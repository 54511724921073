import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1071 1080">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M728.000000,1081.000000 
	C485.361389,1081.000000 243.222794,1081.000000 1.042090,1081.000000 
	C1.042090,721.069763 1.042090,361.139557 1.042090,1.104661 
	C357.888855,1.104661 714.777710,1.104661 1071.833252,1.104661 
	C1071.833252,360.999939 1071.833252,720.999939 1071.833252,1081.000000 
	C957.472412,1081.000000 842.986206,1081.000000 728.000000,1081.000000 
M621.503906,288.741180 
	C621.303711,288.733337 621.103516,288.725464 620.633118,288.223816 
	C620.118774,288.070526 619.604431,287.917206 618.695435,287.364838 
	C618.153076,287.245422 617.610779,287.125977 617.046326,286.550659 
	C616.067688,286.268188 615.089111,285.985718 613.673523,285.213501 
	C612.834167,285.122009 611.994751,285.030548 610.886047,284.726868 
	C610.660217,284.697174 610.434143,284.694977 609.892090,284.003693 
	C609.214294,283.724365 608.536438,283.445038 607.838928,283.151337 
	C607.838928,283.151337 607.872253,283.131714 607.306091,282.715363 
	C599.386963,280.315460 591.581726,277.375275 583.515137,275.674896 
	C576.155823,274.123627 568.513367,273.916016 560.306885,272.954071 
	C559.599487,272.943024 558.892029,272.931976 557.864624,272.239716 
	C556.893738,272.163971 555.922791,272.088226 554.601868,271.762054 
	C554.193176,271.826263 553.784424,271.890472 552.499146,271.491455 
	C547.956238,271.523468 543.413391,271.555450 538.148132,271.322784 
	C536.404602,271.538239 534.661072,271.753662 532.049561,271.811157 
	C530.002747,271.984100 527.955994,272.157013 525.337280,272.190369 
	C525.003235,272.365723 524.669250,272.541107 523.622559,272.514893 
	C520.755432,272.983246 517.888306,273.451599 514.532349,273.689209 
	C514.038757,273.839508 513.545105,273.989807 512.241577,274.037170 
	C509.898590,274.664703 507.549011,275.268768 505.213806,275.923981 
	C496.810211,278.281738 488.411469,280.656769 479.264038,282.994232 
	C469.572632,287.663513 459.319275,291.442139 450.280151,297.145233 
	C387.170105,336.963806 356.622131,394.558533 361.497650,468.918762 
	C366.814178,550.004761 425.839264,616.360901 505.238007,633.558960 
	C524.327209,637.693787 543.688538,638.970581 563.901917,636.414368 
	C564.596802,636.238281 565.291687,636.062195 566.801758,636.047729 
	C567.863342,635.809326 568.924927,635.570923 570.729431,635.585327 
	C580.818909,633.023682 590.908447,630.462036 601.734009,628.157959 
	C604.233154,627.192871 606.732361,626.227783 609.992859,625.240601 
	C615.309692,622.876465 620.986084,621.087646 625.871887,618.033875 
	C637.760925,610.602966 649.283081,602.584900 661.521606,594.732666 
	C661.981567,594.137512 662.441528,593.542419 662.952393,593.004639 
	C662.952393,593.004639 662.967651,592.929565 663.648315,592.892639 
	C676.713257,581.469116 688.317200,568.787598 697.771240,553.648438 
	C698.122864,552.881653 698.474487,552.114807 699.124817,551.166077 
	C699.124817,551.166077 699.228027,550.831909 699.810486,550.665894 
	C705.526672,538.302490 711.242859,525.939026 717.383179,513.052795 
	C723.835815,495.410370 726.414734,477.107025 726.986145,458.412231 
	C727.605957,438.134521 724.565125,418.442627 718.086365,398.377899 
	C713.158508,387.326050 708.230652,376.274200 703.142456,364.487305 
	C696.577026,354.979889 690.821533,344.757721 683.230347,336.155518 
	C674.746887,326.542267 664.598206,318.398621 655.049377,309.197662 
	C654.859863,308.934509 654.604248,308.823242 653.996399,308.251251 
	C651.705383,306.726776 649.414368,305.202301 646.975159,303.250488 
	C646.775696,302.977142 646.509033,302.867676 645.897461,302.320526 
	C643.558105,301.016388 641.218811,299.712219 638.877747,298.112000 
	C638.877747,298.112000 638.582031,298.126160 638.511353,297.581268 
	C638.013550,297.402100 637.515808,297.222900 636.576111,296.534393 
	C632.705505,294.560303 628.834900,292.586182 624.580566,290.147644 
	C624.200317,290.039948 623.820129,289.932251 623.134888,289.306641 
	C622.756287,289.231750 622.377686,289.156830 621.503906,288.741180 
M457.205597,793.401611 
	C457.205597,793.401611 456.964935,793.123779 457.052094,792.256775 
	C456.504028,789.327759 456.247833,786.306030 455.352356,783.487366 
	C452.825531,775.533569 446.693054,771.971252 436.468750,772.176147 
	C427.357513,772.358765 421.314667,776.584229 419.077271,784.337219 
	C414.958313,798.610107 422.747284,809.098755 437.507294,809.154480 
	C438.173370,809.156982 438.839478,809.154846 439.505524,809.149475 
	C443.339020,809.118774 447.173981,809.142029 451.005524,809.038208 
	C454.455231,808.944824 456.812408,804.875183 457.638550,798.148254 
	C458.150940,799.341248 458.436005,800.716980 459.219788,801.691711 
	C461.283936,804.258606 463.386200,808.548157 465.791382,808.774780 
	C474.774261,809.620972 483.884705,809.112976 493.016388,809.112976 
	C491.949036,800.901611 489.080811,798.298279 481.644409,798.255432 
	C477.538116,798.231812 473.431519,798.251221 468.767242,798.251221 
	C468.767242,789.333740 468.767242,780.956482 468.767242,772.542053 
	C462.216217,773.169128 459.332245,776.230774 458.945435,782.577087 
	C458.744019,785.881531 458.712097,789.196228 458.001190,793.056641 
	C457.820709,793.260132 457.640228,793.463623 457.205597,793.401611 
M686.971863,704.893372 
	C687.019043,706.923950 687.066223,708.954529 686.932373,711.625488 
	C686.965637,711.844727 686.998962,712.064026 686.233276,712.210693 
	C681.872070,701.783569 677.547546,691.340759 673.098938,680.951050 
	C672.594666,679.773254 671.422058,678.045166 670.507874,678.012512 
	C663.591370,677.764954 656.661926,677.877258 649.802307,677.877258 
	C649.802307,705.287720 649.802307,732.018311 649.802307,758.700928 
	C657.343140,758.700928 664.576782,758.700928 672.346924,758.700928 
	C672.346924,747.602051 672.346924,736.855591 672.346924,726.109192 
	C677.020874,735.886414 680.686218,745.811340 684.528076,755.667358 
	C685.032166,756.960693 686.405273,758.818665 687.435791,758.858337 
	C694.664246,759.136353 701.908386,759.005615 709.317627,759.005615 
	C709.317627,731.910278 709.317627,705.492249 709.317627,678.626282 
	C701.828125,678.626282 694.491943,678.626282 687.122864,678.626282 
	C687.122864,687.085999 687.122864,695.561829 686.971863,704.893372 
M864.283630,678.157715 
	C863.522217,678.228516 862.760803,678.299316 861.182739,678.077271 
	C850.228882,678.077271 839.275085,678.077271 828.292847,678.077271 
	C828.292847,705.098572 828.292847,731.966919 828.292847,758.668335 
	C837.380432,758.668335 846.104736,758.668335 855.126709,758.668335 
	C855.126709,746.525208 855.126709,734.752625 855.126709,722.992004 
	C861.565613,722.887146 862.463135,723.617920 862.749512,729.532776 
	C862.918396,733.020996 862.817749,736.522583 862.821777,740.018188 
	C862.829041,746.259644 862.823853,752.501038 862.823853,758.666565 
	C871.606384,758.666565 879.669678,758.666565 887.901917,758.666565 
	C887.901917,751.145447 887.771423,744.005371 887.935730,736.872070 
	C888.129761,728.452698 888.116760,720.268066 878.103210,717.783325 
	C881.207275,714.836975 885.498657,712.702637 886.379028,709.600342 
	C890.754944,694.179077 886.986511,677.455322 864.283630,678.157715 
M513.731750,719.569702 
	C513.731750,716.840698 513.731750,714.111633 513.731750,711.382629 
	C514.051880,711.360718 514.372070,711.338806 514.692200,711.316895 
	C517.581177,727.085266 520.470093,742.853638 523.385010,758.763489 
	C529.164062,758.763489 534.579041,758.763489 540.215271,758.763489 
	C542.687195,744.129272 545.120728,729.722656 547.554199,715.315979 
	C548.198547,715.398071 548.842896,715.480103 549.487244,715.562195 
	C549.487244,729.956848 549.487244,744.351501 549.487244,758.624207 
	C557.702576,758.624207 565.267273,758.624207 572.579468,758.624207 
	C572.579468,731.498352 572.579468,704.775330 572.579468,677.845276 
	C562.348450,677.845276 552.525818,678.036255 542.716980,677.752319 
	C538.727905,677.636902 537.365662,678.767517 536.886169,682.852112 
	C535.787903,692.206787 533.984680,701.478577 532.467773,710.784058 
	C531.963989,710.727051 531.460205,710.669983 530.956482,710.612976 
	C528.896973,699.739624 526.837524,688.866211 524.827515,678.253967 
	C512.979736,678.253967 501.751312,678.253967 490.562897,678.253967 
	C490.562897,705.363037 490.562897,732.065308 490.562897,758.733398 
	C498.356903,758.733398 505.773560,758.733398 513.728088,758.733398 
	C513.728088,745.807373 513.728088,733.179321 513.731750,719.569702 
M317.854187,697.206604 
	C317.218323,688.348511 313.260651,680.884399 304.287720,679.816589 
	C288.871674,677.982056 273.246796,677.901672 257.585449,677.078430 
	C257.585449,705.166565 257.585449,731.886353 257.585449,759.030518 
	C263.784393,759.030518 269.747192,759.129211 275.705627,759.008728 
	C286.177582,758.796936 296.677979,758.848938 307.107056,758.031860 
	C315.047943,757.409729 318.701385,753.432922 319.432678,745.501099 
	C319.949432,739.896484 319.817352,734.197815 319.535889,728.563110 
	C319.241333,722.666870 316.061859,718.663208 310.355316,716.787415 
	C309.345795,716.455566 308.390106,715.959900 305.778778,714.841125 
	C316.742096,712.926819 318.703613,706.310181 317.854187,697.206604 
M388.318542,690.299561 
	C385.593414,683.397949 380.220306,679.488281 373.139465,679.057373 
	C360.025787,678.259338 346.865967,678.212769 333.724426,677.890381 
	C332.290710,677.855225 330.851044,678.063293 329.575439,678.147888 
	C329.575439,705.348145 329.575439,732.065491 329.575439,759.018738 
	C334.928558,759.018738 339.921448,759.104675 344.910461,759.001282 
	C356.225159,758.766968 367.544525,758.586609 378.848114,758.076660 
	C383.313080,757.875244 387.628754,756.151917 389.446655,751.732483 
	C392.989197,743.120544 392.320801,734.080750 390.641541,725.199829 
	C389.467529,718.990845 383.827881,717.331421 378.379852,715.450439 
	C389.496674,709.571167 390.686066,707.518555 389.519043,696.455444 
	C389.327881,694.643127 388.842957,692.861877 388.318542,690.299561 
M720.046204,699.500122 
	C720.046204,719.254028 720.046204,739.007935 720.046204,758.741394 
	C727.845642,758.741394 734.936584,758.741394 742.568909,758.741394 
	C742.568909,747.664307 742.568909,736.863586 742.568909,726.062866 
	C744.018860,727.040222 744.630310,728.148132 745.072876,729.319824 
	C748.365906,738.039551 751.565430,746.795410 754.958069,755.475952 
	C755.482727,756.818420 756.804749,758.803833 757.824402,758.844971 
	C765.069031,759.137085 772.330872,759.000671 779.688171,759.000671 
	C779.688171,731.666199 779.688171,705.067627 779.688171,678.153748 
	C772.276611,678.153748 765.076477,678.153748 757.185181,678.153748 
	C757.185181,689.246094 757.185181,699.999023 757.185181,710.751953 
	C752.001526,700.966003 747.933899,691.030823 743.690796,681.171082 
	C743.127930,679.863159 741.712646,678.032837 740.624878,677.985413 
	C733.881836,677.691223 727.119507,677.837463 720.046204,677.837463 
	C720.046204,685.222961 720.046204,691.861633 720.046204,699.500122 
M627.543518,759.015686 
	C633.290466,759.015686 639.037415,759.015686 645.115417,759.015686 
	C639.890808,731.467712 634.822388,704.743225 629.751953,678.007935 
	C616.625732,678.007935 604.005493,678.007935 591.011475,678.007935 
	C586.504761,704.953369 582.020569,731.764038 577.520325,758.670654 
	C586.979858,758.670654 596.039307,758.670654 605.304932,758.670654 
	C605.705872,754.881165 606.263550,751.426880 606.382996,747.957520 
	C606.496216,744.667114 608.334412,744.189148 610.983643,744.366089 
	C613.523376,744.535645 616.535034,743.627686 616.749146,747.837219 
	C616.933716,751.465576 617.426819,755.078125 617.814819,759.015320 
	C620.939209,759.015320 623.752136,759.015320 627.543518,759.015686 
M422.747986,758.468140 
	C423.186981,755.011719 423.953766,751.557861 423.981354,748.098145 
	C424.015259,743.849976 426.718781,744.385376 429.394684,744.390381 
	C431.945374,744.395142 434.393616,744.021484 434.358154,747.998352 
	C434.326294,751.571289 435.044128,755.150940 435.440582,758.814026 
	C444.642853,758.814026 453.407715,758.814026 462.120544,758.814026 
	C462.268921,758.290527 462.473938,757.967346 462.422241,757.692444 
	C457.564697,731.878235 452.726990,706.060059 447.667633,680.285400 
	C447.465729,679.256897 445.159729,677.968262 443.813690,677.944214 
	C433.324554,677.757019 422.828491,677.964417 412.338989,677.786255 
	C409.282654,677.734314 408.387878,678.904358 407.978699,681.706543 
	C406.825684,689.602844 405.358002,697.452759 404.051056,705.327026 
	C401.115814,723.011841 398.202728,740.700317 395.198151,758.886658 
	C404.387909,758.886658 413.164948,758.886658 422.747986,758.468140 
M245.250854,718.063416 
	C242.718369,704.733459 240.185883,691.403564 237.653580,678.074585 
	C224.429504,678.074585 211.821579,678.074585 198.887665,678.074585 
	C194.373688,705.020386 189.886841,731.804138 185.371368,758.758789 
	C194.762070,758.758789 203.841217,758.758789 213.113281,758.758789 
	C213.598526,754.719666 214.179550,750.946472 214.470047,747.150940 
	C214.732651,743.720093 217.192017,744.342224 219.220215,744.415649 
	C221.304581,744.491211 224.134705,743.370605 224.387802,747.207703 
	C224.636917,750.984192 225.150665,754.743225 225.573685,758.750488 
	C234.704529,758.750488 243.610016,758.750488 252.931717,758.750488 
	C250.390808,745.254639 247.909637,732.076111 245.250854,718.063416 
M817.347839,709.500122 
	C817.347839,699.073669 817.347839,688.647217 817.347839,678.201660 
	C808.096619,678.201660 799.534363,678.201660 790.945557,678.201660 
	C790.945557,705.255615 790.945557,731.968628 790.945557,758.721191 
	C799.889771,758.721191 808.464417,758.721191 817.347900,758.721191 
	C817.347900,742.448120 817.347900,726.474182 817.347839,709.500122 
M292.041504,775.813538 
	C292.913757,772.969971 291.980682,771.914368 288.923126,772.068115 
	C284.435364,772.293701 279.927429,772.119751 275.428070,772.111511 
	C263.227173,772.089050 257.284546,777.992615 256.996948,790.424988 
	C256.723297,802.253845 263.434265,809.179810 275.201660,809.188110 
	C279.034241,809.190796 282.931366,808.688354 286.684753,809.221252 
	C292.687714,810.073669 295.078979,806.796326 295.548401,801.853455 
	C296.041321,796.663269 295.658691,791.389893 295.658691,786.375122 
	C287.915009,786.375122 280.513702,786.375122 273.091003,786.375122 
	C272.576935,795.931519 279.669464,796.324951 286.019318,797.768921 
	C281.221191,798.180664 276.580536,798.450012 271.986847,798.101196 
	C267.799011,797.783203 264.895874,794.102295 265.134277,790.258057 
	C265.381134,786.278137 268.446350,783.169495 272.593933,782.963318 
	C275.919037,782.798157 279.267059,782.726868 282.587311,782.918762 
	C287.431183,783.198669 290.607330,781.301819 292.041504,775.813538 
M412.339050,805.919250 
	C415.110321,802.655701 415.867737,799.037720 415.073029,794.766479 
	C414.555206,791.983521 414.561737,788.921265 415.137268,786.151184 
	C416.738892,778.442444 412.020905,772.176819 404.178528,772.143860 
	C397.861237,772.117310 391.543701,772.108093 385.226410,772.128235 
	C378.930817,772.148254 376.535797,774.454468 376.451385,780.806396 
	C376.367462,787.121826 376.428833,793.439941 376.488770,799.756348 
	C376.550537,806.268127 379.331635,809.084106 385.953186,809.120911 
	C392.102325,809.155151 398.267578,809.185120 404.395325,808.768738 
	C406.906464,808.598206 409.337646,807.250977 412.339050,805.919250 
M781.187683,807.423950 
	C789.029785,811.737610 797.429199,810.017212 805.585266,809.261780 
	C812.769958,808.596313 815.920349,805.103577 816.086609,799.468201 
	C816.294617,792.418823 813.106384,788.098450 805.918640,786.992371 
	C800.696777,786.188782 795.315491,786.469360 790.032898,785.972778 
	C788.887939,785.865173 787.861023,784.502075 786.779724,783.717163 
	C787.794189,782.801147 788.670410,781.492188 789.859985,781.073425 
	C791.198242,780.602417 792.819031,780.920227 794.316956,780.919067 
	C801.078247,780.913574 807.839539,780.916687 814.671814,780.916687 
	C813.854675,775.087646 811.200378,772.271057 806.462463,772.173706 
	C800.136963,772.043762 793.797119,771.966675 787.480225,772.246033 
	C781.119873,772.527283 776.772827,777.596436 777.018188,783.871887 
	C777.292114,790.875671 781.465515,795.152100 788.186340,795.232849 
	C792.849121,795.288818 797.542175,794.962769 802.161133,795.419617 
	C803.861572,795.587769 805.388367,797.512268 806.994385,798.635620 
	C805.339661,799.479431 803.732666,800.912598 802.020813,801.051208 
	C797.718872,801.399597 793.369507,801.162598 789.039185,801.161499 
	C785.592102,801.160583 782.145020,801.161255 777.537109,801.161255 
	C778.876282,803.655334 779.753967,805.289917 781.187683,807.423950 
M537.151855,795.269775 
	C541.982544,795.266418 546.835999,794.992737 551.632141,795.398254 
	C553.446777,795.551697 555.128906,797.272644 556.871399,798.279053 
	C555.169495,799.235779 553.548218,800.415955 551.736938,801.069580 
	C550.556885,801.495422 549.100525,801.158142 547.767944,801.158386 
	C541.299622,801.159302 534.831299,801.158752 528.332031,801.158752 
	C528.836548,806.087280 531.271545,809.313049 536.103943,809.523010 
	C543.378113,809.839050 550.772522,809.752258 557.968872,808.764893 
	C564.011597,807.935852 566.745239,802.901672 565.819458,796.878357 
	C564.857788,790.620972 561.077576,787.238892 553.189514,786.357300 
	C548.743042,785.860413 544.201355,786.261963 539.727966,785.923950 
	C538.643921,785.842041 537.660034,784.436401 536.630127,783.639404 
	C537.631714,782.763611 538.511536,781.422607 539.663391,781.121277 
	C541.384766,780.670898 543.297485,780.929565 545.128906,780.926575 
	C551.534607,780.915955 557.940308,780.922485 564.420288,780.922485 
	C563.598633,774.918335 561.164856,772.313599 556.104492,772.149231 
	C549.948608,771.949341 543.769714,771.910095 537.620361,772.207520 
	C531.327454,772.511780 527.295288,776.699768 526.948669,782.631348 
	C526.561523,789.256958 529.976135,793.861267 537.151855,795.269775 
M736.609802,802.784851 
	C738.497559,806.378235 741.151489,809.031067 745.452393,809.117554 
	C751.614014,809.241455 757.784790,809.310974 763.942139,809.110352 
	C768.595459,808.958862 771.028687,806.194519 771.508972,800.848938 
	C764.183960,800.848938 756.890564,800.846313 749.597168,800.850098 
	C746.178467,800.851807 743.458374,799.992859 743.057861,795.303101 
	C746.299500,795.303101 749.250610,795.304626 752.201660,795.302673 
	C755.367126,795.300537 758.540039,795.433777 761.696411,795.262085 
	C768.095398,794.914001 772.684326,790.670471 773.167969,784.863770 
	C773.673767,778.791260 770.624451,774.113464 764.221252,773.060425 
	C759.045288,772.209290 753.635254,772.441528 748.350464,772.676453 
	C741.310303,772.989319 736.912109,776.727234 735.188660,783.505798 
	C733.592102,789.785400 733.691895,796.039246 736.609802,802.784851 
M604.688171,804.320557 
	C604.863525,803.279358 605.038818,802.238098 605.277222,800.822266 
	C597.480591,800.822266 590.174622,800.783875 582.869385,800.838745 
	C579.339722,800.865173 576.872192,799.742676 576.294434,795.528137 
	C577.874512,795.450378 579.164734,795.350830 580.456299,795.329529 
	C585.775330,795.241882 591.129211,795.529724 596.404968,795.013000 
	C602.512207,794.414856 606.199463,790.222107 606.499756,784.553162 
	C606.829163,778.334595 603.822571,773.770874 597.313843,773.025330 
	C590.962952,772.297852 584.370300,772.488708 578.022034,773.325439 
	C573.329102,773.943909 569.724670,777.706604 569.091736,782.381104 
	C568.306030,788.183594 568.060486,794.299011 569.028137,800.035217 
	C570.109741,806.446960 574.445862,809.097961 580.875305,809.164551 
	C585.699524,809.214478 590.527893,809.069946 595.348267,809.216064 
	C599.144165,809.331116 602.210083,808.239990 604.688171,804.320557 
M337.322449,777.823120 
	C331.744141,784.817749 331.361420,792.584351 335.042725,800.277527 
	C338.462891,807.424927 345.302765,809.160706 352.656525,809.149902 
	C355.140503,809.146240 357.663544,809.128723 360.100525,808.715332 
	C367.339478,807.487427 372.629639,802.201233 373.721100,795.390625 
	C375.126709,786.619690 371.663635,777.728882 365.035645,774.813660 
	C355.637421,770.680176 346.363770,770.718079 337.322449,777.823120 
M706.491089,783.305847 
	C711.472473,783.174561 716.454041,783.051392 721.435120,782.910156 
	C728.388977,782.712891 731.094299,779.727600 730.812927,772.125977 
	C724.048584,772.125977 717.258423,772.122375 710.468323,772.126892 
	C700.888123,772.133179 696.181946,775.603394 693.318848,784.762207 
	C689.277039,797.691650 697.548096,809.153442 710.932861,809.170532 
	C714.593323,809.175171 718.254639,809.230347 721.914062,809.170410 
	C728.612366,809.060730 731.128540,806.194519 730.814026,798.605042 
	C724.399414,798.605042 717.953491,798.568054 711.508301,798.617920 
	C707.134949,798.651794 703.554626,797.223694 701.909851,792.949463 
	C700.326965,788.836182 702.656189,785.976624 706.491089,783.305847 
M644.213684,780.602600 
	C647.982422,789.095215 651.868591,797.538757 655.450623,806.109375 
	C656.569092,808.785645 658.173828,809.372559 660.780396,809.247498 
	C663.042053,809.138977 664.617554,809.053284 665.731750,806.400513 
	C669.843811,796.610474 674.394714,787.005432 678.660889,777.278442 
	C679.188171,776.076111 679.545166,773.684753 679.028625,773.360046 
	C677.770813,772.569641 675.394348,771.897156 674.471375,772.564514 
	C672.186035,774.216797 669.895630,776.354919 668.628845,778.822388 
	C665.799805,784.332764 663.593933,790.163086 660.578308,797.151184 
	C658.969299,793.366333 658.007629,790.932617 656.911072,788.561279 
	C654.760315,783.910339 652.999207,778.969666 650.106384,774.826355 
	C648.979065,773.211670 645.232788,773.425415 642.679321,772.806335 
	C642.864502,774.731689 643.015320,776.661499 643.259827,778.579285 
	C643.319153,779.044922 643.687073,779.471191 644.213684,780.602600 
M326.548340,809.193909 
	C328.282959,809.113037 330.017578,809.032166 331.810211,808.948608 
	C330.525543,800.688232 327.838989,798.319275 320.192535,798.245728 
	C316.093140,798.206299 311.993103,798.238647 307.330627,798.238647 
	C307.330627,789.233032 307.330627,780.855042 307.330627,772.410400 
	C300.800903,773.531677 298.156494,776.140869 297.945099,781.901245 
	C297.719238,788.054871 297.698700,794.228516 297.912231,800.382202 
	C298.109344,806.061462 301.472961,809.084717 307.253693,809.157166 
	C313.414490,809.234375 319.576813,809.186096 326.548340,809.193909 
M622.324646,774.208862 
	C620.179321,773.702637 617.899902,772.467651 615.914856,772.832764 
	C610.845032,773.765137 608.378235,777.448120 608.308044,782.489868 
	C608.186218,791.240051 608.274170,799.993225 608.274170,808.718445 
	C615.751953,808.860596 618.920715,806.067566 619.065796,799.995667 
	C619.109436,798.169617 619.073120,796.341614 619.072266,794.514526 
	C619.068054,785.077881 620.208496,783.835022 629.774780,782.851746 
	C636.902771,782.119080 637.307495,781.649231 637.783508,772.486084 
	C632.801208,772.989258 627.970459,773.477173 622.324646,774.208862 
M684.113464,808.893127 
	C685.876953,806.859619 689.049866,804.901733 689.163818,802.779663 
	C689.702209,792.753296 689.403870,782.681885 689.403870,772.320984 
	C684.719116,772.952148 682.288696,775.692810 682.144592,780.215149 
	C681.864075,789.016052 681.799194,797.825073 681.783936,806.630981 
	C681.782532,807.430054 682.787903,808.230835 684.113464,808.893127 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M563.174011,636.447510 
	C543.688538,638.970581 524.327209,637.693787 505.238007,633.558960 
	C425.839264,616.360901 366.814178,550.004761 361.497650,468.918762 
	C356.622131,394.558533 387.170105,336.963806 450.280151,297.145233 
	C459.319275,291.442139 469.572632,287.663513 479.743103,283.294220 
	C479.066803,284.958832 477.452393,286.146790 476.845947,287.722687 
	C475.300415,291.738770 475.491455,296.852936 469.443207,297.494537 
	C468.865814,297.555786 468.331665,298.625366 467.893494,299.301971 
	C464.678955,304.266083 461.543671,309.282166 458.280457,314.213776 
	C456.446716,316.985077 453.494049,319.388824 452.720306,322.389923 
	C451.845306,325.783875 450.009216,327.705505 447.818878,330.099579 
	C444.125214,334.136932 445.726227,340.407959 449.867310,341.342834 
	C447.942383,343.499786 446.136658,345.354919 444.262207,346.842285 
	C444.368134,345.146362 444.542816,343.818237 444.717499,342.490112 
	C444.348602,342.292206 443.979706,342.094269 443.610779,341.896332 
	C442.514893,343.119812 441.137970,344.217590 440.499115,345.645569 
	C440.314056,346.059174 442.245514,347.419739 443.141632,348.675659 
	C424.772186,371.235687 411.828705,396.151703 407.086548,424.691406 
	C397.531494,482.196350 413.566132,531.601685 455.414886,572.357727 
	C471.383911,587.909851 490.384460,598.727966 510.851685,607.070679 
	C508.214417,607.182434 505.781403,606.983643 503.348419,606.784912 
	C505.475555,608.774231 507.997162,609.990234 510.495850,611.251648 
	C513.273926,612.654175 516.031189,614.097900 519.446289,615.858032 
	C516.920166,616.887329 515.456299,617.483765 512.825012,618.555847 
	C516.517273,619.869812 519.034424,620.426819 521.203735,621.617554 
	C524.401733,623.372864 527.213135,626.995667 530.435547,627.311829 
	C538.160461,628.069824 543.265015,636.017578 551.755676,634.247986 
	C554.698242,633.634766 557.939758,634.274414 561.015564,634.609253 
	C561.792542,634.693909 562.457581,635.807007 563.174011,636.447510 
M472.627258,288.476776 
	C472.841461,288.531647 473.055664,288.586548 473.269867,288.641418 
	C473.132324,288.506775 472.994781,288.372101 472.627258,288.476776 
M537.517822,633.410889 
	C537.517822,633.410889 537.585388,633.522827 537.517822,633.410889 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M513.729919,720.060425 
	C513.728088,733.179321 513.728088,745.807373 513.728088,758.733398 
	C505.773560,758.733398 498.356903,758.733398 490.562897,758.733398 
	C490.562897,732.065308 490.562897,705.363037 490.562897,678.253967 
	C501.751312,678.253967 512.979736,678.253967 524.827515,678.253967 
	C526.837524,688.866211 528.896973,699.739624 530.956482,710.612976 
	C531.460205,710.669983 531.963989,710.727051 532.467773,710.784058 
	C533.984680,701.478577 535.787903,692.206787 536.886169,682.852112 
	C537.365662,678.767517 538.727905,677.636902 542.716980,677.752319 
	C552.525818,678.036255 562.348450,677.845276 572.579468,677.845276 
	C572.579468,704.775330 572.579468,731.498352 572.579468,758.624207 
	C565.267273,758.624207 557.702576,758.624207 549.487244,758.624207 
	C549.487244,744.351501 549.487244,729.956848 549.487244,715.562195 
	C548.842896,715.480103 548.198547,715.398071 547.554199,715.315979 
	C545.120728,729.722656 542.687195,744.129272 540.215271,758.763489 
	C534.579041,758.763489 529.164062,758.763489 523.385010,758.763489 
	C520.470093,742.853638 517.581177,727.085266 514.692200,711.316895 
	C514.372070,711.338806 514.051880,711.360718 513.731750,711.382629 
	C513.731750,714.111633 513.731750,716.840698 513.729919,720.060425 
z"
          />
          <path
            fill="#0D0D6B"
            opacity="1.000000"
            stroke="none"
            d="
M317.888489,697.622192 
	C318.703613,706.310181 316.742096,712.926819 305.778778,714.841125 
	C308.390106,715.959900 309.345795,716.455566 310.355316,716.787415 
	C316.061859,718.663208 319.241333,722.666870 319.535889,728.563110 
	C319.817352,734.197815 319.949432,739.896484 319.432678,745.501099 
	C318.701385,753.432922 315.047943,757.409729 307.107056,758.031860 
	C296.677979,758.848938 286.177582,758.796936 275.705627,759.008728 
	C269.747192,759.129211 263.784393,759.030518 257.585449,759.030518 
	C257.585449,731.886353 257.585449,705.166565 257.585449,677.078430 
	C273.246796,677.901672 288.871674,677.982056 304.287720,679.816589 
	C313.260651,680.884399 317.218323,688.348511 317.888489,697.622192 
M292.564789,741.629395 
	C292.700012,737.474670 293.031067,733.314819 292.924011,729.166382 
	C292.768433,723.139038 290.845673,721.887085 284.503174,723.362183 
	C284.503174,730.433411 284.503174,737.538025 284.503174,746.033630 
	C287.577667,744.620117 289.942749,743.532715 292.564789,741.629395 
z"
          />
          <path
            fill="#080767"
            opacity="1.000000"
            stroke="none"
            d="
M388.406158,690.682861 
	C388.842957,692.861877 389.327881,694.643127 389.519043,696.455444 
	C390.686066,707.518555 389.496674,709.571167 378.379852,715.450439 
	C383.827881,717.331421 389.467529,718.990845 390.641541,725.199829 
	C392.320801,734.080750 392.989197,743.120544 389.446655,751.732483 
	C387.628754,756.151917 383.313080,757.875244 378.848114,758.076660 
	C367.544525,758.586609 356.225159,758.766968 344.910461,759.001282 
	C339.921448,759.104675 334.928558,759.018738 329.575439,759.018738 
	C329.575439,732.065491 329.575439,705.348145 329.575439,678.147888 
	C330.851044,678.063293 332.290710,677.855225 333.724426,677.890381 
	C346.865967,678.212769 360.025787,678.259338 373.139465,679.057373 
	C380.220306,679.488281 385.593414,683.397949 388.406158,690.682861 
M362.452789,744.175903 
	C366.905182,739.721436 364.564240,734.130371 364.725220,729.070374 
	C364.915009,723.103394 362.767609,721.864502 356.444946,723.262939 
	C356.444946,730.430115 356.444946,737.611328 356.444946,745.191528 
	C358.467102,744.936890 360.079987,744.733765 362.452789,744.175903 
M362.119019,692.390991 
	C360.257599,692.199158 358.396210,692.007324 356.107971,691.771545 
	C356.107971,696.943542 355.969727,701.408325 356.191528,705.855225 
	C356.258850,707.204407 357.515717,709.691467 357.895233,709.629272 
	C359.998810,709.284424 363.414276,708.734070 363.785706,707.483398 
	C365.224609,702.638489 366.313721,697.449219 362.119019,692.390991 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M720.046204,699.000183 
	C720.046204,691.861633 720.046204,685.222961 720.046204,677.837463 
	C727.119507,677.837463 733.881836,677.691223 740.624878,677.985413 
	C741.712646,678.032837 743.127930,679.863159 743.690796,681.171082 
	C747.933899,691.030823 752.001526,700.966003 757.185181,710.751953 
	C757.185181,699.999023 757.185181,689.246094 757.185181,678.153748 
	C765.076477,678.153748 772.276611,678.153748 779.688171,678.153748 
	C779.688171,705.067627 779.688171,731.666199 779.688171,759.000671 
	C772.330872,759.000671 765.069031,759.137085 757.824402,758.844971 
	C756.804749,758.803833 755.482727,756.818420 754.958069,755.475952 
	C751.565430,746.795410 748.365906,738.039551 745.072876,729.319824 
	C744.630310,728.148132 744.018860,727.040222 742.568909,726.062866 
	C742.568909,736.863586 742.568909,747.664307 742.568909,758.741394 
	C734.936584,758.741394 727.845642,758.741394 720.046204,758.741394 
	C720.046204,739.007935 720.046204,719.254028 720.046204,699.000183 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M627.054260,759.015503 
	C623.752136,759.015320 620.939209,759.015320 617.814819,759.015320 
	C617.426819,755.078125 616.933716,751.465576 616.749146,747.837219 
	C616.535034,743.627686 613.523376,744.535645 610.983643,744.366089 
	C608.334412,744.189148 606.496216,744.667114 606.382996,747.957520 
	C606.263550,751.426880 605.705872,754.881165 605.304932,758.670654 
	C596.039307,758.670654 586.979858,758.670654 577.520325,758.670654 
	C582.020569,731.764038 586.504761,704.953369 591.011475,678.007935 
	C604.005493,678.007935 616.625732,678.007935 629.751953,678.007935 
	C634.822388,704.743225 639.890808,731.467712 645.115417,759.015686 
	C639.037415,759.015686 633.290466,759.015686 627.054260,759.015503 
M615.098022,726.082581 
	C613.822510,718.020996 612.546997,709.959351 611.271423,701.897766 
	C610.948792,701.889587 610.626160,701.881409 610.303528,701.873230 
	C609.014954,711.075806 607.726318,720.278381 606.348877,730.114868 
	C609.041077,730.114868 611.086853,730.473267 612.893799,729.971313 
	C613.870483,729.700073 614.439026,727.959351 615.098022,726.082581 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M422.344971,758.677368 
	C413.164948,758.886658 404.387909,758.886658 395.198151,758.886658 
	C398.202728,740.700317 401.115814,723.011841 404.051056,705.327026 
	C405.358002,697.452759 406.825684,689.602844 407.978699,681.706543 
	C408.387878,678.904358 409.282654,677.734314 412.338989,677.786255 
	C422.828491,677.964417 433.324554,677.757019 443.813690,677.944214 
	C445.159729,677.968262 447.465729,679.256897 447.667633,680.285400 
	C452.726990,706.060059 457.564697,731.878235 462.422241,757.692444 
	C462.473938,757.967346 462.268921,758.290527 462.120544,758.814026 
	C453.407715,758.814026 444.642853,758.814026 435.440582,758.814026 
	C435.044128,755.150940 434.326294,751.571289 434.358154,747.998352 
	C434.393616,744.021484 431.945374,744.395142 429.394684,744.390381 
	C426.718781,744.385376 424.015259,743.849976 423.981354,748.098145 
	C423.953766,751.557861 423.186981,755.011719 422.344971,758.677368 
M425.508850,719.930542 
	C425.103882,723.150146 424.698883,726.369751 424.259277,729.864929 
	C427.459381,729.864929 430.036713,729.864929 433.321533,729.864929 
	C432.129608,720.656372 430.979645,711.772156 429.829681,702.887878 
	C429.241425,702.916565 428.653168,702.945251 428.064911,702.973877 
	C427.254791,708.336670 426.444641,713.699402 425.508850,719.930542 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M245.339661,718.480530 
	C247.909637,732.076111 250.390808,745.254639 252.931717,758.750488 
	C243.610016,758.750488 234.704529,758.750488 225.573685,758.750488 
	C225.150665,754.743225 224.636917,750.984192 224.387802,747.207703 
	C224.134705,743.370605 221.304581,744.491211 219.220215,744.415649 
	C217.192017,744.342224 214.732651,743.720093 214.470047,747.150940 
	C214.179550,750.946472 213.598526,754.719666 213.113281,758.758789 
	C203.841217,758.758789 194.762070,758.758789 185.371368,758.758789 
	C189.886841,731.804138 194.373688,705.020386 198.887665,678.074585 
	C211.821579,678.074585 224.429504,678.074585 237.653580,678.074585 
	C240.185883,691.403564 242.718369,704.733459 245.339661,718.480530 
M218.422409,701.249207 
	C217.056686,710.704712 215.690979,720.160217 214.304199,729.761597 
	C217.820786,729.761597 220.374619,729.761597 223.367981,729.761597 
	C222.123093,718.966736 220.926483,708.590393 219.599564,697.084229 
	C219.065704,698.967407 218.846100,699.742065 218.422409,701.249207 
z"
          />
          <path
            fill="#040366"
            opacity="1.000000"
            stroke="none"
            d="
M861.999390,678.370117 
	C862.760803,678.299316 863.522217,678.228516 864.767151,678.663208 
	C865.996094,679.441223 866.728210,679.904358 867.489014,679.957642 
	C878.269470,680.712280 884.118530,685.856628 885.880798,696.208923 
	C887.413513,705.213379 884.435303,711.801270 878.091309,713.906677 
	C876.699890,714.368347 875.566956,715.608948 874.315002,716.490845 
	C875.447510,717.764893 876.481750,719.146729 877.734619,720.288696 
	C879.810425,722.180786 883.038147,723.492004 884.009216,725.794861 
	C888.230835,735.805908 885.144287,746.299622 886.029053,757.145508 
	C880.256592,757.463257 875.293823,757.541748 870.377625,758.061768 
	C865.459900,758.581970 863.857056,756.458923 863.957947,751.795776 
	C864.141663,743.305664 863.968506,734.808350 864.000183,726.314270 
	C864.013062,722.857544 862.487061,721.316040 858.948975,720.939087 
	C853.822021,720.392700 853.064514,723.456116 853.036377,727.279602 
	C852.973938,735.773438 852.903015,744.270264 853.072998,752.761108 
	C853.130249,755.617126 852.315063,757.193359 849.369751,757.362610 
	C845.736328,757.571350 842.105103,757.941833 838.470276,757.976746 
	C830.349426,758.054810 829.024048,756.710144 829.010254,748.437073 
	C828.975281,727.451294 829.079712,706.464844 828.930176,685.480042 
	C828.901306,681.421692 830.101624,679.778687 834.334167,679.914124 
	C842.154175,680.164246 849.989441,680.061584 857.814575,679.909058 
	C859.218994,679.881653 860.604919,678.907227 861.999390,678.370117 
M855.037476,692.502319 
	C855.037476,698.246826 855.037476,703.991394 855.037476,709.742554 
	C862.408875,709.412842 862.976135,708.703064 862.975830,700.493652 
	C862.975525,692.888977 862.337585,692.150269 855.037476,692.502319 
z"
          />
          <path
            fill="#040366"
            opacity="1.000000"
            stroke="none"
            d="
M687.032288,712.283386 
	C686.998962,712.064026 686.965637,711.844727 687.333374,711.117432 
	C687.827576,708.404053 687.920715,706.198853 688.009460,703.525391 
	C688.002563,699.573547 687.869202,696.083923 688.031128,692.608032 
	C688.227478,688.394531 688.673401,684.192627 689.477661,679.991211 
	C695.299744,679.994202 700.655579,679.991638 706.231018,680.095154 
	C706.724976,680.399048 706.911926,680.659607 707.007385,681.453247 
	C707.002197,703.887024 706.978943,725.850464 707.042786,747.813721 
	C707.047791,749.541748 707.659729,751.268066 707.952881,753.375610 
	C707.178467,754.793518 706.487976,756.687561 705.695618,756.731140 
	C700.137329,757.036926 694.557007,757.026367 688.986816,756.931458 
	C688.215027,756.918335 687.072205,756.047546 686.758667,755.298523 
	C683.758850,748.132507 680.903381,740.906189 678.000549,733.699463 
	C676.353210,729.609558 674.694763,725.524170 673.041321,721.436707 
	C672.268250,721.573669 671.495117,721.710632 670.722046,721.847595 
	C670.321838,733.570740 669.921631,745.293945 669.537842,756.537292 
	C666.044189,757.069336 663.590942,757.404785 661.151672,757.821899 
	C654.877441,758.894592 651.079407,756.218872 651.043274,750.049988 
	C650.914490,728.055298 651.014526,706.059326 650.966675,684.063965 
	C650.960083,681.029663 652.344971,679.907104 655.240295,679.963745 
	C659.737671,680.051758 664.250061,679.806580 668.729248,680.105103 
	C669.975220,680.188110 671.759338,681.435791 672.221436,682.582458 
	C676.015869,691.997925 679.561096,701.513977 683.170654,711.003784 
	C684.036011,713.278809 684.835144,713.557800 687.032288,712.283386 
z"
          />
          <path
            fill="#040366"
            opacity="1.000000"
            stroke="none"
            d="
M817.347900,710.000183 
	C817.347900,726.474182 817.347900,742.448120 817.347900,758.721191 
	C808.464417,758.721191 799.889771,758.721191 790.945557,758.721191 
	C790.945557,731.968628 790.945557,705.255615 790.945557,678.201660 
	C799.534363,678.201660 808.096619,678.201660 817.347839,678.201660 
	C817.347839,688.647217 817.347839,699.073669 817.347900,710.000183 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M449.986572,341.040985 
	C445.726227,340.407959 444.125214,334.136932 447.818878,330.099579 
	C450.009216,327.705505 451.845306,325.783875 452.720306,322.389923 
	C453.494049,319.388824 456.446716,316.985077 458.280457,314.213776 
	C461.543671,309.282166 464.678955,304.266083 467.893494,299.301971 
	C468.331665,298.625366 468.865814,297.555786 469.443207,297.494537 
	C475.491455,296.852936 475.300415,291.738770 476.845947,287.722687 
	C477.452393,286.146790 479.066803,284.958832 480.116516,283.309753 
	C488.411469,280.656769 496.810211,278.281738 505.213806,275.923981 
	C507.549011,275.268768 509.898590,274.664703 512.766235,274.280212 
	C513.867676,274.322113 514.444458,274.121033 515.021179,273.919952 
	C517.888306,273.451599 520.755432,272.983246 524.265137,272.669525 
	C525.241516,272.659424 525.575317,272.494690 525.909180,272.329956 
	C527.955994,272.157013 530.002747,271.984100 532.869019,271.922974 
	C535.415771,271.885712 537.143127,271.736603 538.870483,271.587463 
	C543.413391,271.555450 547.956238,271.523468 553.131104,271.767090 
	C554.159363,272.032623 554.555664,272.022522 554.951904,272.012451 
	C555.922791,272.088226 556.893738,272.163971 557.544861,272.602539 
	C550.089722,272.978607 542.950806,273.138611 535.819885,272.967804 
	C526.948669,272.755341 518.565186,274.932983 511.149048,279.378632 
	C507.951050,281.295685 507.026703,286.516266 502.476532,287.661530 
	C502.303467,287.705109 502.084015,288.306458 502.172607,288.538513 
	C504.194794,293.835724 500.432068,293.690186 497.065765,294.216675 
	C496.064362,294.373260 494.981873,295.344696 494.338409,296.233673 
	C490.939880,300.928955 487.663788,305.713043 484.379852,310.490143 
	C484.264313,310.658234 484.513245,311.076874 484.474731,310.929749 
	C485.963074,311.550537 487.175629,312.056305 488.094025,312.749634 
	C486.537781,313.467163 485.275665,313.997162 483.839966,314.144653 
	C475.683838,311.825378 472.555206,314.176208 473.148743,321.712158 
	C472.169189,322.300354 471.381897,322.696991 470.270996,323.152649 
	C469.651367,323.515900 469.355347,323.820190 469.051514,324.077698 
	C469.043671,324.030884 469.119171,324.088379 468.872955,324.159973 
	C468.404022,324.484680 468.181335,324.737793 467.890289,325.087585 
	C467.821930,325.184265 467.591644,325.129059 467.176697,325.130798 
	C466.213348,325.922180 465.664948,326.711853 465.116638,327.676697 
	C464.999878,328.125916 464.805084,328.203003 464.120605,328.073151 
	C462.893677,328.804230 462.078552,329.545349 461.245331,330.281464 
	C461.227234,330.276459 461.217682,330.240173 460.861572,330.198975 
	C460.030853,330.837158 459.556183,331.516541 459.055603,332.391205 
	C458.977112,332.882660 458.803589,333.061340 458.097656,333.094055 
	C454.413574,333.063568 452.944336,334.772827 453.034149,337.997528 
	C453.030670,338.030365 452.964966,338.036896 452.597534,338.028809 
	C451.499695,339.006592 450.769287,339.992462 450.032898,340.999847 
	C450.026978,341.021332 449.986572,341.040985 449.986572,341.040985 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M291.997772,776.189209 
	C290.607330,781.301819 287.431183,783.198669 282.587311,782.918762 
	C279.267059,782.726868 275.919037,782.798157 272.593933,782.963318 
	C268.446350,783.169495 265.381134,786.278137 265.134277,790.258057 
	C264.895874,794.102295 267.799011,797.783203 271.986847,798.101196 
	C276.580536,798.450012 281.221191,798.180664 286.019318,797.768921 
	C279.669464,796.324951 272.576935,795.931519 273.091003,786.375122 
	C280.513702,786.375122 287.915009,786.375122 295.658691,786.375122 
	C295.658691,791.389893 296.041321,796.663269 295.548401,801.853455 
	C295.078979,806.796326 292.687714,810.073669 286.684753,809.221252 
	C282.931366,808.688354 279.034241,809.190796 275.201660,809.188110 
	C263.434265,809.179810 256.723297,802.253845 256.996948,790.424988 
	C257.284546,777.992615 263.227173,772.089050 275.428070,772.111511 
	C279.927429,772.119751 284.435364,772.293701 288.923126,772.068115 
	C291.980682,771.914368 292.913757,772.969971 291.997772,776.189209 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M412.072144,806.179138 
	C409.337646,807.250977 406.906464,808.598206 404.395325,808.768738 
	C398.267578,809.185120 392.102325,809.155151 385.953186,809.120911 
	C379.331635,809.084106 376.550537,806.268127 376.488770,799.756348 
	C376.428833,793.439941 376.367462,787.121826 376.451385,780.806396 
	C376.535797,774.454468 378.930817,772.148254 385.226410,772.128235 
	C391.543701,772.108093 397.861237,772.117310 404.178528,772.143860 
	C412.020905,772.176819 416.738892,778.442444 415.137268,786.151184 
	C414.561737,788.921265 414.555206,791.983521 415.073029,794.766479 
	C415.867737,799.037720 415.110321,802.655701 412.072144,806.179138 
M407.530121,796.423401 
	C400.364410,795.949341 393.198700,795.475342 385.453491,794.962952 
	C385.639435,797.539551 385.750244,799.074829 385.876007,800.817749 
	C392.165710,800.817749 397.969818,800.965576 403.759216,800.738281 
	C405.806824,800.657837 408.778473,800.725159 407.530121,796.423401 
M407.951965,784.986206 
	C408.823242,781.181274 405.853088,781.357239 403.721985,781.256409 
	C399.093567,781.037415 394.436157,781.377625 389.813721,781.101562 
	C385.817505,780.862915 384.708954,782.357666 386.243988,787.028076 
	C393.164459,786.532166 400.301514,786.020813 407.951965,784.986206 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M780.909668,807.174194 
	C779.753967,805.289917 778.876282,803.655334 777.537109,801.161255 
	C782.145020,801.161255 785.592102,801.160583 789.039185,801.161499 
	C793.369507,801.162598 797.718872,801.399597 802.020813,801.051208 
	C803.732666,800.912598 805.339661,799.479431 806.994385,798.635681 
	C805.388367,797.512268 803.861572,795.587769 802.161133,795.419617 
	C797.542175,794.962769 792.849121,795.288818 788.186340,795.232849 
	C781.465515,795.152100 777.292114,790.875671 777.018188,783.871887 
	C776.772827,777.596436 781.119873,772.527283 787.480225,772.246033 
	C793.797119,771.966675 800.136963,772.043762 806.462463,772.173706 
	C811.200378,772.271057 813.854675,775.087646 814.671814,780.916687 
	C807.839539,780.916687 801.078247,780.913574 794.316956,780.919067 
	C792.819031,780.920227 791.198242,780.602417 789.859985,781.073425 
	C788.670410,781.492188 787.794189,782.801147 786.779724,783.717163 
	C787.861023,784.502075 788.887939,785.865173 790.032898,785.972778 
	C795.315491,786.469360 800.696777,786.188782 805.918640,786.992371 
	C813.106384,788.098450 816.294617,792.418823 816.086609,799.468201 
	C815.920349,805.103577 812.769958,808.596313 805.585266,809.261780 
	C797.429199,810.017212 789.029785,811.737610 780.909668,807.174194 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M536.728882,795.259399 
	C529.976135,793.861267 526.561523,789.256958 526.948669,782.631348 
	C527.295288,776.699768 531.327454,772.511780 537.620361,772.207520 
	C543.769714,771.910095 549.948608,771.949341 556.104492,772.149231 
	C561.164856,772.313599 563.598633,774.918335 564.420288,780.922485 
	C557.940308,780.922485 551.534607,780.915955 545.128906,780.926575 
	C543.297485,780.929565 541.384766,780.670898 539.663391,781.121277 
	C538.511536,781.422607 537.631714,782.763611 536.630127,783.639404 
	C537.660034,784.436401 538.643921,785.842041 539.727966,785.923950 
	C544.201355,786.261963 548.743042,785.860413 553.189514,786.357300 
	C561.077576,787.238892 564.857788,790.620972 565.819458,796.878357 
	C566.745239,802.901672 564.011597,807.935852 557.968872,808.764893 
	C550.772522,809.752258 543.378113,809.839050 536.103943,809.523010 
	C531.271545,809.313049 528.836548,806.087280 528.332031,801.158752 
	C534.831299,801.158752 541.299622,801.159302 547.767944,801.158386 
	C549.100525,801.158142 550.556885,801.495422 551.736938,801.069580 
	C553.548218,800.415955 555.169495,799.235779 556.871399,798.279053 
	C555.128906,797.272644 553.446777,795.551697 551.632141,795.398254 
	C546.835999,794.992737 541.982544,795.266418 536.728882,795.259399 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M736.425293,802.451965 
	C733.691895,796.039246 733.592102,789.785400 735.188660,783.505798 
	C736.912109,776.727234 741.310303,772.989319 748.350464,772.676453 
	C753.635254,772.441528 759.045288,772.209290 764.221252,773.060425 
	C770.624451,774.113464 773.673767,778.791260 773.167969,784.863770 
	C772.684326,790.670471 768.095398,794.914001 761.696411,795.262085 
	C758.540039,795.433777 755.367126,795.300537 752.201660,795.302673 
	C749.250610,795.304626 746.299500,795.303101 743.057861,795.303101 
	C743.458374,799.992859 746.178467,800.851807 749.597168,800.850098 
	C756.890564,800.846313 764.183960,800.848938 771.508972,800.848938 
	C771.028687,806.194519 768.595459,808.958862 763.942139,809.110352 
	C757.784790,809.310974 751.614014,809.241455 745.452393,809.117554 
	C741.151489,809.031067 738.497559,806.378235 736.425293,802.451965 
M744.814758,782.303711 
	C744.050720,783.579041 743.286682,784.854370 742.034363,786.944580 
	C749.612915,786.944580 756.063599,787.083191 762.499023,786.830750 
	C763.830139,786.778564 765.104126,785.271667 766.404419,784.435120 
	C765.173645,783.314880 763.964600,781.263245 762.708435,781.233948 
	C756.962952,781.100220 751.204285,781.533386 744.814758,782.303711 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M604.491150,804.627258 
	C602.210083,808.239990 599.144165,809.331116 595.348267,809.216064 
	C590.527893,809.069946 585.699524,809.214478 580.875305,809.164551 
	C574.445862,809.097961 570.109741,806.446960 569.028137,800.035217 
	C568.060486,794.299011 568.306030,788.183594 569.091736,782.381104 
	C569.724670,777.706604 573.329102,773.943909 578.022034,773.325439 
	C584.370300,772.488708 590.962952,772.297852 597.313843,773.025330 
	C603.822571,773.770874 606.829163,778.334595 606.499756,784.553162 
	C606.199463,790.222107 602.512207,794.414856 596.404968,795.013000 
	C591.129211,795.529724 585.775330,795.241882 580.456299,795.329529 
	C579.164734,795.350830 577.874512,795.450378 576.294434,795.528137 
	C576.872192,799.742676 579.339722,800.865173 582.869385,800.838745 
	C590.174622,800.783875 597.480591,800.822266 605.277222,800.822266 
	C605.038818,802.238098 604.863525,803.279358 604.491150,804.627258 
M599.730286,784.474548 
	C598.988098,783.498901 598.271606,781.701965 597.499634,781.677734 
	C591.453064,781.488708 585.376526,781.395935 579.356995,781.874695 
	C578.026855,781.980530 576.887268,784.480225 575.659912,785.877563 
	C582.704285,787.023682 588.842834,787.140564 594.970947,786.939392 
	C596.457458,786.890625 597.909607,785.793335 599.730286,784.474548 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M488.388153,312.562073 
	C487.175629,312.056305 485.963074,311.550537 484.474731,310.929749 
	C484.513245,311.076874 484.264313,310.658234 484.379852,310.490143 
	C487.663788,305.713043 490.939880,300.928955 494.338409,296.233673 
	C494.981873,295.344696 496.064362,294.373260 497.065765,294.216675 
	C500.432068,293.690186 504.194794,293.835724 502.172607,288.538513 
	C502.084015,288.306458 502.303467,287.705109 502.476532,287.661530 
	C507.026703,286.516266 507.951050,281.295685 511.149048,279.378632 
	C518.565186,274.932983 526.948669,272.755341 535.819885,272.967804 
	C542.950806,273.138611 550.089722,272.978607 557.704834,272.943176 
	C558.892029,272.931976 559.599487,272.943024 560.643127,273.411499 
	C559.693726,274.903595 558.548096,276.236359 557.096619,276.917908 
	C552.406372,279.120209 546.249390,278.807892 544.039062,285.178406 
	C543.716125,286.109283 541.597107,286.471008 540.264465,286.978699 
	C539.369324,287.319702 537.870056,287.152222 537.539551,287.727997 
	C534.843018,292.425629 530.188293,292.199677 525.759216,293.201355 
	C522.958435,293.834656 520.578308,296.327789 517.675659,297.993530 
	C516.897034,298.003357 516.452148,298.006439 515.832397,297.779846 
	C515.114319,298.055359 514.571228,298.560547 513.911743,299.277771 
	C513.581604,299.745941 513.314026,299.918915 512.627319,300.013489 
	C511.476898,300.740387 510.691864,301.462646 509.794922,302.226196 
	C509.682983,302.267548 509.544434,302.073242 509.141846,302.080170 
	C508.179840,302.564240 507.620392,303.041412 506.917725,303.686523 
	C506.355591,303.928955 505.936737,304.003448 505.146606,304.073425 
	C504.148438,304.391327 503.521484,304.713776 502.811096,305.114136 
	C502.727692,305.192078 502.529510,305.078613 502.159363,305.075439 
	C501.205933,305.430969 500.622589,305.789642 500.034729,306.104034 
	C500.030212,306.059723 500.109039,306.101227 499.750366,306.070068 
	C498.634766,306.444275 497.877869,306.849609 497.075775,307.169861 
	C497.030609,307.084778 497.164185,307.223602 496.770020,307.146362 
	C495.318115,307.788879 494.260406,308.508636 493.173523,309.225647 
	C493.144379,309.222839 493.119110,309.170013 492.750854,309.113220 
	C491.598328,309.736145 490.814026,310.415924 489.913635,311.361389 
	C489.327759,311.938782 488.857971,312.250427 488.388153,312.562073 
M520.408386,277.482361 
	C520.408386,277.482361 520.521851,277.414185 520.408386,277.482361 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M337.573608,777.568481 
	C346.363770,770.718079 355.637421,770.680176 365.035645,774.813660 
	C371.663635,777.728882 375.126709,786.619690 373.721100,795.390625 
	C372.629639,802.201233 367.339478,807.487427 360.100525,808.715332 
	C357.663544,809.128723 355.140503,809.146240 352.656525,809.149902 
	C345.302765,809.160706 338.462891,807.424927 335.042725,800.277527 
	C331.361420,792.584351 331.744141,784.817749 337.573608,777.568481 
M353.504578,799.124023 
	C354.666443,799.115601 355.829590,799.139221 356.989960,799.093140 
	C361.611420,798.909668 364.874725,796.016479 365.379944,791.681885 
	C365.920563,787.043640 363.696075,783.653381 358.763794,782.794495 
	C355.403839,782.209412 351.796051,782.081482 348.442810,782.629272 
	C341.833984,783.708801 339.486694,790.319702 343.239929,795.937988 
	C345.611664,799.488281 349.120514,799.112732 353.504578,799.124023 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M563.537964,636.430908 
	C562.457581,635.807007 561.792542,634.693909 561.015564,634.609253 
	C557.939758,634.274414 554.698242,633.634766 551.755676,634.247986 
	C543.265015,636.017578 538.160461,628.069824 530.435547,627.311829 
	C527.213135,626.995667 524.401733,623.372864 521.203735,621.617554 
	C519.034424,620.426819 516.517273,619.869812 512.825012,618.555847 
	C515.456299,617.483765 516.920166,616.887329 519.446289,615.858032 
	C516.031189,614.097900 513.273926,612.654175 510.495850,611.251648 
	C507.997162,609.990234 505.475555,608.774231 503.348419,606.784912 
	C505.781403,606.983643 508.214417,607.182434 511.236694,607.080750 
	C530.499451,613.105957 549.617432,616.736267 569.404358,615.788818 
	C577.985168,615.377991 586.541321,614.455017 595.005737,614.177368 
	C594.439331,615.014587 593.987549,615.796387 593.510620,615.811951 
	C579.166077,616.281799 564.818909,616.672363 550.472534,617.086487 
	C548.828125,617.133972 547.185547,617.246826 545.542114,617.329346 
	C545.541992,617.824036 545.541870,618.318665 545.541748,618.813293 
	C551.737366,619.801514 557.932983,620.789734 564.067871,621.768311 
	C562.550781,626.553894 562.877197,627.079895 568.745239,626.954529 
	C572.738586,626.869263 576.523560,626.757507 577.535767,631.371399 
	C585.617188,629.873535 593.329224,628.444092 601.027100,627.235962 
	C601.012878,627.457214 600.997925,627.900391 600.997925,627.900391 
	C590.908447,630.462036 580.818909,633.023682 570.236572,635.176453 
	C566.506836,633.468262 566.156982,633.572449 565.986572,635.886108 
	C565.291687,636.062195 564.596802,636.238281 563.537964,636.430908 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M456.972229,798.917419 
	C456.812408,804.875183 454.455231,808.944824 451.005524,809.038208 
	C447.173981,809.142029 443.339020,809.118774 439.505524,809.149475 
	C438.839478,809.154846 438.173370,809.156982 437.507294,809.154480 
	C422.747284,809.098755 414.958313,798.610107 419.077271,784.337219 
	C421.314667,776.584229 427.357513,772.358765 436.468750,772.176147 
	C446.693054,771.971252 452.825531,775.533569 455.352356,783.487366 
	C456.247833,786.306030 456.504028,789.327759 457.011902,793.103210 
	C456.971863,795.605591 456.972046,797.261475 456.972229,798.917419 
M440.020264,782.075134 
	C438.032257,782.100830 436.016479,781.922913 434.061249,782.189087 
	C427.258514,783.115479 424.211487,789.752991 427.845123,795.605896 
	C431.246796,801.085083 436.805450,799.140686 441.440399,798.870300 
	C443.808929,798.732056 446.883148,796.415833 448.134857,794.224976 
	C451.237946,788.793701 448.274597,784.326538 440.020264,782.075134 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M518.009521,297.986755 
	C520.578308,296.327789 522.958435,293.834656 525.759216,293.201355 
	C530.188293,292.199677 534.843018,292.425629 537.539551,287.727997 
	C537.870056,287.152222 539.369324,287.319702 540.264465,286.978699 
	C541.597107,286.471008 543.716125,286.109283 544.039062,285.178406 
	C546.249390,278.807892 552.406372,279.120209 557.096619,276.917908 
	C558.548096,276.236359 559.693726,274.903595 560.987549,273.492554 
	C568.513367,273.916016 576.155823,274.123627 583.515137,275.674896 
	C591.581726,277.375275 599.386963,280.315460 607.140503,283.133820 
	C603.903320,283.172424 600.811035,282.910248 597.767090,282.373688 
	C595.522766,281.978058 593.529968,281.403778 592.683655,284.475494 
	C591.480042,288.843872 588.463074,290.520874 583.991455,290.074036 
	C583.001892,289.975128 581.923462,290.766327 580.523315,291.125244 
	C578.412415,291.721069 576.664185,292.339325 574.502380,293.044861 
	C572.412781,293.045319 570.736755,292.958527 568.736450,292.605865 
	C551.551697,290.840393 535.196838,293.752991 518.748779,297.997620 
	C518.502441,297.992706 518.009521,297.986725 518.009521,297.986755 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M706.075012,783.417358 
	C702.656189,785.976624 700.326965,788.836182 701.909851,792.949463 
	C703.554626,797.223694 707.134949,798.651794 711.508301,798.617920 
	C717.953491,798.568054 724.399414,798.605042 730.814026,798.605042 
	C731.128540,806.194519 728.612366,809.060730 721.914062,809.170410 
	C718.254639,809.230347 714.593323,809.175171 710.932861,809.170532 
	C697.548096,809.153442 689.277039,797.691650 693.318848,784.762207 
	C696.181946,775.603394 700.888123,772.133179 710.468323,772.126892 
	C717.258423,772.122375 724.048584,772.125977 730.812927,772.125977 
	C731.094299,779.727600 728.388977,782.712891 721.435120,782.910156 
	C716.454041,783.051392 711.472473,783.174561 706.075012,783.417358 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M686.632812,712.247070 
	C684.835144,713.557800 684.036011,713.278809 683.170654,711.003784 
	C679.561096,701.513977 676.015869,691.997925 672.221436,682.582458 
	C671.759338,681.435791 669.975220,680.188110 668.729248,680.105103 
	C664.250061,679.806580 659.737671,680.051758 655.240295,679.963745 
	C652.344971,679.907104 650.960083,681.029663 650.966675,684.063965 
	C651.014526,706.059326 650.914490,728.055298 651.043274,750.049988 
	C651.079407,756.218872 654.877441,758.894592 661.151672,757.821899 
	C663.590942,757.404785 666.044189,757.069336 669.537842,756.537292 
	C669.921631,745.293945 670.321838,733.570740 670.722046,721.847595 
	C671.495117,721.710632 672.268250,721.573669 673.041321,721.436707 
	C674.694763,725.524170 676.353210,729.609558 678.000549,733.699463 
	C680.903381,740.906189 683.758850,748.132507 686.758667,755.298523 
	C687.072205,756.047546 688.215027,756.918335 688.986816,756.931458 
	C694.557007,757.026367 700.137329,757.036926 705.695618,756.731140 
	C706.487976,756.687561 707.178467,754.793518 707.956238,752.907593 
	C707.995667,730.771240 708.026184,709.483093 707.951111,688.195374 
	C707.942627,685.790161 707.339111,683.386963 707.011536,680.982849 
	C706.911926,680.659607 706.724976,680.399048 705.924133,679.817383 
	C699.935486,679.617554 694.473450,679.801636 689.011353,679.985718 
	C688.673401,684.192627 688.227478,688.394531 688.031128,692.608032 
	C687.869202,696.083923 688.002563,699.573547 687.786560,703.534729 
	C687.568054,704.012329 687.122864,704.037659 687.122864,704.037659 
	C687.122864,695.561829 687.122864,687.085999 687.122864,678.626282 
	C694.491943,678.626282 701.828125,678.626282 709.317627,678.626282 
	C709.317627,705.492249 709.317627,731.910278 709.317627,759.005615 
	C701.908386,759.005615 694.664246,759.136353 687.435791,758.858337 
	C686.405273,758.818665 685.032166,756.960693 684.528076,755.667358 
	C680.686218,745.811340 677.020874,735.886414 672.346924,726.109192 
	C672.346924,736.855591 672.346924,747.602051 672.346924,758.700928 
	C664.576782,758.700928 657.343140,758.700928 649.802307,758.700928 
	C649.802307,732.018311 649.802307,705.287720 649.802307,677.877258 
	C656.661926,677.877258 663.591370,677.764954 670.507874,678.012512 
	C671.422058,678.045166 672.594666,679.773254 673.098938,680.951050 
	C677.547546,691.340759 681.872070,701.783569 686.632812,712.247070 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M580.885925,291.147705 
	C581.923462,290.766327 583.001892,289.975128 583.991455,290.074036 
	C588.463074,290.520874 591.480042,288.843872 592.683655,284.475494 
	C593.529968,281.403778 595.522766,281.978058 597.767090,282.373688 
	C600.811035,282.910248 603.903320,283.172424 607.423584,283.341980 
	C607.872253,283.131714 607.838928,283.151337 607.679260,283.520325 
	C609.035583,284.949890 610.490662,286.126740 612.116272,286.978027 
	C612.463257,287.159790 613.429504,286.159180 614.110474,285.703278 
	C615.089111,285.985718 616.067688,286.268188 616.814941,286.961609 
	C616.768127,287.863037 616.952637,288.353516 617.137085,288.843994 
	C617.788086,288.483978 618.439087,288.123962 619.090088,287.763916 
	C619.604431,287.917206 620.118774,288.070526 620.887573,288.736206 
	C621.425964,289.500610 621.709839,289.752625 622.030884,290.315704 
	C623.033447,290.621887 623.998901,290.616974 624.964294,290.612061 
	C628.834900,292.586182 632.705505,294.560303 636.914795,297.010254 
	C637.696350,297.699493 638.139221,297.912842 638.582031,298.126160 
	C638.582031,298.126160 638.877747,298.112000 638.986816,298.565369 
	C641.455566,300.319824 643.815308,301.620941 646.175049,302.922058 
	C646.509033,302.867676 646.775696,302.977142 647.117554,303.817963 
	C648.044434,307.582794 648.824585,310.781219 649.615601,313.976959 
	C649.864502,314.982239 650.142639,315.980286 650.024109,316.940674 
	C642.091553,312.875244 634.542175,308.850708 626.809448,304.614441 
	C626.094299,304.242157 625.562622,304.081665 624.947632,303.852325 
	C624.864319,303.783478 624.948914,303.584625 624.799011,303.201630 
	C623.895874,302.589233 623.142578,302.359833 622.229797,302.329773 
	C621.764282,302.432281 621.458313,302.335419 620.974243,301.895447 
	C616.718384,300.396667 612.640747,299.240936 608.441101,298.156494 
	C608.319153,298.227722 608.114075,298.033508 607.839478,297.744812 
	C606.236938,297.331482 604.909058,297.206848 603.491455,297.165710 
	C603.401733,297.249237 603.188721,297.127869 602.941895,296.828064 
	C599.457153,295.661591 596.219360,294.794891 592.743286,293.666229 
	C591.323425,293.284363 590.141846,293.164459 588.563599,293.041351 
	C585.739868,292.407990 583.312927,291.777832 580.885925,291.147705 
M592.481323,289.592316 
	C592.481323,289.592316 592.410339,289.476227 592.481323,289.592316 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M644.063416,780.259094 
	C643.687073,779.471191 643.319153,779.044922 643.259827,778.579285 
	C643.015320,776.661499 642.864502,774.731689 642.679321,772.806274 
	C645.232788,773.425415 648.979065,773.211670 650.106384,774.826355 
	C652.999207,778.969666 654.760315,783.910339 656.911072,788.561279 
	C658.007629,790.932617 658.969299,793.366333 660.578308,797.151184 
	C663.593933,790.163086 665.799805,784.332764 668.628845,778.822388 
	C669.895630,776.354919 672.186035,774.216797 674.471375,772.564514 
	C675.394348,771.897156 677.770813,772.569641 679.028625,773.360046 
	C679.545166,773.684753 679.188171,776.076111 678.660889,777.278442 
	C674.394714,787.005432 669.843811,796.610474 665.731750,806.400513 
	C664.617554,809.053284 663.042053,809.138977 660.780396,809.247498 
	C658.173828,809.372559 656.569092,808.785645 655.450623,806.109375 
	C651.868591,797.538757 647.982422,789.095215 644.063416,780.259094 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M861.591064,678.223694 
	C860.604919,678.907227 859.218994,679.881653 857.814575,679.909058 
	C849.989441,680.061584 842.154175,680.164246 834.334167,679.914124 
	C830.101624,679.778687 828.901306,681.421692 828.930176,685.480042 
	C829.079712,706.464844 828.975281,727.451294 829.010254,748.437073 
	C829.024048,756.710144 830.349426,758.054810 838.470276,757.976746 
	C842.105103,757.941833 845.736328,757.571350 849.369751,757.362610 
	C852.315063,757.193359 853.130249,755.617126 853.072998,752.761108 
	C852.903015,744.270264 852.973938,735.773438 853.036377,727.279602 
	C853.064514,723.456116 853.822021,720.392700 858.948975,720.939087 
	C862.487061,721.316040 864.013062,722.857544 864.000183,726.314270 
	C863.968506,734.808350 864.141663,743.305664 863.957947,751.795776 
	C863.857056,756.458923 865.459900,758.581970 870.377625,758.061768 
	C875.293823,757.541748 880.256592,757.463257 886.029053,757.145508 
	C885.144287,746.299622 888.230835,735.805908 884.009216,725.794861 
	C883.038147,723.492004 879.810425,722.180786 877.734619,720.288696 
	C876.481750,719.146729 875.447510,717.764893 874.315002,716.490845 
	C875.566956,715.608948 876.699890,714.368347 878.091309,713.906677 
	C884.435303,711.801270 887.413513,705.213379 885.880798,696.208923 
	C884.118530,685.856628 878.269470,680.712280 867.489014,679.957642 
	C866.728210,679.904358 865.996094,679.441223 865.117676,678.827087 
	C886.986511,677.455322 890.754944,694.179077 886.379028,709.600342 
	C885.498657,712.702637 881.207275,714.836975 878.103210,717.783325 
	C888.116760,720.268066 888.129761,728.452698 887.935730,736.872070 
	C887.771423,744.005371 887.901917,751.145447 887.901917,758.666565 
	C879.669678,758.666565 871.606384,758.666565 862.823853,758.666565 
	C862.823853,752.501038 862.829041,746.259644 862.821777,740.018188 
	C862.817749,736.522583 862.918396,733.020996 862.749512,729.532776 
	C862.463135,723.617920 861.565613,722.887146 855.126709,722.992004 
	C855.126709,734.752625 855.126709,746.525208 855.126709,758.668335 
	C846.104736,758.668335 837.380432,758.668335 828.292847,758.668335 
	C828.292847,731.966919 828.292847,705.098572 828.292847,678.077271 
	C839.275085,678.077271 850.228882,678.077271 861.591064,678.223694 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M457.305389,798.532837 
	C456.972046,797.261475 456.971863,795.605591 456.968323,793.536743 
	C456.964935,793.123779 457.205597,793.401611 457.522156,793.819580 
	C458.093689,793.660400 458.348663,793.083374 458.603638,792.506287 
	C458.712097,789.196228 458.744019,785.881531 458.945435,782.577087 
	C459.332245,776.230774 462.216217,773.169128 468.767242,772.542053 
	C468.767242,780.956482 468.767242,789.333740 468.767242,798.251221 
	C473.431519,798.251221 477.538116,798.231812 481.644409,798.255432 
	C489.080811,798.298279 491.949036,800.901611 493.016388,809.112976 
	C483.884705,809.112976 474.774261,809.620972 465.791382,808.774780 
	C463.386200,808.548157 461.283936,804.258606 459.219788,801.691711 
	C458.436005,800.716980 458.150940,799.341248 457.305389,798.532837 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M660.952942,594.803284 
	C649.283081,602.584900 637.760925,610.602966 625.871887,618.033875 
	C620.986084,621.087646 615.309692,622.876465 609.537231,625.160828 
	C609.081665,625.081055 608.936462,624.895813 609.178101,624.640503 
	C610.750122,622.525818 612.080383,620.666443 613.737305,618.350403 
	C609.442261,616.905151 605.225464,615.486267 601.375366,614.009827 
	C604.037964,613.400574 606.380493,612.986450 608.607422,612.231140 
	C609.528809,611.918640 610.202332,610.875366 611.355957,610.067627 
	C627.479431,604.004211 642.942261,597.434204 657.101929,588.245239 
	C656.985291,589.517639 656.763916,590.512024 656.835938,590.188538 
	C658.615967,592.183777 659.784485,593.493530 660.952942,594.803284 
M650.704102,596.569763 
	C650.991760,596.294983 651.282166,596.022766 651.552002,595.731445 
	C651.559021,595.723877 651.340210,595.507263 651.226746,595.388123 
	C650.939087,595.663391 650.651489,595.938599 650.704102,596.569763 
M653.406921,593.481689 
	C653.406921,593.481689 653.524292,593.410645 653.406921,593.481689 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M326.143433,809.193237 
	C319.576813,809.186096 313.414490,809.234375 307.253693,809.157166 
	C301.472961,809.084717 298.109344,806.061462 297.912231,800.382202 
	C297.698700,794.228516 297.719238,788.054871 297.945099,781.901245 
	C298.156494,776.140869 300.800903,773.531677 307.330627,772.410400 
	C307.330627,780.855042 307.330627,789.233032 307.330627,798.238647 
	C311.993103,798.238647 316.093140,798.206299 320.192535,798.245728 
	C327.838989,798.319275 330.525543,800.688232 331.810211,808.948608 
	C330.017578,809.032166 328.282959,809.113037 326.143433,809.193237 
z"
          />
          <path
            fill="#0F0E66"
            opacity="1.000000"
            stroke="none"
            d="
M622.732178,774.086975 
	C627.970459,773.477173 632.801208,772.989258 637.783508,772.486084 
	C637.307495,781.649231 636.902771,782.119080 629.774780,782.851746 
	C620.208496,783.835022 619.068054,785.077881 619.072266,794.514526 
	C619.073120,796.341614 619.109436,798.169617 619.065796,799.995667 
	C618.920715,806.067566 615.751953,808.860596 608.274170,808.718445 
	C608.274170,799.993225 608.186218,791.240051 608.308044,782.489868 
	C608.378235,777.448120 610.845032,773.765137 615.914856,772.832764 
	C617.899902,772.467651 620.179321,773.702637 622.732178,774.086975 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M718.220459,399.218201 
	C724.565125,418.442627 727.605957,438.134521 726.986145,458.412231 
	C726.414734,477.107025 723.835815,495.410370 716.828735,513.476501 
	C714.752930,516.696350 713.284363,519.522888 711.698730,522.282166 
	C708.640076,527.604675 705.515320,532.889221 702.260498,537.869385 
	C701.420715,537.003479 700.738342,536.457764 700.091431,535.573364 
	C715.541809,513.007324 724.230164,488.353180 725.830322,461.402313 
	C726.810547,444.891663 724.921082,428.576294 719.117371,412.932190 
	C715.896851,404.251129 712.399841,395.672638 709.376404,387.036469 
	C710.192200,387.037262 710.659058,387.049530 711.349487,387.312134 
	C713.359070,391.457825 715.132324,395.359192 716.976074,399.226929 
	C717.051697,399.385681 717.790588,399.228302 718.220459,399.218201 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M650.407349,316.981628 
	C650.142639,315.980286 649.864502,314.982239 649.615601,313.976959 
	C648.824585,310.781219 648.044434,307.582794 647.191650,304.031616 
	C649.414368,305.202301 651.705383,306.726776 653.807617,308.625549 
	C653.465881,309.795074 653.313049,310.590332 653.160156,311.385559 
	C653.831482,310.795471 654.502808,310.205414 655.174194,309.615356 
	C664.598206,318.398621 674.746887,326.542267 683.230347,336.155518 
	C690.821533,344.757721 696.577026,354.979889 702.819275,364.795990 
	C701.689819,365.052429 700.883545,365.000153 700.056641,364.953247 
	C700.036072,364.958618 700.049622,364.925629 700.012207,364.698608 
	C699.841492,364.174011 699.620850,363.971710 699.167297,363.556213 
	C697.583374,361.254669 696.145081,359.261597 694.718018,356.993286 
	C694.437866,356.535217 694.146484,356.352417 693.870239,356.109436 
	C693.885376,356.049255 693.809143,356.147247 693.856201,355.803589 
	C693.155334,354.734161 692.407471,354.008392 691.509033,353.191406 
	C691.358521,353.100189 691.088379,352.874451 691.082764,352.531433 
	C690.385498,351.474487 689.693726,350.760529 688.980652,350.037689 
	C688.959290,350.028809 688.954773,349.982788 688.958496,349.657990 
	C688.311279,348.903290 687.660339,348.473419 686.988586,348.037720 
	C686.967834,348.031952 686.956543,347.990387 686.959045,347.669495 
	C686.338745,346.875214 685.715942,346.401794 684.834717,345.869141 
	C684.402832,345.843536 684.229370,345.877167 684.030273,345.943604 
	C684.004700,345.976410 684.084961,345.954498 684.046997,345.672455 
	C683.682312,345.238953 683.355652,345.087494 683.017212,344.967529 
	C683.005493,344.998962 683.060974,344.961151 683.029297,344.670776 
	C682.686584,343.888153 682.375610,343.395874 682.040894,342.582397 
	C681.368713,341.825928 680.720337,341.390625 679.845947,340.932159 
	C679.619934,340.908997 679.165710,340.907501 679.096436,340.589478 
	C677.921448,338.962036 676.815735,337.652679 675.703308,336.004517 
	C674.825989,335.087860 673.955444,334.510010 672.916382,333.765411 
	C672.514465,333.384552 672.264160,333.192505 671.980713,332.675171 
	C670.056335,330.792053 668.148193,329.256287 666.126404,327.402130 
	C663.778320,325.626465 661.543884,324.169189 659.170776,322.449158 
	C658.415405,321.726593 657.798767,321.266724 656.974854,320.556488 
	C655.158875,319.561493 653.550110,318.816895 651.695679,318.024353 
	C651.102356,317.644867 650.754883,317.313232 650.407349,316.981628 
M649.598633,307.515533 
	C649.598633,307.515533 649.478760,307.588379 649.598633,307.515533 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M661.237305,594.767944 
	C659.784485,593.493530 658.615967,592.183777 656.835938,590.188538 
	C656.763916,590.512024 656.985291,589.517639 657.119019,587.993652 
	C656.993896,587.300110 656.956421,587.136169 656.949097,586.978638 
	C656.979126,586.985046 656.974854,586.924683 657.324707,586.933594 
	C660.611206,584.831848 663.861755,583.043945 666.425354,580.550049 
	C674.034119,573.147949 681.372314,565.467712 688.950806,557.770386 
	C689.085388,557.644531 689.209839,557.297791 689.547424,557.227173 
	C690.725708,555.854065 691.566406,554.551514 692.564026,553.154846 
	C692.720886,553.060791 692.906677,552.745605 693.227783,552.713135 
	C694.032776,551.789551 694.516724,550.898438 695.117249,549.879761 
	C695.233826,549.752258 695.309570,549.415100 695.588257,549.381958 
	C696.197693,549.174744 696.528564,549.000610 697.186584,548.907837 
	C698.085205,549.603455 698.656616,550.217712 699.228027,550.831909 
	C699.228027,550.831909 699.124817,551.166077 698.737183,551.334351 
	C697.993408,552.279297 697.637207,553.056030 697.281006,553.832764 
	C688.317200,568.787598 676.713257,581.469116 663.250488,592.778503 
	C662.765320,592.692688 662.677979,592.721008 662.590637,592.749390 
	C662.694275,592.815369 662.797913,592.881348 662.901550,592.947327 
	C662.441528,593.542419 661.981567,594.137512 661.237305,594.767944 
z"
          />
          <path
            fill="#111166"
            opacity="1.000000"
            stroke="none"
            d="
M683.719604,808.962036 
	C682.787903,808.230835 681.782532,807.430054 681.783936,806.630981 
	C681.799194,797.825073 681.864075,789.016052 682.144592,780.215149 
	C682.288696,775.692810 684.719116,772.952148 689.403870,772.320984 
	C689.403870,782.681885 689.702209,792.753296 689.163818,802.779663 
	C689.049866,804.901733 685.876953,806.859619 683.719604,808.962036 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M699.519287,550.748901 
	C698.656616,550.217712 698.085205,549.603455 697.224854,548.572876 
	C697.721863,546.544556 698.507812,544.932556 699.575562,543.162598 
	C700.494568,541.970703 701.131836,540.936768 701.884277,539.620544 
	C702.139038,538.955322 702.278503,538.572449 702.417969,538.189514 
	C705.515320,532.889221 708.640076,527.604675 711.698730,522.282166 
	C713.284363,519.522888 714.752930,516.696350 716.616699,513.737915 
	C711.242859,525.939026 705.526672,538.302490 699.519287,550.748901 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M700.077271,364.947876 
	C700.883545,365.000153 701.689819,365.052429 702.899414,365.163513 
	C708.230652,376.274200 713.158508,387.326050 718.153442,398.798035 
	C717.790588,399.228302 717.051697,399.385681 716.976074,399.226929 
	C715.132324,395.359192 713.359070,391.457825 711.338074,386.927155 
	C710.269958,384.332642 709.574280,382.300354 708.577576,380.428314 
	C705.811218,375.232483 702.919495,370.103333 700.077271,364.947876 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M646.036255,302.621277 
	C643.815308,301.620941 641.455566,300.319824 638.987671,298.713379 
	C641.218811,299.712219 643.558105,301.016388 646.036255,302.621277 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M687.047363,704.465515 
	C687.122864,704.037659 687.568054,704.012329 687.790955,704.002930 
	C687.920715,706.198853 687.827576,708.404053 687.423950,710.797241 
	C687.066223,708.954529 687.019043,706.923950 687.047363,704.465515 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M601.365967,628.029175 
	C600.997925,627.900391 601.012878,627.457214 601.343262,627.206665 
	C602.452759,626.627258 603.231812,626.298279 604.308350,625.951782 
	C605.083313,625.625183 605.560791,625.316101 606.401001,625.003723 
	C607.488037,624.965576 608.212219,624.930725 608.936462,624.895813 
	C608.936462,624.895813 609.081665,625.081055 609.156616,625.171875 
	C606.732361,626.227783 604.233154,627.192871 601.365967,628.029175 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M613.891968,285.458374 
	C613.429504,286.159180 612.463257,287.159790 612.116272,286.978027 
	C610.490662,286.126740 609.035583,284.949890 607.689087,283.527527 
	C608.536438,283.445038 609.214294,283.724365 610.188477,284.452271 
	C610.484802,284.900818 610.816956,284.974182 610.816956,284.974182 
	C610.816956,284.974182 611.155396,284.939117 611.155396,284.939087 
	C611.994751,285.030548 612.834167,285.122009 613.891968,285.458374 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M566.394165,635.966919 
	C566.156982,633.572449 566.506836,633.468262 569.865112,635.050049 
	C568.924927,635.570923 567.863342,635.809326 566.394165,635.966919 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M655.111816,309.406494 
	C654.502808,310.205414 653.831482,310.795471 653.160156,311.385559 
	C653.313049,310.590332 653.465881,309.795074 653.950623,308.931854 
	C654.604248,308.823242 654.859863,308.934509 655.111816,309.406494 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M538.509277,271.455139 
	C537.143127,271.736603 535.415771,271.885712 533.302979,272.001953 
	C534.661072,271.753662 536.404602,271.538239 538.509277,271.455139 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M624.772461,290.379852 
	C623.998901,290.616974 623.033447,290.621887 622.034058,290.313263 
	C622.000000,289.999756 622.004944,289.994659 622.365967,289.971497 
	C622.964600,289.907043 623.202271,289.865784 623.439880,289.824554 
	C623.820129,289.932251 624.200317,290.039948 624.772461,290.379852 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M618.892761,287.564392 
	C618.439087,288.123962 617.788086,288.483978 617.137085,288.843994 
	C616.952637,288.353516 616.768127,287.863037 616.826050,287.189575 
	C617.610779,287.125977 618.153076,287.245422 618.892761,287.564392 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M697.526123,553.740601 
	C697.637207,553.056030 697.993408,552.279297 698.587830,551.425293 
	C698.474487,552.114807 698.122864,552.881653 697.526123,553.740601 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M514.776733,273.804565 
	C514.444458,274.121033 513.867676,274.322113 513.171265,274.331665 
	C513.545105,273.989807 514.038757,273.839508 514.776733,273.804565 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M638.546692,297.853699 
	C638.139221,297.912842 637.696350,297.699493 637.135742,297.264923 
	C637.515808,297.222900 638.013550,297.402100 638.546692,297.853699 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M554.776855,271.887238 
	C554.555664,272.022522 554.159363,272.032623 553.569458,271.998688 
	C553.784424,271.890472 554.193176,271.826263 554.776855,271.887238 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M621.996826,290.002197 
	C621.709839,289.752625 621.425964,289.500610 621.022705,288.983093 
	C621.103516,288.725464 621.303711,288.733337 621.749268,289.139771 
	C621.994568,289.538330 622.004944,289.994659 622.004944,289.994659 
	C622.004944,289.994659 622.000000,289.999756 621.996826,290.002197 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M622.365967,289.971497 
	C622.004944,289.994659 621.994568,289.538330 621.996826,289.310120 
	C622.377686,289.156830 622.756287,289.231750 623.287354,289.565613 
	C623.202271,289.865784 622.964600,289.907043 622.365967,289.971497 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M611.020752,284.833008 
	C611.155396,284.939117 610.816956,284.974182 610.816956,284.974182 
	C610.816956,284.974182 610.484802,284.900818 610.346313,284.810547 
	C610.434143,284.694977 610.660217,284.697174 611.020752,284.833008 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M525.623230,272.260162 
	C525.575317,272.494690 525.241516,272.659424 524.621460,272.770325 
	C524.669250,272.541107 525.003235,272.365723 525.623230,272.260162 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M656.919006,586.972229 
	C656.956421,587.136169 656.993896,587.300110 657.014282,587.715576 
	C642.942261,597.434204 627.479431,604.004211 610.954529,610.050659 
	C605.296326,611.393188 600.406860,612.655762 595.436890,613.822266 
	C595.356384,613.726135 595.108704,613.764648 595.108704,613.764648 
	C586.541321,614.455017 577.985168,615.377991 569.404358,615.788818 
	C549.617432,616.736267 530.499451,613.105957 511.441010,606.770264 
	C490.384460,598.727966 471.383911,587.909851 455.414886,572.357727 
	C413.566132,531.601685 397.531494,482.196350 407.086548,424.691406 
	C411.828705,396.151703 424.772186,371.235687 443.144012,348.632416 
	C443.211487,348.259674 443.161255,348.189117 443.465637,348.193817 
	C443.909485,347.850800 444.048920,347.503113 444.219452,347.180939 
	C444.250549,347.206482 444.330933,347.210052 444.330933,347.210052 
	C446.136658,345.354919 447.942383,343.499786 449.867310,341.342834 
	C449.986572,341.040985 450.026978,341.021332 450.318054,340.893494 
	C451.394379,339.856079 452.179657,338.946472 452.964966,338.036865 
	C452.964966,338.036896 453.030670,338.030365 453.339294,337.883789 
	C455.268311,336.199005 456.888672,334.660767 458.509064,333.122559 
	C458.803589,333.061340 458.977112,332.882660 459.368896,332.318665 
	C460.211334,331.447296 460.714508,330.843719 461.217682,330.240173 
	C461.217682,330.240173 461.227234,330.276459 461.587097,330.308838 
	C462.808777,329.588562 463.670593,328.835876 464.532379,328.083191 
	C464.805084,328.203003 464.999878,328.125916 465.449829,327.542358 
	C466.385803,326.531586 466.988708,325.830322 467.591614,325.129059 
	C467.591644,325.129059 467.821930,325.184265 468.165649,325.039978 
	C468.712616,324.626556 468.915894,324.357483 469.119171,324.088379 
	C469.119171,324.088379 469.043671,324.030884 469.396606,324.075867 
	C470.031281,323.778442 470.312958,323.436066 470.594635,323.093658 
	C471.381897,322.696991 472.169189,322.300354 473.432800,321.660889 
	C474.625549,320.797577 475.341980,320.177094 476.388245,319.420715 
	C476.838654,318.869690 476.959229,318.454559 477.044006,318.050232 
	C477.008179,318.061096 477.047638,318.124725 477.406830,318.260223 
	C478.354126,317.951111 478.942230,317.506470 479.588470,317.111938 
	C479.646606,317.162018 479.777283,317.081512 480.106079,317.082214 
	C480.641968,316.738251 480.849091,316.393555 481.050293,316.043182 
	C481.044342,316.037537 481.069824,316.059357 481.464752,316.004883 
	C481.663513,315.406342 481.467377,314.862305 481.486206,314.538910 
	C482.471954,314.682098 483.242767,314.604614 484.013550,314.527161 
	C485.275665,313.997162 486.537781,313.467163 488.094025,312.749634 
	C488.857971,312.250427 489.327759,311.938782 490.255615,311.342255 
	C491.515472,310.428253 492.317291,309.799133 493.119141,309.170044 
	C493.119110,309.170013 493.144379,309.222839 493.507385,309.345367 
	C494.968323,308.719818 496.066254,307.971710 497.164185,307.223602 
	C497.164185,307.223602 497.030609,307.084778 497.417358,307.279633 
	C498.572388,307.016724 499.340729,306.558990 500.109039,306.101227 
	C500.109039,306.101227 500.030212,306.059723 500.399841,306.132935 
	C501.356140,305.830292 501.942841,305.454468 502.529510,305.078613 
	C502.529510,305.078613 502.727692,305.192078 503.183258,305.129791 
	C504.265167,304.737671 504.891510,304.407806 505.517853,304.077942 
	C505.936737,304.003448 506.355591,303.928955 507.289185,303.611328 
	C508.384064,302.936554 508.964264,302.504913 509.544434,302.073242 
	C509.544434,302.073242 509.682983,302.267548 510.146729,302.246399 
	C511.433136,301.910126 512.255798,301.594971 513.476440,301.274963 
	C518.581360,300.199188 523.288269,299.128326 528.004150,298.303925 
	C528.013184,298.550415 528.016846,299.043701 527.776367,299.281677 
	C526.027222,300.019440 524.518494,300.519226 522.631958,301.048767 
	C508.223877,304.533691 495.639435,311.355438 483.083588,318.160889 
	C480.052185,319.803894 477.608582,322.531342 474.545898,324.832764 
	C467.627869,330.100616 460.844452,335.049835 454.535065,340.543518 
	C442.227539,351.259857 433.130249,364.597595 425.246246,378.702698 
	C422.068634,384.387665 420.187897,390.797455 417.463440,397.116730 
	C416.410400,399.147522 415.465302,400.889313 414.834625,402.738373 
	C410.284576,416.077881 407.770355,429.753937 406.598236,443.850037 
	C405.405884,458.190125 406.551331,472.143707 409.849335,486.406738 
	C410.247833,488.185272 410.600983,489.598633 410.943939,491.395691 
	C411.234100,493.209503 411.534454,494.639618 411.856140,496.457825 
	C413.511108,501.271179 415.144775,505.696503 416.824585,510.493713 
	C420.019470,517.910583 422.743927,525.188416 426.444244,531.930725 
	C429.709442,537.880249 434.063660,543.232056 437.760254,548.973145 
	C437.711304,549.427063 437.839172,549.755615 438.104004,550.367249 
	C439.436554,551.785889 440.632080,552.921570 441.987152,554.343384 
	C443.435669,556.089417 444.724701,557.549255 446.168396,559.305786 
	C447.459442,560.957581 448.476807,562.439087 449.756042,563.642151 
	C452.770294,566.476868 455.909393,569.178772 459.295898,572.098755 
	C465.372620,576.925232 471.151428,581.587280 477.097656,586.530334 
	C484.546783,591.064636 491.522186,596.001099 499.173004,599.430847 
	C515.715027,606.846252 532.897400,611.821228 551.234131,613.353821 
	C569.548767,614.884583 587.313599,613.102478 605.412720,608.952881 
	C622.450439,604.640259 637.980591,597.823486 652.348999,588.337769 
	C653.619263,587.499207 655.383972,587.409668 656.919006,586.972229 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M443.209106,348.302917 
	C442.245514,347.419739 440.314056,346.059174 440.499115,345.645569 
	C441.137970,344.217590 442.514893,343.119812 443.610779,341.896332 
	C443.979706,342.094269 444.348602,342.292206 444.717499,342.490112 
	C444.542816,343.818237 444.368134,345.146362 444.262207,346.842285 
	C444.330933,347.210052 444.250549,347.206482 443.953613,347.240143 
	C443.491516,347.578918 443.326385,347.884003 443.161255,348.189117 
	C443.161255,348.189117 443.211487,348.259674 443.209106,348.302917 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M472.742249,288.357117 
	C472.994781,288.372101 473.132324,288.506775 473.269867,288.641418 
	C473.055664,288.586548 472.841461,288.531647 472.742249,288.357117 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M537.551636,633.466858 
	C537.585388,633.522827 537.517822,633.410889 537.551636,633.466858 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M292.436340,742.037354 
	C289.942749,743.532715 287.577667,744.620117 284.503174,746.033630 
	C284.503174,737.538025 284.503174,730.433411 284.503174,723.362183 
	C290.845673,721.887085 292.768433,723.139038 292.924011,729.166382 
	C293.031067,733.314819 292.700012,737.474670 292.436340,742.037354 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M362.072845,744.353271 
	C360.079987,744.733765 358.467102,744.936890 356.444946,745.191528 
	C356.444946,737.611328 356.444946,730.430115 356.444946,723.262939 
	C362.767609,721.864502 364.915009,723.103394 364.725220,729.070374 
	C364.564240,734.130371 366.905182,739.721436 362.072845,744.353271 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M362.453857,692.589966 
	C366.313721,697.449219 365.224609,702.638489 363.785706,707.483398 
	C363.414276,708.734070 359.998810,709.284424 357.895233,709.629272 
	C357.515717,709.691467 356.258850,707.204407 356.191528,705.855225 
	C355.969727,701.408325 356.107971,696.943542 356.107971,691.771545 
	C358.396210,692.007324 360.257599,692.199158 362.453857,692.589966 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M615.144897,726.482056 
	C614.439026,727.959351 613.870483,729.700073 612.893799,729.971313 
	C611.086853,730.473267 609.041077,730.114868 606.348877,730.114868 
	C607.726318,720.278381 609.014954,711.075806 610.303528,701.873230 
	C610.626160,701.881409 610.948792,701.889587 611.271423,701.897766 
	C612.546997,709.959351 613.822510,718.020996 615.144897,726.482056 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M425.571686,719.496338 
	C426.444641,713.699402 427.254791,708.336670 428.064911,702.973877 
	C428.653168,702.945251 429.241425,702.916565 429.829681,702.887878 
	C430.979645,711.772156 432.129608,720.656372 433.321533,729.864929 
	C430.036713,729.864929 427.459381,729.864929 424.259277,729.864929 
	C424.698883,726.369751 425.103882,723.150146 425.571686,719.496338 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M218.524445,700.882935 
	C218.846100,699.742065 219.065704,698.967407 219.599564,697.084229 
	C220.926483,708.590393 222.123093,718.966736 223.367981,729.761597 
	C220.374619,729.761597 217.820786,729.761597 214.304199,729.761597 
	C215.690979,720.160217 217.056686,710.704712 218.524445,700.882935 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M855.273193,692.174683 
	C862.337585,692.150269 862.975525,692.888977 862.975830,700.493652 
	C862.976135,708.703064 862.408875,709.412842 855.037476,709.742554 
	C855.037476,703.991394 855.037476,698.246826 855.273193,692.174683 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M707.007385,681.453247 
	C707.339111,683.386963 707.942627,685.790161 707.951111,688.195374 
	C708.026184,709.483093 707.995667,730.771240 707.992920,752.527222 
	C707.659729,751.268066 707.047791,749.541748 707.042786,747.813721 
	C706.978943,725.850464 707.002197,703.887024 707.007385,681.453247 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M689.477661,679.991211 
	C694.473450,679.801636 699.935486,679.617554 705.704468,679.711304 
	C700.655579,679.991638 695.299744,679.994202 689.477661,679.991211 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M483.839966,314.144653 
	C483.242767,314.604614 482.471954,314.682098 481.398010,314.971649 
	C481.086517,315.475616 481.078156,315.767487 481.069824,316.059357 
	C481.069824,316.059357 481.044342,316.037537 480.787537,316.142090 
	C480.279602,316.524963 480.028442,316.803223 479.777283,317.081512 
	C479.777283,317.081512 479.646606,317.162018 479.218414,317.106140 
	C478.209381,317.408417 477.628510,317.766571 477.047638,318.124756 
	C477.047638,318.124725 477.008179,318.061096 476.764099,318.213501 
	C476.366180,318.762817 476.212311,319.159698 476.058411,319.556610 
	C475.341980,320.177094 474.625549,320.797577 473.625061,321.469330 
	C472.555206,314.176208 475.683838,311.825378 483.839966,314.144653 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M458.097656,333.094055 
	C456.888672,334.660767 455.268311,336.199005 453.342773,337.850952 
	C452.944336,334.772827 454.413574,333.063568 458.097656,333.094055 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M452.597534,338.028778 
	C452.179657,338.946472 451.394379,339.856079 450.323975,340.872009 
	C450.769287,339.992462 451.499695,339.006592 452.597534,338.028778 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M464.120605,328.073151 
	C463.670593,328.835876 462.808777,329.588562 461.605225,330.313843 
	C462.078552,329.545349 462.893677,328.804230 464.120605,328.073151 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M460.861572,330.198975 
	C460.714508,330.843719 460.211334,331.447296 459.394867,332.123413 
	C459.556183,331.516541 460.030853,330.837158 460.861572,330.198975 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M467.176697,325.130798 
	C466.988708,325.830322 466.385803,326.531586 465.449707,327.367188 
	C465.664948,326.711853 466.213348,325.922180 467.176697,325.130798 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M468.872955,324.159973 
	C468.915894,324.357483 468.712616,324.626556 468.234009,324.943298 
	C468.181335,324.737793 468.404022,324.484680 468.872955,324.159973 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M470.270996,323.152649 
	C470.312958,323.436066 470.031281,323.778442 469.404449,324.122650 
	C469.355347,323.820190 469.651367,323.515900 470.270996,323.152649 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M407.752747,796.718018 
	C408.778473,800.725159 405.806824,800.657837 403.759216,800.738281 
	C397.969818,800.965576 392.165710,800.817749 385.876007,800.817749 
	C385.750244,799.074829 385.639435,797.539551 385.453491,794.962952 
	C393.198700,795.475342 400.364410,795.949341 407.752747,796.718018 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M407.695251,785.247803 
	C400.301514,786.020813 393.164459,786.532166 386.243988,787.028076 
	C384.708954,782.357666 385.817505,780.862915 389.813721,781.101562 
	C394.436157,781.377625 399.093567,781.037415 403.721985,781.256409 
	C405.853088,781.357239 408.823242,781.181274 407.695251,785.247803 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M745.132568,782.033325 
	C751.204285,781.533386 756.962952,781.100220 762.708435,781.233948 
	C763.964600,781.263245 765.173645,783.314880 766.404419,784.435181 
	C765.104126,785.271667 763.830139,786.778564 762.499023,786.830750 
	C756.063599,787.083191 749.612915,786.944580 742.034363,786.944580 
	C743.286682,784.854370 744.050720,783.579041 745.132568,782.033325 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M599.553955,784.826294 
	C597.909607,785.793335 596.457458,786.890625 594.970947,786.939392 
	C588.842834,787.140564 582.704285,787.023682 575.659912,785.877563 
	C576.887268,784.480225 578.026855,781.980530 579.356995,781.874695 
	C585.376526,781.395935 591.453064,781.488708 597.499634,781.677734 
	C598.271606,781.701965 598.988098,783.498901 599.553955,784.826294 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M527.995178,298.057434 
	C523.288269,299.128326 518.581360,300.199188 513.445801,300.957397 
	C513.008972,300.432709 513.000793,300.220734 512.992676,300.008789 
	C513.314026,299.918915 513.581604,299.745941 514.238159,299.256805 
	C515.117371,299.011353 515.553772,298.998932 516.327454,298.983795 
	C517.441528,298.654907 518.218323,298.328705 518.995178,298.002533 
	C535.196838,293.752991 551.551697,290.840393 568.274902,292.716339 
	C561.376709,293.446198 554.570129,293.429382 547.867920,294.257629 
	C541.189148,295.083008 534.615112,296.756073 527.995178,298.057434 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M496.770020,307.146362 
	C496.066254,307.971710 494.968323,308.719818 493.536530,309.348145 
	C494.260406,308.508636 495.318115,307.788879 496.770020,307.146362 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M512.627319,300.013489 
	C513.000793,300.220734 513.008972,300.432709 513.047791,300.962250 
	C512.255798,301.594971 511.433136,301.910126 510.258667,302.205078 
	C510.691864,301.462646 511.476898,300.740387 512.627319,300.013489 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M492.750885,309.113220 
	C492.317291,309.799133 491.515472,310.428253 490.371704,311.076538 
	C490.814026,310.415924 491.598328,309.736145 492.750885,309.113220 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M515.990234,298.986481 
	C515.553772,298.998932 515.117371,299.011353 514.354492,299.044769 
	C514.571228,298.560547 515.114319,298.055359 515.827148,298.024048 
	C515.996826,298.497894 515.990234,298.986481 515.990234,298.986481 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M516.327454,298.983795 
	C515.990234,298.986481 515.996826,298.497894 516.002075,298.253723 
	C516.452148,298.006439 516.897034,298.003357 517.675659,297.993500 
	C518.009521,297.986725 518.502441,297.992706 518.748779,297.997620 
	C518.218323,298.328705 517.441528,298.654907 516.327454,298.983795 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M499.750366,306.070068 
	C499.340729,306.558990 498.572388,307.016724 497.462524,307.364716 
	C497.877869,306.849609 498.634766,306.444275 499.750366,306.070068 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M502.159363,305.075470 
	C501.942841,305.454468 501.356140,305.830292 500.404358,306.177246 
	C500.622589,305.789642 501.205933,305.430969 502.159363,305.075470 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M505.146606,304.073425 
	C504.891510,304.407806 504.265167,304.737671 503.266663,305.051849 
	C503.521484,304.713776 504.148438,304.391327 505.146606,304.073425 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M509.141846,302.080170 
	C508.964264,302.504913 508.384064,302.936554 507.432434,303.443420 
	C507.620392,303.041412 508.179840,302.564240 509.141846,302.080170 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M520.465088,277.448273 
	C520.521851,277.414185 520.408386,277.482361 520.465088,277.448273 
z"
          />
          <path
            fill="#FCFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M353.047485,799.127441 
	C349.120514,799.112732 345.611664,799.488281 343.239929,795.937988 
	C339.486694,790.319702 341.833984,783.708801 348.442810,782.629272 
	C351.796051,782.081482 355.403839,782.209412 358.763794,782.794495 
	C363.696075,783.653381 365.920563,787.043640 365.379944,791.681885 
	C364.874725,796.016479 361.611420,798.909668 356.989960,799.093140 
	C355.829590,799.139221 354.666443,799.115601 353.047485,799.127441 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M595.005737,614.177368 
	C595.108704,613.764648 595.356384,613.726135 595.904419,613.842773 
	C597.971191,613.995361 599.489990,614.031372 601.008728,614.067383 
	C605.225464,615.486267 609.442261,616.905151 613.737305,618.350403 
	C612.080383,620.666443 610.750122,622.525818 609.178101,624.640503 
	C608.212219,624.930725 607.488037,624.965576 606.099976,625.017883 
	C604.961060,625.346619 604.485962,625.657959 604.010864,625.969299 
	C603.231812,626.298279 602.452759,626.627258 601.357544,626.985413 
	C593.329224,628.444092 585.617188,629.873535 577.535767,631.371399 
	C576.523560,626.757507 572.738586,626.869263 568.745239,626.954529 
	C562.877197,627.079895 562.550781,626.553894 564.067871,621.768311 
	C557.932983,620.789734 551.737366,619.801514 545.541748,618.813293 
	C545.541870,618.318665 545.541992,617.824036 545.542114,617.329346 
	C547.185547,617.246826 548.828125,617.133972 550.472534,617.086487 
	C564.818909,616.672363 579.166077,616.281799 593.510620,615.811951 
	C593.987549,615.796387 594.439331,615.014587 595.005737,614.177368 
z"
          />
          <path
            fill="#FCFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M440.438751,782.121948 
	C448.274597,784.326538 451.237946,788.793701 448.134857,794.224976 
	C446.883148,796.415833 443.808929,798.732056 441.440399,798.870300 
	C436.805450,799.140686 431.246796,801.085083 427.845123,795.605896 
	C424.211487,789.752991 427.258514,783.115479 434.061249,782.189087 
	C436.016479,781.922913 438.032257,782.100830 440.438751,782.121948 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M528.004150,298.303955 
	C534.615112,296.756073 541.189148,295.083008 547.867920,294.257629 
	C554.570129,293.429382 561.376709,293.446198 568.599121,292.982178 
	C570.736755,292.958527 572.412781,293.045319 574.903564,293.180359 
	C584.875061,294.528351 594.031921,295.828125 603.188721,297.127869 
	C603.188721,297.127869 603.401733,297.249237 603.796143,297.436279 
	C605.498352,297.760040 606.806213,297.896759 608.114075,298.033508 
	C608.114075,298.033508 608.319153,298.227722 608.702759,298.457672 
	C613.108337,299.871277 617.130371,301.054901 621.152344,302.238556 
	C621.458313,302.335419 621.764282,302.432281 622.409424,302.681946 
	C623.481995,303.084717 624.215454,303.334686 624.948914,303.584625 
	C624.948914,303.584625 624.864319,303.783478 625.057983,304.143066 
	C625.832031,304.610474 626.412476,304.718353 626.992859,304.826202 
	C634.542175,308.850708 642.091553,312.875244 650.024109,316.940674 
	C650.754883,317.313232 651.102356,317.644867 651.816528,318.294312 
	C652.795959,319.365509 653.408752,320.118866 653.848389,321.135071 
	C648.946167,318.685303 644.318176,315.775177 639.469666,313.296478 
	C624.133362,305.456085 607.790039,300.538727 590.574280,297.003174 
	C588.776428,296.975281 587.392761,296.966858 585.607056,296.941040 
	C582.803589,296.603912 580.402222,296.284180 577.547668,295.950592 
	C572.001892,295.685242 566.864014,294.880829 561.826111,295.294739 
	C550.529968,296.222839 539.283508,297.756561 528.016846,299.043701 
	C528.016846,299.043701 528.013184,298.550415 528.004150,298.303955 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M602.941895,296.828064 
	C594.031921,295.828125 584.875061,294.528351 575.317078,293.093079 
	C576.664185,292.339325 578.412415,291.721069 580.523315,291.125244 
	C583.312927,291.777832 585.739868,292.407990 588.812500,293.295349 
	C590.632629,293.677765 591.807068,293.802979 592.981506,293.928192 
	C596.219360,294.794891 599.457153,295.661591 602.941895,296.828064 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M620.974243,301.895447 
	C617.130371,301.054901 613.108337,299.871277 608.824707,298.386414 
	C612.640747,299.240936 616.718384,300.396667 620.974243,301.895447 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M607.839478,297.744812 
	C606.806213,297.896759 605.498352,297.760040 603.885864,297.352722 
	C604.909058,297.206848 606.236938,297.331482 607.839478,297.744812 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M592.743286,293.666229 
	C591.807068,293.802979 590.632629,293.677765 589.209229,293.298584 
	C590.141846,293.164459 591.323425,293.284363 592.743286,293.666229 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M624.799011,303.201630 
	C624.215454,303.334686 623.481995,303.084717 622.568970,302.482605 
	C623.142578,302.359833 623.895874,302.589233 624.799011,303.201630 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M626.809448,304.614441 
	C626.412476,304.718353 625.832031,304.610474 625.141235,304.211914 
	C625.562622,304.081665 626.094299,304.242157 626.809448,304.614441 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M592.445801,289.534271 
	C592.410339,289.476227 592.481323,289.592316 592.445801,289.534271 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M458.302399,792.781494 
	C458.348663,793.083374 458.093689,793.660400 457.649231,793.952271 
	C457.640228,793.463623 457.820709,793.260132 458.302399,792.781494 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M601.375366,614.009888 
	C599.489990,614.031372 597.971191,613.995361 595.984863,613.938843 
	C600.406860,612.655762 605.296326,611.393188 610.587158,610.147522 
	C610.202332,610.875366 609.528809,611.918640 608.607422,612.231140 
	C606.380493,612.986450 604.037964,613.400574 601.375366,614.009888 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M650.533936,596.391846 
	C650.651489,595.938599 650.939087,595.663391 651.226746,595.388123 
	C651.340210,595.507263 651.559021,595.723877 651.552002,595.731445 
	C651.282166,596.022766 650.991760,596.294983 650.533936,596.391846 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M653.465576,593.446167 
	C653.524292,593.410645 653.406921,593.481689 653.465576,593.446167 
z"
          />
          <path
            fill="#FCFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M709.027466,387.047913 
	C712.399841,395.672638 715.896851,404.251129 719.117371,412.932190 
	C724.921082,428.576294 726.810547,444.891663 725.830322,461.402313 
	C724.230164,488.353180 715.541809,513.007324 700.023499,535.601318 
	C699.920105,535.967957 699.922485,536.112976 699.756714,536.154480 
	C699.391785,536.359192 699.330017,536.563965 699.176819,537.001343 
	C698.861328,537.371277 698.774658,537.550110 698.415405,537.871216 
	C697.631653,538.902100 697.120544,539.790710 696.298401,540.790039 
	C695.149841,542.158386 694.312317,543.416016 693.195984,544.807556 
	C691.886475,546.232239 690.855713,547.523010 689.504272,548.898499 
	C687.105042,551.277527 685.026428,553.571838 682.683167,555.990112 
	C682.289734,556.260437 682.161011,556.406677 681.720825,556.556091 
	C676.620728,560.654175 671.832153,564.749146 666.715149,568.923218 
	C665.941467,569.469604 665.496216,569.936890 664.740356,570.384399 
	C663.467468,571.088196 662.505310,571.811584 661.223328,572.608765 
	C659.541565,573.680115 658.179626,574.677551 656.508667,575.696655 
	C655.731201,576.082336 655.262817,576.446350 654.542603,576.845215 
	C654.151489,576.976624 654.012207,577.073181 653.638367,577.140015 
	C653.053101,577.176025 652.834778,577.382141 652.428528,577.796692 
	C651.359436,578.310486 650.610718,578.756226 649.626099,579.143799 
	C649.075745,579.399719 648.761230,579.713928 648.101074,580.065186 
	C646.765808,580.676208 645.776184,581.250244 644.415161,581.821045 
	C636.429382,584.593018 628.815063,587.368225 620.791138,590.113281 
	C618.528015,590.589539 616.674500,591.095947 614.498413,591.491455 
	C612.457031,591.887024 610.738220,592.393555 608.565796,592.902893 
	C589.141479,595.068909 570.288940,594.870605 551.689270,589.978027 
	C545.467407,588.341431 539.278076,586.581421 533.051270,584.924072 
	C533.028687,584.970337 533.118164,584.919312 533.032349,584.582520 
	C528.295715,582.064636 523.644836,579.883606 518.903442,577.674072 
	C518.812988,577.645630 518.685181,577.785889 518.528320,577.458496 
	C513.916809,574.424805 509.462067,571.718628 505.005493,569.003784 
	C505.003601,568.995117 504.986267,568.992798 504.891907,568.669678 
	C502.924652,567.146729 501.051727,565.946960 499.159454,564.799377 
	C499.140076,564.851624 499.246185,564.817566 499.191956,564.502197 
	C498.429810,563.778931 497.721924,563.370972 496.984985,562.624573 
	C496.778809,562.010437 496.655487,561.671692 496.416931,561.467896 
	C466.363098,535.800720 449.522980,503.304626 446.809875,463.861786 
	C445.174957,440.093475 448.928345,417.082642 459.573822,395.532806 
	C463.314270,387.960938 467.607178,380.661957 471.951935,373.170044 
	C473.557007,371.322510 474.858002,369.543243 476.411804,367.757660 
	C476.873932,367.194946 477.083252,366.638550 477.585938,365.901489 
	C478.213104,365.145752 478.546936,364.570618 479.152832,363.867371 
	C479.679504,363.433044 479.934082,363.126923 480.476501,362.776550 
	C481.649719,361.526703 482.535156,360.321136 483.743347,358.927673 
	C485.364441,357.303619 486.662750,355.867462 488.271301,354.419312 
	C491.067322,352.024872 493.553101,349.642395 496.120911,347.241699 
	C496.202942,347.223480 496.260681,347.065643 496.575745,346.992798 
	C500.964539,343.660461 505.038239,340.400970 509.069092,337.122894 
	C509.026276,337.104340 509.108765,337.124268 509.390198,337.108917 
	C509.948273,336.852966 510.224945,336.612396 510.721375,336.366760 
	C510.941132,336.361694 511.253448,336.052277 511.478943,336.041809 
	C512.014282,335.909271 512.198181,335.684143 512.263733,335.313232 
	C512.271301,335.270569 512.339111,335.324677 512.656006,335.264008 
	C513.947876,334.513336 514.922791,333.823303 516.225342,333.053101 
	C516.723999,332.826996 516.895081,332.681061 517.403198,332.585205 
	C525.879883,328.820892 534.019653,325.006470 542.490723,321.208069 
	C543.248291,321.108704 543.674438,320.993347 544.495728,320.892151 
	C545.705505,320.736481 546.530762,320.605682 547.333618,320.391235 
	C597.389221,307.020447 641.771240,317.848267 680.668274,351.775085 
	C691.675415,361.375671 699.895691,373.303864 707.835327,385.338562 
	C707.773376,385.301117 707.910095,385.321350 708.012939,385.659485 
	C708.419617,386.347717 708.723572,386.697815 709.027466,387.047913 
M516.347473,509.906097 
	C516.197266,510.268433 516.047058,510.630768 515.733337,511.338165 
	C515.733337,511.338165 515.879639,511.690887 515.703247,512.506042 
	C516.142761,515.945984 516.582336,519.385864 517.837402,522.982300 
	C519.535583,522.369812 521.233765,521.757385 523.759949,521.203125 
	C525.453979,521.515930 527.148010,521.828674 528.842102,522.141418 
	C528.922241,522.073059 529.002441,522.004761 529.082581,521.936401 
	C533.384827,522.349976 537.687012,522.763550 541.989258,523.177124 
	C542.187378,522.595520 542.385498,522.013855 542.583618,521.432251 
	C541.186462,520.517761 539.789307,519.603333 538.247192,518.593933 
	C538.006104,515.796692 537.741699,512.729126 537.477234,509.661530 
	C536.836304,509.668365 536.195374,509.675201 535.554443,509.682007 
	C535.554443,513.695740 535.554443,517.709473 535.554443,522.315674 
	C530.473145,518.942322 535.424927,510.394958 527.277771,509.517242 
	C526.450134,513.190063 525.855774,516.285095 524.995300,519.304382 
	C524.795837,520.004272 523.688232,520.445435 523.354309,520.372070 
	C523.282104,519.659546 523.209900,518.946960 523.329163,517.987610 
	C523.366638,517.618713 523.225220,517.384094 522.871826,516.499329 
	C522.836243,515.977600 522.800659,515.455811 523.223145,514.500000 
	C522.778870,513.091309 522.334656,511.682648 521.800659,510.076599 
	C521.800659,510.076599 521.600342,510.159637 521.128845,509.817322 
	C519.745117,509.872528 518.361389,509.927734 516.992615,510.001221 
	C516.992615,510.001221 516.991028,509.982208 516.347473,509.906097 
M548.079285,516.873474 
	C548.079285,516.873474 548.277161,516.721558 548.068848,517.423218 
	C548.031128,517.908264 547.993408,518.393372 547.805603,519.595764 
	C547.730164,520.078491 547.654663,520.561279 547.244019,521.501526 
	C547.683350,521.924316 548.122681,522.347107 549.303833,522.865662 
	C551.042480,522.841431 552.781067,522.817200 555.427185,522.908997 
	C556.036438,522.901184 556.645630,522.893372 558.065552,523.019714 
	C559.224182,523.022217 560.437744,523.240784 561.525635,522.964661 
	C562.553101,522.703796 563.448486,521.922729 564.401978,521.370483 
	C563.416443,520.846191 562.432068,520.319885 561.444946,519.798584 
	C560.964600,519.544922 560.479248,519.300720 559.398010,518.744507 
	C561.494934,517.790710 562.958862,517.124878 564.422791,516.458984 
	C564.324097,516.091736 564.225464,515.724426 564.126770,515.357178 
	C562.762512,514.954956 561.398254,514.552795 559.643127,514.035400 
	C561.621826,512.873291 563.098145,512.006226 564.574463,511.139160 
	C564.351929,510.630432 564.129395,510.121674 563.906921,509.612946 
	C561.649109,509.761505 559.391357,509.910065 556.321777,510.040619 
	C555.781555,510.071320 555.241394,510.102020 554.046692,510.061218 
	C552.146484,510.084076 550.246216,510.106934 547.744263,510.263947 
	C547.682007,511.160461 547.619751,512.056946 547.703735,513.641296 
	C547.663574,514.106689 547.623474,514.572083 547.237976,515.442993 
	C547.593872,515.885010 547.949829,516.326965 548.079285,516.873474 
M637.475891,509.691406 
	C636.682739,510.726044 635.446960,511.660156 635.184631,512.815369 
	C634.557068,515.578674 634.387756,518.445984 633.878296,520.503418 
	C632.947998,520.271667 632.017761,520.039856 630.822388,519.521667 
	C630.582642,519.422424 630.348694,519.311035 630.041870,518.279968 
	C629.986633,515.385925 629.931396,512.491821 629.876221,509.597778 
	C629.178894,509.600830 628.481567,509.603851 627.784241,509.606873 
	C627.784241,513.961853 627.784241,518.316772 627.784241,523.455750 
	C629.899475,522.923340 631.962219,522.404175 634.635010,522.239868 
	C635.730896,522.483459 636.826721,522.727051 638.624878,523.065002 
	C639.776489,522.634583 640.928040,522.204102 642.851379,521.771118 
	C645.784912,523.632080 647.538940,523.147827 647.878174,519.388123 
	C647.952576,518.563965 648.603455,517.791748 648.911804,517.864868 
	C649.125671,519.561157 649.339539,521.257385 649.553406,522.953674 
	C650.151855,522.902161 650.750244,522.850647 651.348633,522.799133 
	C651.348633,519.461548 651.475159,516.116333 651.273071,512.791077 
	C651.210876,511.767792 650.251160,510.799011 649.702393,509.805267 
	C648.850952,510.873566 647.999512,511.941864 646.709656,513.653748 
	C646.117310,513.782104 645.253540,514.224426 644.978088,513.986206 
	C643.514526,512.720825 642.198547,511.284821 639.911255,509.906158 
	C639.302612,509.928497 638.693970,509.950836 637.475891,509.691406 
M496.921997,519.497498 
	C496.971802,519.665466 497.021606,519.833435 496.996094,520.851257 
	C498.746613,521.553772 500.461731,522.393372 502.272919,522.861084 
	C502.718414,522.976074 503.477051,521.878296 504.091736,521.338013 
	C503.407806,520.846252 502.723877,520.354492 501.546478,519.616577 
	C501.029572,519.438232 500.512695,519.259827 500.028717,518.188965 
	C500.051544,516.101624 500.195190,514.004822 500.032715,511.932007 
	C499.974518,511.189697 499.176941,510.505341 498.716858,509.794556 
	C498.081116,510.523407 497.445374,511.252289 496.268616,511.996460 
	C495.907928,511.989410 495.547241,511.982330 494.881104,511.351959 
	C492.988129,510.896698 491.095184,510.441437 489.202209,509.986176 
	C488.503754,513.038574 487.438080,516.072693 487.284698,519.152283 
	C487.221985,520.411194 489.010773,522.694580 490.177612,522.849243 
	C491.674377,523.047729 493.800598,521.932312 494.891571,520.704224 
	C495.814178,519.665649 495.648590,517.660400 496.041351,515.510376 
	C496.296631,515.021423 496.551910,514.532410 496.938385,514.861450 
	C496.984833,516.239502 497.031281,517.617493 496.921997,519.497498 
M604.350647,509.165802 
	C602.124146,509.830109 599.897644,510.494415 597.195923,510.716278 
	C593.911682,508.132477 591.406189,509.938660 588.352661,511.819763 
	C587.951233,511.175049 587.549805,510.530365 587.148376,509.885651 
	C586.622864,510.350647 585.659912,510.799530 585.642090,511.283264 
	C585.516663,514.691711 585.585571,518.107239 585.592407,521.520569 
	C586.421448,521.390930 587.250427,521.261353 588.780823,521.030212 
	C591.812988,522.283325 594.864746,525.321411 598.364136,521.257202 
	C598.930298,521.831299 599.496399,522.405396 600.910156,522.981750 
	C602.606262,522.987732 604.302368,522.993774 606.804749,523.053589 
	C607.610840,523.002197 608.416992,522.950806 610.067078,522.962585 
	C620.908020,524.372986 613.872192,516.416687 615.251221,512.896484 
	C615.554260,512.123047 615.090149,510.217194 614.794006,510.176331 
	C612.912903,509.916840 610.979797,510.034271 608.427246,509.997650 
	C607.790405,509.981750 607.153564,509.965851 605.733765,509.784851 
	C605.475891,509.716614 605.218079,509.648376 604.350647,509.165802 
M623.683899,470.163696 
	C623.567688,460.410553 623.451477,450.657410 623.567871,440.016266 
	C623.471008,435.998352 623.374146,431.980408 623.569397,427.076416 
	C623.558655,422.020203 623.547913,416.963959 623.791809,411.273102 
	C623.667542,410.394562 623.543213,409.516052 622.841614,407.926880 
	C613.323242,407.849945 603.804321,407.706879 594.287537,407.797974 
	C593.331299,407.807129 592.388550,409.234802 591.190552,410.736542 
	C590.768860,415.486816 590.347168,420.237122 589.476318,425.662689 
	C589.299255,428.113312 589.122131,430.563965 588.541321,433.677368 
	C588.433105,435.480682 588.324829,437.283966 587.770752,439.692566 
	C587.449768,441.895630 587.128784,444.098694 586.807800,446.301758 
	C586.588440,446.336334 586.369080,446.370880 586.149719,446.405457 
	C585.678467,444.586487 585.207214,442.767517 584.768433,440.233185 
	C584.618958,439.787933 584.469543,439.342682 584.546936,438.438110 
	C584.348511,437.980804 584.150085,437.523499 584.002808,436.219849 
	C583.274658,431.159515 582.546509,426.099152 581.936951,420.221039 
	C581.747437,419.155243 581.557922,418.089447 581.524109,416.411194 
	C581.048828,415.579346 580.573608,414.747467 580.193298,413.257568 
	C580.099365,412.828827 580.005432,412.400085 580.039856,411.075928 
	C579.303955,409.953674 578.590942,407.873108 577.828735,407.854950 
	C568.458496,407.631256 559.081055,407.709167 549.505127,407.709167 
	C549.505127,411.820709 549.505127,415.398560 549.208069,419.795929 
	C549.298096,421.526672 549.388123,423.257385 549.203796,425.885223 
	C549.296326,430.904755 549.388855,435.924255 549.208618,441.850739 
	C549.290710,450.911407 549.372742,459.972107 549.241821,469.934937 
	C549.313721,473.963013 549.385681,477.991089 549.240967,482.824036 
	C549.295837,483.926544 549.350647,485.029053 548.596436,486.371796 
	C548.078674,486.214996 547.560974,486.058197 547.125305,485.168884 
	C547.128113,484.441101 547.130981,483.713318 547.356628,482.146790 
	C546.841797,479.113098 546.327026,476.079407 545.895752,472.206757 
	C544.262085,461.803528 542.628357,451.400269 541.095337,440.126007 
	C540.759888,438.424957 540.424377,436.723938 540.328186,434.525452 
	C540.172913,434.021545 540.017578,433.517639 539.937561,432.489227 
	C539.881714,432.321655 539.825806,432.154053 539.921143,431.492279 
	C539.840515,431.340057 539.759827,431.187836 539.726379,430.229095 
	C538.599121,422.871307 537.471863,415.513519 536.273499,407.691620 
	C525.657410,407.691620 515.194153,407.837616 504.739227,407.606384 
	C501.421844,407.532990 500.532410,408.728363 500.151062,411.781342 
	C496.892883,437.866119 493.472961,463.930664 490.127380,490.004578 
	C489.673431,493.542572 489.363007,497.099030 488.986023,500.647827 
	C497.653290,500.647827 505.715149,500.647827 513.810791,500.647827 
	C514.486694,495.240448 515.119202,490.180084 515.757690,485.072357 
	C518.777832,485.072357 521.343506,485.072357 524.070068,485.072357 
	C524.445679,490.508545 524.795654,495.573517 525.152100,500.731567 
	C540.046570,500.731567 554.454895,500.731567 569.064453,500.731567 
	C568.996643,499.312073 568.941956,498.166748 569.064331,496.284393 
	C569.005981,495.533752 568.947632,494.783081 569.065125,493.244171 
	C569.006653,492.173309 568.948181,491.102417 569.061829,489.144714 
	C569.098938,486.449524 569.136047,483.754364 569.877380,480.647614 
	C569.822998,476.424255 569.768555,472.200867 569.958618,467.072479 
	C569.958618,460.983154 569.958618,454.893860 569.958618,448.804535 
	C570.255432,448.768311 570.552185,448.732117 570.848999,448.695892 
	C572.008545,455.107788 573.168152,461.519714 574.209167,468.689270 
	C574.473450,470.127686 574.737732,471.566101 574.902405,473.891907 
	C575.185852,475.645142 575.469299,477.398407 575.480530,479.487701 
	C575.415527,479.813293 575.524658,480.062805 575.767944,480.679535 
	C575.767944,480.679535 576.074097,481.002563 575.977844,481.849792 
	C576.417480,484.910858 576.857117,487.971924 577.225281,491.843536 
	C577.695618,494.754852 578.166016,497.666168 578.647888,500.649109 
	C583.947815,500.649109 588.969727,500.649109 594.893188,500.649109 
	C594.893188,495.874237 594.893188,491.458954 595.113220,486.202209 
	C595.430054,482.146545 595.746887,478.090912 596.525940,473.393799 
	C597.254272,469.612183 597.982544,465.830536 599.099731,461.333588 
	C599.732971,457.423462 600.366211,453.513336 600.999512,449.603210 
	C601.364502,449.647156 601.729553,449.691101 602.094604,449.735016 
	C602.094604,458.151276 602.094604,466.567505 601.916443,475.828491 
	C602.309082,478.563446 602.701721,481.298431 602.918335,484.830109 
	C602.870728,486.193085 602.823120,487.556030 602.050293,489.394562 
	C602.050293,493.110474 602.050293,496.826416 602.050293,500.572571 
	C609.385742,500.572571 616.214111,500.572571 623.573914,500.372467 
	C623.504761,499.635101 623.435547,498.897705 623.656250,497.245758 
	C623.532654,491.845551 623.409058,486.445312 623.576233,480.173615 
	C623.504272,477.133881 623.432251,474.094147 623.683899,470.163696 
M656.807434,511.689972 
	C657.863220,513.252991 659.347412,514.687317 659.865295,516.411926 
	C660.498413,518.520325 660.321289,520.872009 660.551819,523.861572 
	C665.468811,520.411133 659.810547,513.917114 666.457642,511.842773 
	C666.457642,515.687500 666.457642,519.125427 666.457642,522.805664 
	C669.205688,522.805664 671.460266,522.805664 673.714844,522.805664 
	C673.927185,522.386902 674.139465,521.968201 674.351807,521.549438 
	C672.847168,520.572876 671.342529,519.596313 669.664551,518.507263 
	C671.161621,517.770508 672.556030,517.084290 674.742126,516.008423 
	C672.376465,514.967773 671.020447,514.371277 669.868408,513.864563 
	C671.315857,512.775940 672.569519,511.833069 673.823181,510.890167 
	C672.197449,510.480347 670.566650,509.707855 668.947021,509.730835 
	C664.613037,509.792267 660.283752,510.194824 655.195618,509.910736 
	C653.045593,512.756775 653.016174,519.774597 656.042175,524.154297 
	C656.042175,519.267761 656.042175,515.652161 656.807434,511.689972 
M627.562622,473.939972 
	C627.648926,476.432373 627.675171,478.928528 627.832336,481.416473 
	C628.379578,490.078491 632.427612,496.788300 640.243835,500.384247 
	C649.481262,504.634033 658.366272,504.062897 665.065552,496.248535 
	C666.842957,497.916840 668.327820,500.509369 670.180908,500.806702 
	C674.370850,501.478882 678.742432,501.018005 683.352417,501.018005 
	C683.352417,484.207458 683.352417,467.820374 683.352417,451.155090 
	C673.958313,451.155090 664.743835,451.155090 655.253784,451.155090 
	C655.253784,455.897949 655.253784,460.472717 655.253784,465.109894 
	C656.634033,465.516937 657.850647,465.875732 659.206055,466.275452 
	C659.206055,471.297516 659.589539,476.151398 659.034363,480.895416 
	C658.780151,483.067719 656.906311,486.548340 655.371155,486.776947 
	C651.584351,487.340820 651.787292,483.517609 651.772400,481.050049 
	C651.663879,463.075317 651.646851,445.098358 651.885681,427.125885 
	C651.910095,425.285950 653.809204,423.470947 654.838379,421.644379 
	C656.175598,423.395050 658.389221,425.013031 658.678284,426.922394 
	C659.413879,431.781189 659.288940,436.770294 659.518188,441.927155 
	C667.625854,441.927155 675.365234,441.927155 684.175232,441.927155 
	C683.430664,435.522614 683.189026,429.386475 681.912659,423.473694 
	C680.248352,415.763855 675.170593,410.360962 667.589478,408.302795 
	C662.226013,406.846649 656.493286,406.062225 650.940186,406.133423 
	C638.677002,406.290588 625.194824,417.310181 627.453247,432.500031 
	C627.543884,446.008087 627.634460,459.516144 627.562622,473.939972 
M566.232239,511.257202 
	C566.232239,515.185303 566.232239,519.113464 566.232239,523.041565 
	C566.817688,523.060547 567.403137,523.079468 567.988586,523.098389 
	C568.641418,521.864380 569.294250,520.630432 570.053955,519.194458 
	C571.066101,520.821533 571.822754,522.038025 572.579468,523.254517 
	C572.962097,523.127075 573.344727,522.999634 573.727356,522.872131 
	C573.908569,520.318604 574.089783,517.765137 574.270935,515.211609 
	C574.726074,515.214172 575.181213,515.216797 575.636353,515.219360 
	C576.091736,517.249695 576.412048,519.325562 577.073914,521.286194 
	C577.314575,521.999084 578.323120,522.806274 579.066040,522.900208 
	C579.868591,523.001648 581.398560,522.475891 581.528748,521.953857 
	C582.519043,517.981934 583.244202,513.943970 584.051880,509.926575 
	C583.403870,509.763397 582.755798,509.600220 582.107727,509.437012 
	C581.279785,511.587891 580.451843,513.738770 579.337769,516.632935 
	C578.589111,513.828125 578.052551,511.817810 577.539001,509.893616 
	C573.616577,510.091431 570.044983,510.271576 566.232239,511.257202 
M505.115906,519.068604 
	C505.516907,522.453430 507.643005,523.779053 510.687256,522.955688 
	C515.154541,521.747437 513.905151,517.833923 513.943054,514.718872 
	C513.974731,512.119995 513.500977,509.673340 510.027924,509.511536 
	C507.300385,509.384430 505.442474,510.279297 505.198456,513.226929 
	C505.061676,514.879395 505.119263,516.548035 505.115906,519.068604 
M683.862549,511.919800 
	C681.430664,510.964905 678.998779,510.010010 676.217468,508.917908 
	C676.217468,514.484619 676.217468,518.667175 676.217468,522.826050 
	C683.622192,523.092590 684.408569,522.354065 684.237427,515.604431 
	C684.212463,514.619141 684.100220,513.636169 683.862549,511.919800 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M709.376465,387.036438 
	C708.723572,386.697815 708.419617,386.347717 708.004395,385.683075 
	C707.893066,385.368530 707.897217,385.376038 707.897217,385.376038 
	C699.895691,373.303864 691.675415,361.375671 680.668274,351.775085 
	C641.771240,317.848267 597.389221,307.020447 547.333618,320.391235 
	C546.530762,320.605682 545.705505,320.736481 544.582153,320.650757 
	C544.546692,319.959045 544.819824,319.522888 545.472168,319.058228 
	C555.908569,317.019012 565.965759,315.008270 576.489136,313.008484 
	C579.103882,313.018799 581.259338,313.131042 583.399780,312.999084 
	C596.498657,312.191467 609.272644,314.405609 622.341919,317.207092 
	C648.296570,324.191956 669.961487,337.943268 689.660339,357.064819 
	C689.806030,354.720276 689.855164,353.929718 690.202332,353.080505 
	C690.696350,352.972717 690.892395,352.923584 691.088379,352.874451 
	C691.088379,352.874451 691.358521,353.100189 691.554810,353.515076 
	C692.437073,354.669067 693.123108,355.408142 693.809143,356.147247 
	C693.809143,356.147247 693.885376,356.049255 693.923889,356.349518 
	C694.127625,356.955780 694.375732,357.162018 694.706787,357.268494 
	C696.145081,359.261597 697.583374,361.254669 699.143677,363.810211 
	C699.527039,364.557007 699.788330,364.741302 700.049622,364.925629 
	C700.049622,364.925629 700.036072,364.958618 700.056641,364.953247 
	C702.919495,370.103333 705.811218,375.232483 708.577576,380.428314 
	C709.574280,382.300354 710.269958,384.332642 711.114380,386.676819 
	C710.659058,387.049530 710.192200,387.037262 709.376465,387.036438 
z"
          />
          <path
            fill="#FD8B03"
            opacity="1.000000"
            stroke="none"
            d="
M698.688110,537.729004 
	C698.774658,537.550110 698.861328,537.371277 699.323242,536.945312 
	C699.872498,536.541382 699.947144,536.346252 699.922485,536.112976 
	C699.922485,536.112976 699.920105,535.967957 699.988037,535.940002 
	C700.738342,536.457764 701.420715,537.003479 702.260559,537.869385 
	C702.278503,538.572449 702.139038,538.955322 701.588501,539.666077 
	C700.447754,540.333557 699.717896,540.673218 698.965576,540.598999 
	C698.858032,539.366455 698.773071,538.547729 698.688110,537.729004 
z"
          />
          <path
            fill="#FC6B04"
            opacity="1.000000"
            stroke="none"
            d="
M689.904297,353.139160 
	C689.855164,353.929718 689.806030,354.720276 689.660339,357.064819 
	C669.961487,337.943268 648.296570,324.191956 622.328552,316.808472 
	C621.605713,315.571442 621.405090,314.563568 620.832703,314.262665 
	C617.279297,312.394653 613.660034,310.648071 610.026794,308.937225 
	C607.831421,307.903442 605.482483,307.170044 603.359436,306.013702 
	C599.260559,303.781281 595.251892,301.378845 591.274719,298.934753 
	C590.921143,298.717499 591.071960,297.679474 590.988525,297.022644 
	C607.790039,300.538727 624.133362,305.456085 639.469666,313.296478 
	C644.318176,315.775177 648.946167,318.685303 654.176941,321.184143 
	C655.105408,321.086853 655.531982,321.203400 656.047729,321.483826 
	C656.297424,321.851654 656.503296,321.986481 656.866821,322.240662 
	C656.979309,322.429077 657.121765,322.844116 657.116821,323.206970 
	C658.092102,324.026581 659.072327,324.483337 660.029053,324.968201 
	C660.005615,324.996338 660.056763,324.937592 660.066956,325.300293 
	C661.174316,326.338257 662.271484,327.013519 663.537476,327.737366 
	C663.706299,327.785980 664.005188,327.970886 664.024536,328.328918 
	C665.340759,329.467957 666.637634,330.248993 668.158081,331.133331 
	C668.663879,331.427094 668.863403,331.682312 668.979736,332.374695 
	C670.240417,333.592926 671.501343,334.438751 672.833252,335.630066 
	C674.991333,337.619507 677.078552,339.263489 679.165710,340.907501 
	C679.165710,340.907501 679.619934,340.908997 679.757263,341.211395 
	C679.906616,341.708649 679.918579,341.903473 680.016235,342.316986 
	C680.281494,342.809692 680.528503,342.993073 680.875977,343.097229 
	C680.909119,343.108673 680.902527,343.178528 680.957397,343.497589 
	C681.695190,344.198151 682.378113,344.579651 683.060974,344.961121 
	C683.060974,344.961151 683.005493,344.998962 683.076416,345.236633 
	C683.459839,345.634338 683.772400,345.794434 684.084961,345.954498 
	C684.084961,345.954498 684.004700,345.976410 683.984985,346.313477 
	C685.638977,348.448151 687.312683,350.245758 689.177856,352.191193 
	C689.606689,352.566254 689.785034,352.832977 689.904297,353.139160 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M664.005188,327.970886 
	C664.005188,327.970886 663.706299,327.785980 663.422119,327.433167 
	C662.110901,326.366119 661.083801,325.651855 660.056763,324.937592 
	C660.056763,324.937592 660.005615,324.996338 659.935730,324.714417 
	C659.540222,324.001068 659.214600,323.569672 658.991821,323.029419 
	C659.094666,322.920563 659.309448,322.711884 659.309448,322.711884 
	C661.543884,324.169189 663.778320,325.626465 666.015869,327.533813 
	C666.018921,327.983917 665.799438,328.243134 665.504761,328.167358 
	C664.808472,328.051361 664.406860,328.011108 664.005188,327.970886 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M679.096436,340.589478 
	C677.078552,339.263489 674.991333,337.619507 672.889404,335.558868 
	C672.874695,335.142212 672.989380,335.007660 673.262573,335.147583 
	C674.260559,335.639404 674.985291,335.991333 675.710022,336.343323 
	C676.815735,337.652679 677.921448,338.962036 679.096436,340.589478 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M688.986389,352.043396 
	C687.312683,350.245758 685.638977,348.448151 684.010559,346.280640 
	C684.229370,345.877167 684.402832,345.843536 684.873108,346.169128 
	C685.765381,347.015717 686.360962,347.503052 686.956543,347.990387 
	C686.956543,347.990387 686.967834,348.031952 687.050842,348.325378 
	C687.740845,349.073456 688.347778,349.528107 688.954773,349.982788 
	C688.954773,349.982788 688.959290,350.028809 688.951233,350.369568 
	C688.957581,351.154694 688.971985,351.599030 688.986389,352.043396 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M668.980103,332.002258 
	C668.863403,331.682312 668.663879,331.427094 668.072876,330.848480 
	C667.109253,329.721252 666.454346,328.982208 665.799438,328.243164 
	C665.799438,328.243134 666.018921,327.983917 666.129517,327.852234 
	C668.148193,329.256287 670.056335,330.792053 671.656006,332.681213 
	C670.558350,332.690491 669.769226,332.346375 668.980103,332.002258 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M655.958618,321.319916 
	C655.531982,321.203400 655.105408,321.086853 654.350220,320.921265 
	C653.408752,320.118866 652.795959,319.365509 652.062256,318.342224 
	C653.550110,318.816895 655.158875,319.561493 656.876709,320.659851 
	C656.985779,321.013580 656.789368,321.216797 656.577393,321.207581 
	C656.365356,321.198395 655.958618,321.319916 655.958618,321.319916 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M668.979736,332.374695 
	C669.769226,332.346375 670.558350,332.690491 671.672180,333.028564 
	C672.264160,333.192505 672.514465,333.384552 672.867065,334.032166 
	C672.987244,334.646332 672.988342,334.826996 672.989380,335.007660 
	C672.989380,335.007660 672.874695,335.142212 672.818481,335.213379 
	C671.501343,334.438751 670.240417,333.592926 668.979736,332.374695 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M689.177856,352.191193 
	C688.971985,351.599030 688.957581,351.154694 688.972595,350.378448 
	C689.693726,350.760529 690.385498,351.474487 691.082764,352.531433 
	C690.892395,352.923584 690.696350,352.972717 690.202332,353.080505 
	C689.785034,352.832977 689.606689,352.566254 689.177856,352.191193 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M693.856201,355.803589 
	C693.123108,355.408142 692.437073,354.669067 691.705322,353.606293 
	C692.407471,354.008392 693.155334,354.734161 693.856201,355.803589 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M657.121765,322.844116 
	C657.121765,322.844116 656.979309,322.429077 656.914429,322.033203 
	C656.849548,321.637360 656.789368,321.216797 656.789368,321.216797 
	C656.789368,321.216797 656.985779,321.013580 657.083984,320.910217 
	C657.798767,321.266724 658.415405,321.726593 659.170776,322.449158 
	C659.309448,322.711884 659.094666,322.920563 658.696899,322.982910 
	C657.906738,322.978180 657.514221,322.911133 657.121765,322.844116 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M673.262573,335.147583 
	C672.988342,334.826996 672.987244,334.646332 673.035522,334.198914 
	C673.955444,334.510010 674.825989,335.087860 675.703308,336.004517 
	C674.985291,335.991333 674.260559,335.639404 673.262573,335.147583 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M686.959045,347.669495 
	C686.360962,347.503052 685.765381,347.015717 685.131470,346.228394 
	C685.715942,346.401794 686.338745,346.875214 686.959045,347.669495 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M688.958496,349.657990 
	C688.347778,349.528107 687.740845,349.073456 687.071594,348.331146 
	C687.660339,348.473419 688.311279,348.903290 688.958496,349.657990 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M683.029297,344.670776 
	C682.378113,344.579651 681.695190,344.198151 681.250122,343.436646 
	C681.680237,343.005585 681.872498,342.954559 682.064697,342.903564 
	C682.375610,343.395874 682.686584,343.888153 683.029297,344.670776 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M682.040894,342.582397 
	C681.872498,342.954559 681.680237,343.005585 681.195251,343.117554 
	C680.902527,343.178528 680.909119,343.108673 680.836670,342.846619 
	C680.486328,342.422485 680.208496,342.260406 679.930603,342.098328 
	C679.918579,341.903473 679.906616,341.708649 679.983276,341.234558 
	C680.720337,341.390625 681.368713,341.825928 682.040894,342.582397 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M684.046997,345.672455 
	C683.772400,345.794434 683.459839,345.634338 683.088135,345.205170 
	C683.355652,345.087494 683.682312,345.238953 684.046997,345.672455 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M694.718018,356.993256 
	C694.375732,357.162018 694.127625,356.955780 693.908752,356.409729 
	C694.146484,356.352417 694.437866,356.535217 694.718018,356.993256 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M700.012207,364.698608 
	C699.788330,364.741302 699.527039,364.557007 699.289307,364.118683 
	C699.620850,363.971710 699.841492,364.174011 700.012207,364.698608 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M649.538696,307.551941 
	C649.478760,307.588379 649.598633,307.515533 649.538696,307.551941 
z"
          />
          <path
            fill="#FD8B03"
            opacity="1.000000"
            stroke="none"
            d="
M649.861938,579.201904 
	C650.610718,578.756226 651.359436,578.310486 652.665588,577.831665 
	C653.575134,577.728821 653.791748,577.519226 653.872925,577.169678 
	C654.012207,577.073181 654.151489,576.976624 654.882080,576.901611 
	C655.921448,576.507141 656.369629,576.091064 656.817749,575.675049 
	C658.179626,574.677551 659.541565,573.680115 661.558838,572.729736 
	C663.159851,571.985962 664.105408,571.195007 665.051025,570.404114 
	C665.496216,569.936890 665.941467,569.469604 667.059753,568.936951 
	C673.581787,566.021790 679.127686,562.820374 682.032349,556.552917 
	C682.161011,556.406677 682.289734,556.260437 683.028137,556.010986 
	C685.700195,553.543152 687.762573,551.178467 689.824951,548.813782 
	C690.855713,547.523010 691.886475,546.232239 693.562195,544.828369 
	C695.140991,544.474060 696.074768,544.232910 696.885559,544.330078 
	C696.278198,546.250732 695.793884,547.832886 695.309570,549.415100 
	C695.309570,549.415100 695.233826,549.752258 694.779175,549.889648 
	C693.851929,550.933167 693.379272,551.839417 692.906677,552.745605 
	C692.906677,552.745605 692.720886,553.060791 692.195129,553.152466 
	C690.849548,554.595337 690.029663,555.946533 689.209839,557.297791 
	C689.209839,557.297791 689.085388,557.644531 688.595093,557.759644 
	C687.247803,558.318054 686.241333,558.598999 685.556519,559.229309 
	C676.939026,567.161865 668.345764,575.121216 659.811707,583.143311 
	C658.680664,584.206482 657.911560,585.654785 656.974854,586.924683 
	C656.974854,586.924683 656.979126,586.985046 656.949097,586.978638 
	C655.383972,587.409668 653.619263,587.499207 652.348999,588.337769 
	C637.980591,597.823486 622.450439,604.640259 605.405396,608.578003 
	C606.243408,603.557922 609.140442,601.118408 614.053467,600.928711 
	C615.665588,600.866516 617.247803,600.030579 618.843689,599.548462 
	C618.112061,598.279297 617.380432,597.010132 616.654114,595.750000 
	C622.232422,594.236511 622.232422,594.236511 623.389343,590.926636 
	C625.775452,590.190491 627.867554,589.674316 629.803955,588.809509 
	C634.834656,586.562927 639.796997,584.163025 644.786621,581.824219 
	C645.776184,581.250244 646.765808,580.676208 648.320923,580.057800 
	C649.211609,579.742920 649.536804,579.472412 649.861938,579.201904 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M657.324707,586.933594 
	C657.911560,585.654785 658.680664,584.206482 659.811707,583.143311 
	C668.345764,575.121216 676.939026,567.161865 685.556519,559.229309 
	C686.241333,558.598999 687.247803,558.318054 688.460571,557.885498 
	C681.372314,565.467712 674.034119,573.147949 666.425354,580.550049 
	C663.861755,583.043945 660.611206,584.831848 657.324707,586.933594 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M695.588257,549.381958 
	C695.793884,547.832886 696.278198,546.250732 696.880676,544.334961 
	C696.998840,544.001343 696.991272,544.011780 697.258728,543.931702 
	C697.992004,543.547974 698.457764,543.244385 699.018188,543.033691 
	C699.112793,543.126648 699.293762,543.320557 699.293823,543.320557 
	C698.507812,544.932556 697.721863,546.544556 696.897644,548.491516 
	C696.528564,549.000610 696.197693,549.174744 695.588257,549.381958 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M689.547424,557.227173 
	C690.029663,555.946533 690.849548,554.595337 692.038269,553.246460 
	C691.566406,554.551514 690.725708,555.854065 689.547424,557.227173 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M693.227783,552.713135 
	C693.379272,551.839417 693.851929,550.933167 694.662598,550.017151 
	C694.516724,550.898438 694.032776,551.789551 693.227783,552.713135 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M662.927002,592.975952 
	C662.797913,592.881348 662.694275,592.815369 662.590637,592.749390 
	C662.677979,592.721008 662.765320,592.692688 662.910156,592.796997 
	C662.967651,592.929565 662.952393,593.004639 662.927002,592.975952 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M698.988098,541.012878 
	C699.717896,540.673218 700.447754,540.333557 701.473328,539.948364 
	C701.131836,540.936768 700.494568,541.970703 699.575500,543.162598 
	C699.293762,543.320557 699.112793,543.126648 699.034058,542.708984 
	C698.974670,541.856506 698.994019,541.421753 699.007080,540.993164 
	C699.000732,540.999268 698.988098,541.012878 698.988098,541.012878 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M604.308350,625.951782 
	C604.485962,625.657959 604.961060,625.346619 605.737183,625.021118 
	C605.560791,625.316101 605.083313,625.625183 604.308350,625.951782 
z"
          />
          <path
            fill="#FDA806"
            opacity="1.000000"
            stroke="none"
            d="
M471.647858,373.238373 
	C467.607178,380.661957 463.314270,387.960938 459.573822,395.532806 
	C448.928345,417.082642 445.174957,440.093475 446.809875,463.861786 
	C449.522980,503.304626 466.363098,535.800720 496.416931,561.467896 
	C496.655487,561.671692 496.778809,562.010437 497.029480,562.965271 
	C497.817444,564.035461 498.531799,564.426514 499.246185,564.817566 
	C499.246185,564.817566 499.140076,564.851624 499.131104,565.177124 
	C501.076813,566.666016 503.031525,567.829407 504.986267,568.992798 
	C504.986267,568.992798 505.003601,568.995117 505.009705,569.368164 
	C507.932922,575.208313 513.121094,576.816528 518.685181,577.785889 
	C518.685181,577.785889 518.812988,577.645630 518.943909,578.052979 
	C523.110901,582.015869 527.561707,584.669495 533.118164,584.919312 
	C533.118164,584.919312 533.028687,584.970337 533.056885,585.283264 
	C533.916077,590.222168 530.988708,594.333435 525.142334,596.751282 
	C522.122681,591.172668 521.575806,591.144470 515.351013,594.217468 
	C513.387146,595.187073 510.474762,596.365479 508.888458,595.599976 
	C502.138611,592.342407 495.748047,588.346924 489.177704,584.708679 
	C484.502136,582.119568 479.774658,579.619385 474.999542,577.220520 
	C474.370850,576.904663 473.321594,577.426025 473.729523,577.362244 
	C474.850250,580.474060 475.890259,583.361694 476.930237,586.249329 
	C471.151428,581.587280 465.372620,576.925232 459.297058,571.679199 
	C458.982117,563.908386 457.998596,562.588013 451.994873,562.198242 
	C451.994873,559.110291 452.176331,555.968689 451.906525,552.866333 
	C451.796875,551.605469 450.982269,549.985657 449.972870,549.357300 
	C449.178070,548.862549 446.705658,549.364685 446.539276,549.904785 
	C446.170044,551.103210 446.484344,552.745239 447.058594,553.949585 
	C448.056427,556.042419 449.198029,557.889526 446.013702,559.009033 
	C444.724701,557.549255 443.435669,556.089417 442.366150,554.272339 
	C444.079498,549.926270 443.403046,546.390015 440.891052,542.904114 
	C439.538635,541.027344 438.103912,538.580322 438.174255,536.438782 
	C438.321350,531.960327 435.874451,529.685303 432.319763,529.203003 
	C427.438660,528.540649 427.497528,526.444763 429.012909,522.786377 
	C429.777679,520.940063 429.814453,518.792236 430.238892,516.459351 
	C428.187836,516.591736 426.939117,516.672302 425.405396,516.771301 
	C424.978180,511.231934 422.139526,508.923492 416.778442,510.121857 
	C415.144775,505.696503 413.511108,501.271179 412.214966,496.306396 
	C414.012238,495.214539 415.471985,494.662109 416.931732,494.109680 
	C417.000061,493.549713 417.068390,492.989746 417.136688,492.429810 
	C415.075836,491.957214 413.014954,491.484589 410.954102,491.011993 
	C410.600983,489.598633 410.247833,488.185272 410.238464,486.397888 
	C412.217957,486.791534 413.853699,487.559174 414.835388,488.019897 
	C415.465179,484.789520 416.630524,481.542572 416.609741,478.303192 
	C416.575287,472.933380 418.610107,467.545807 414.791077,462.099854 
	C413.118622,459.715027 413.370819,453.527313 415.291504,451.599579 
	C419.000183,447.877258 419.432098,444.617126 418.257050,440.088440 
	C417.313812,436.453094 417.703339,433.130188 422.343506,431.939331 
	C423.364502,431.677277 424.122864,430.300537 424.948120,429.388214 
	C426.279877,427.916016 427.299835,425.950470 428.923187,424.993286 
	C433.627289,422.219635 434.795044,419.825470 433.296509,414.418701 
	C431.627167,408.395508 435.218048,402.030334 441.238953,400.426605 
	C443.148468,399.918030 445.138672,399.688019 447.017700,399.095093 
	C450.109131,398.119537 452.101929,396.744934 449.831299,392.937836 
	C449.241730,391.949371 450.483734,388.444366 451.136292,388.372162 
	C455.119934,387.931580 456.171844,385.328979 457.195099,382.132233 
	C457.702637,380.546692 458.937500,378.725494 460.352264,377.990417 
	C463.965088,376.113129 467.863617,374.785736 471.647858,373.238373 
z"
          />
          <path
            fill="#FDA205"
            opacity="1.000000"
            stroke="none"
            d="
M471.951935,373.170044 
	C467.863617,374.785736 463.965088,376.113129 460.352264,377.990417 
	C458.937500,378.725494 457.702637,380.546692 457.195099,382.132233 
	C456.171844,385.328979 455.119934,387.931580 451.136292,388.372162 
	C450.483734,388.444366 449.241730,391.949371 449.831299,392.937836 
	C452.101929,396.744934 450.109131,398.119537 447.017700,399.095093 
	C445.138672,399.688019 443.148468,399.918030 441.238953,400.426605 
	C435.218048,402.030334 431.627167,408.395508 433.296509,414.418701 
	C434.795044,419.825470 433.627289,422.219635 428.923187,424.993286 
	C427.299835,425.950470 426.279877,427.916016 424.948120,429.388214 
	C424.122864,430.300537 423.364502,431.677277 422.343506,431.939331 
	C417.703339,433.130188 417.313812,436.453094 418.257050,440.088440 
	C419.432098,444.617126 419.000183,447.877258 415.291504,451.599579 
	C413.370819,453.527313 413.118622,459.715027 414.791077,462.099854 
	C418.610107,467.545807 416.575287,472.933380 416.609741,478.303192 
	C416.630524,481.542572 415.465179,484.789520 414.835388,488.019897 
	C413.853699,487.559174 412.217957,486.791534 410.193115,486.032715 
	C406.551331,472.143707 405.405884,458.190125 406.598236,443.850037 
	C407.770355,429.753937 410.284576,416.077881 414.834625,402.738373 
	C415.465302,400.889313 416.410400,399.147522 417.856567,397.126709 
	C419.299255,396.170990 420.181946,395.518372 420.874878,394.704254 
	C422.830170,392.407074 424.287048,389.359680 426.714600,387.867310 
	C430.312714,385.655334 432.051117,382.853851 433.119720,378.935181 
	C434.123444,375.254547 435.867981,371.747955 437.550751,368.293457 
	C438.289764,366.776398 439.684326,365.578644 440.655762,364.389465 
	C441.553162,368.470001 443.893433,369.193451 447.459686,366.304321 
	C452.898407,361.898315 458.344025,357.500824 463.906464,353.002838 
	C467.397430,352.723907 471.621552,352.462189 475.824677,352.006989 
	C477.186096,351.859589 479.499146,351.439423 479.661072,350.744354 
	C480.716766,346.211975 484.556854,345.872131 487.867767,344.478210 
	C490.000061,343.580444 492.074036,342.261719 493.781158,340.701660 
	C496.458496,338.254944 498.702789,338.426605 500.830139,341.281311 
	C499.186096,343.396606 497.723389,345.231140 496.260681,347.065643 
	C496.260681,347.065643 496.202942,347.223480 495.761963,347.163940 
	C492.867645,349.546692 490.414337,351.988983 487.961029,354.431274 
	C486.662750,355.867462 485.364441,357.303619 483.470642,358.943054 
	C481.881287,360.091949 480.887451,361.037628 479.580688,361.834595 
	C477.940247,361.830750 476.612732,361.975647 475.285217,362.120544 
	C475.364166,362.636688 475.443085,363.152863 475.522003,363.669006 
	C476.641602,363.777832 477.761169,363.886688 478.880768,363.995514 
	C478.546936,364.570618 478.213104,365.145752 477.363464,365.948059 
	C476.618103,366.704834 476.388580,367.234406 476.158997,367.763977 
	C474.858002,369.543243 473.557007,371.322510 471.951935,373.170044 
z"
          />
          <path
            fill="#FC9E08"
            opacity="1.000000"
            stroke="none"
            d="
M477.097656,586.530334 
	C475.890259,583.361694 474.850250,580.474060 473.729523,577.362244 
	C473.321594,577.426025 474.370850,576.904663 474.999542,577.220520 
	C479.774658,579.619385 484.502136,582.119568 489.177704,584.708679 
	C495.748047,588.346924 502.138611,592.342407 508.888458,595.599976 
	C510.474762,596.365479 513.387146,595.187073 515.351013,594.217468 
	C521.575806,591.144470 522.122681,591.172668 525.142334,596.751282 
	C530.988708,594.333435 533.916077,590.222168 533.079468,585.236938 
	C539.278076,586.581421 545.467407,588.341431 551.689270,589.978027 
	C570.288940,594.870605 589.141479,595.068909 608.947021,592.944153 
	C611.461609,592.522522 613.141296,592.062500 614.820984,591.602417 
	C616.674500,591.095947 618.528015,590.589539 620.927979,590.340576 
	C621.991089,590.727905 622.507751,590.857849 623.024414,590.987793 
	C622.232422,594.236511 622.232422,594.236511 616.654114,595.750000 
	C617.380432,597.010132 618.112061,598.279297 618.843689,599.548462 
	C617.247803,600.030579 615.665588,600.866516 614.053467,600.928711 
	C609.140442,601.118408 606.243408,603.557922 605.014038,608.569580 
	C587.313599,613.102478 569.548767,614.884583 551.234131,613.353821 
	C532.897400,611.821228 515.715027,606.846252 499.173004,599.430847 
	C491.522186,596.001099 484.546783,591.064636 477.097656,586.530334 
z"
          />
          <path
            fill="#FD9B04"
            opacity="1.000000"
            stroke="none"
            d="
M501.011475,341.000549 
	C498.702789,338.426605 496.458496,338.254944 493.781158,340.701660 
	C492.074036,342.261719 490.000061,343.580444 487.867767,344.478210 
	C484.556854,345.872131 480.716766,346.211975 479.661072,350.744354 
	C479.499146,351.439423 477.186096,351.859589 475.824677,352.006989 
	C471.621552,352.462189 467.397430,352.723907 463.906464,353.002838 
	C458.344025,357.500824 452.898407,361.898315 447.459686,366.304321 
	C443.893433,369.193451 441.553162,368.470001 440.655762,364.389465 
	C439.684326,365.578644 438.289764,366.776398 437.550751,368.293457 
	C435.867981,371.747955 434.123444,375.254547 433.119720,378.935181 
	C432.051117,382.853851 430.312714,385.655334 426.714600,387.867310 
	C424.287048,389.359680 422.830170,392.407074 420.874878,394.704254 
	C420.181946,395.518372 419.299255,396.170990 418.110046,396.887360 
	C420.187897,390.797455 422.068634,384.387665 425.246246,378.702698 
	C433.130249,364.597595 442.227539,351.259857 454.535065,340.543518 
	C460.844452,335.049835 467.627869,330.100616 474.586761,325.228638 
	C475.219543,327.758484 476.528259,329.072449 477.723602,326.633331 
	C479.017944,323.992249 480.972046,323.815857 483.147827,322.712433 
	C488.121429,320.190094 492.753296,318.800781 498.091370,320.925934 
	C498.478027,321.079865 499.046082,320.733337 499.536377,320.678314 
	C507.540222,319.779907 515.549988,318.932037 523.546814,317.975159 
	C525.967773,317.685425 528.336792,316.953918 530.758423,316.680298 
	C535.742859,316.117035 540.736389,315.786713 545.092896,319.086700 
	C544.819824,319.522888 544.546692,319.959045 544.187134,320.636597 
	C543.674438,320.993347 543.248291,321.108704 542.102783,321.139008 
	C533.708984,323.879395 526.011597,326.646210 518.397766,329.626617 
	C517.683777,329.906067 517.497498,331.533508 517.066223,332.535095 
	C516.895081,332.681061 516.723999,332.826996 515.883850,333.006531 
	C514.256165,333.801605 513.297668,334.563141 512.339111,335.324677 
	C512.339111,335.324677 512.271301,335.270569 512.017761,335.286804 
	C511.400574,335.421143 511.230347,335.670868 511.253448,336.052277 
	C511.253448,336.052277 510.941132,336.361694 510.419312,336.327972 
	C509.634583,336.570923 509.371674,336.847595 509.108765,337.124268 
	C509.108765,337.124268 509.026276,337.104340 508.735687,337.084167 
	C507.619476,337.385315 506.793884,337.706635 505.762390,338.118652 
	C505.301056,338.384338 505.117432,338.616241 504.687927,338.981384 
	C503.250641,339.705353 502.131073,340.352936 501.011475,341.000549 
z"
          />
          <path
            fill="#FD8B03"
            opacity="1.000000"
            stroke="none"
            d="
M545.472168,319.058228 
	C540.736389,315.786713 535.742859,316.117035 530.758423,316.680298 
	C528.336792,316.953918 525.967773,317.685425 523.546814,317.975159 
	C515.549988,318.932037 507.540222,319.779907 499.536377,320.678314 
	C499.046082,320.733337 498.478027,321.079865 498.091370,320.925934 
	C492.753296,318.800781 488.121429,320.190094 483.147827,322.712433 
	C480.972046,323.815857 479.017944,323.992249 477.723602,326.633331 
	C476.528259,329.072449 475.219543,327.758484 474.935547,325.156006 
	C477.608582,322.531342 480.052185,319.803894 483.083588,318.160889 
	C495.639435,311.355438 508.223877,304.533691 522.688354,301.404541 
	C524.975220,302.337555 526.791626,303.140381 528.691467,303.492371 
	C530.344055,303.798523 532.132568,303.363098 533.788757,303.659332 
	C539.433777,304.668976 545.006775,306.192169 550.684753,306.870636 
	C555.193298,307.409393 559.956482,306.308258 564.346252,307.206909 
	C568.526001,308.062561 573.537537,308.294220 576.022888,312.997559 
	C565.965759,315.008270 555.908569,317.019012 545.472168,319.058228 
z"
          />
          <path
            fill="#FD7B03"
            opacity="1.000000"
            stroke="none"
            d="
M576.489136,313.008484 
	C573.537537,308.294220 568.526001,308.062561 564.346252,307.206909 
	C559.956482,306.308258 555.193298,307.409393 550.684753,306.870636 
	C545.006775,306.192169 539.433777,304.668976 533.788757,303.659332 
	C532.132568,303.363098 530.344055,303.798523 528.691467,303.492371 
	C526.791626,303.140381 524.975220,302.337555 523.066162,301.374756 
	C524.518494,300.519226 526.027222,300.019440 527.776367,299.281677 
	C539.283508,297.756561 550.529968,296.222839 561.826111,295.294739 
	C566.864014,294.880829 572.001892,295.685242 577.595093,296.336090 
	C580.641724,300.069153 583.270569,300.460480 586.009155,296.958466 
	C587.392761,296.966858 588.776428,296.975281 590.574280,297.003174 
	C591.071960,297.679474 590.921143,298.717499 591.274719,298.934753 
	C595.251892,301.378845 599.260559,303.781281 603.359436,306.013702 
	C605.482483,307.170044 607.831421,307.903442 610.026794,308.937225 
	C613.660034,310.648071 617.279297,312.394653 620.832703,314.262665 
	C621.405090,314.563568 621.605713,315.571442 621.991455,316.651184 
	C609.272644,314.405609 596.498657,312.191467 583.399780,312.999084 
	C581.259338,313.131042 579.103882,313.018799 576.489136,313.008484 
z"
          />
          <path
            fill="#FDA205"
            opacity="1.000000"
            stroke="none"
            d="
M416.824585,510.493713 
	C422.139526,508.923492 424.978180,511.231934 425.405396,516.771301 
	C426.939117,516.672302 428.187836,516.591736 430.238892,516.459351 
	C429.814453,518.792236 429.777679,520.940063 429.012909,522.786377 
	C427.497528,526.444763 427.438660,528.540649 432.319763,529.203003 
	C435.874451,529.685303 438.321350,531.960327 438.174255,536.438782 
	C438.103912,538.580322 439.538635,541.027344 440.891052,542.904114 
	C443.403046,546.390015 444.079498,549.926270 442.206604,553.986145 
	C440.632080,552.921570 439.436554,551.785889 438.106628,550.057556 
	C437.960510,549.259155 437.948792,549.053467 437.937042,548.847778 
	C434.063660,543.232056 429.709442,537.880249 426.444244,531.930725 
	C422.743927,525.188416 420.019470,517.910583 416.824585,510.493713 
z"
          />
          <path
            fill="#FDA205"
            opacity="1.000000"
            stroke="none"
            d="
M446.168396,559.305786 
	C449.198029,557.889526 448.056427,556.042419 447.058594,553.949585 
	C446.484344,552.745239 446.170044,551.103210 446.539276,549.904785 
	C446.705658,549.364685 449.178070,548.862549 449.972870,549.357300 
	C450.982269,549.985657 451.796875,551.605469 451.906525,552.866333 
	C452.176331,555.968689 451.994873,559.110291 451.994873,562.198242 
	C457.998596,562.588013 458.982117,563.908386 458.999146,571.514893 
	C455.909393,569.178772 452.770294,566.476868 449.756042,563.642151 
	C448.476807,562.439087 447.459442,560.957581 446.168396,559.305786 
z"
          />
          <path
            fill="#FDA205"
            opacity="1.000000"
            stroke="none"
            d="
M410.943939,491.395691 
	C413.014954,491.484589 415.075836,491.957214 417.136688,492.429810 
	C417.068390,492.989746 417.000061,493.549713 416.931732,494.109680 
	C415.471985,494.662109 414.012238,495.214539 412.193634,495.918365 
	C411.534454,494.639618 411.234100,493.209503 410.943939,491.395691 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M477.406830,318.260254 
	C477.628510,317.766571 478.209381,317.408417 479.160278,317.056030 
	C478.942230,317.506470 478.354126,317.951111 477.406830,318.260254 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M443.465637,348.193817 
	C443.326385,347.884003 443.491516,347.578918 443.922485,347.214600 
	C444.048920,347.503113 443.909485,347.850800 443.465637,348.193817 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M476.388245,319.420715 
	C476.212311,319.159698 476.366180,318.762817 476.799927,318.202637 
	C476.959229,318.454559 476.838654,318.869690 476.388245,319.420715 
z"
          />
          <path
            fill="#FDA806"
            opacity="1.000000"
            stroke="none"
            d="
M437.760254,548.973145 
	C437.948792,549.053467 437.960510,549.259155 437.969635,549.774536 
	C437.839172,549.755615 437.711304,549.427063 437.760254,548.973145 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M480.106079,317.082214 
	C480.028442,316.803223 480.279602,316.524963 480.793518,316.147766 
	C480.849091,316.393555 480.641968,316.738251 480.106079,317.082214 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M481.464752,316.004883 
	C481.078156,315.767487 481.086517,315.475616 481.183044,314.751007 
	C481.467377,314.862305 481.663513,315.406342 481.464752,316.004883 
z"
          />
          <path
            fill="#FC6B04"
            opacity="1.000000"
            stroke="none"
            d="
M585.607056,296.941040 
	C583.270569,300.460480 580.641724,300.069153 578.048340,296.349945 
	C580.402222,296.284180 582.803589,296.603912 585.607056,296.941040 
z"
          />
          <path
            fill="#0A0A67"
            opacity="1.000000"
            stroke="none"
            d="
M627.789490,431.715088 
	C625.194824,417.310181 638.677002,406.290588 650.940186,406.133423 
	C656.493286,406.062225 662.226013,406.846649 667.589478,408.302795 
	C675.170593,410.360962 680.248352,415.763855 681.912659,423.473694 
	C683.189026,429.386475 683.430664,435.522614 684.175232,441.927155 
	C675.365234,441.927155 667.625854,441.927155 659.518188,441.927155 
	C659.288940,436.770294 659.413879,431.781189 658.678284,426.922394 
	C658.389221,425.013031 656.175598,423.395050 654.838379,421.644379 
	C653.809204,423.470947 651.910095,425.285950 651.885681,427.125885 
	C651.646851,445.098358 651.663879,463.075317 651.772400,481.050049 
	C651.787292,483.517609 651.584351,487.340820 655.371155,486.776947 
	C656.906311,486.548340 658.780151,483.067719 659.034363,480.895416 
	C659.589539,476.151398 659.206055,471.297516 659.206055,466.275452 
	C657.850647,465.875732 656.634033,465.516937 655.253784,465.109894 
	C655.253784,460.472717 655.253784,455.897949 655.253784,451.155090 
	C664.743835,451.155090 673.958313,451.155090 683.352417,451.155090 
	C683.352417,467.820374 683.352417,484.207458 683.352417,501.018005 
	C678.742432,501.018005 674.370850,501.478882 670.180908,500.806702 
	C668.327820,500.509369 666.842957,497.916840 665.065552,496.248535 
	C658.366272,504.062897 649.481262,504.634033 640.243835,500.384247 
	C632.427612,496.788300 628.379578,490.078491 627.832336,481.416473 
	C627.675171,478.928528 627.648926,476.432373 627.986328,473.687805 
	C628.945312,475.943115 629.480652,478.450531 630.010620,481.363647 
	C630.853638,483.145630 631.702026,484.521973 632.550415,485.898315 
	C633.396484,484.787231 634.212402,483.651215 635.100769,482.574982 
	C635.588074,481.984589 636.204041,481.501526 636.751099,480.959045 
	C643.583130,474.183655 648.494324,466.709198 649.223999,456.516693 
	C649.659485,450.434357 647.494568,445.489868 644.867126,440.889221 
	C641.620605,435.204559 636.933655,430.341339 632.837097,425.148438 
	C632.690063,424.962067 632.203308,425.043732 631.665527,424.761108 
	C630.978149,425.792542 630.604980,427.116699 629.992493,428.319000 
	C629.383301,429.514771 628.533691,430.588104 627.789490,431.715088 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M547.133789,482.985535 
	C547.130981,483.713318 547.128113,484.441101 547.002075,485.933472 
	C547.558472,489.106079 548.238159,491.514130 549.301941,493.949799 
	C550.869934,494.503418 552.014404,495.159271 553.244629,495.532806 
	C558.350342,497.083038 563.215271,501.394867 568.887207,497.021454 
	C568.941956,498.166748 568.996643,499.312073 569.064453,500.731567 
	C554.454895,500.731567 540.046570,500.731567 525.152100,500.731567 
	C524.795654,495.573517 524.445679,490.508545 524.070068,485.072357 
	C521.343506,485.072357 518.777832,485.072357 515.757690,485.072357 
	C515.119202,490.180084 514.486694,495.240448 513.810791,500.647827 
	C505.715149,500.647827 497.653290,500.647827 488.986023,500.647827 
	C489.363007,497.099030 489.673431,493.542572 490.127380,490.004578 
	C493.472961,463.930664 496.892883,437.866119 500.151062,411.781342 
	C500.532410,408.728363 501.421844,407.532990 504.739227,407.606384 
	C515.194153,407.837616 525.657410,407.691620 536.273499,407.691620 
	C537.471863,415.513519 538.599121,422.871307 539.561096,430.723572 
	C539.520508,431.474213 539.645264,431.730347 539.769958,431.986481 
	C539.825806,432.154053 539.881714,432.321655 539.678589,432.738098 
	C539.419556,432.986969 538.976562,433.010132 538.702942,432.757812 
	C537.785156,430.071136 537.140991,427.636749 536.298523,424.453033 
	C533.828796,427.106964 531.635742,428.766266 530.380615,430.961761 
	C527.976318,435.167358 524.940063,439.511139 524.284119,444.100342 
	C523.286560,451.079285 524.092712,458.316010 523.731262,465.218323 
	C522.153320,453.665985 520.976868,442.338623 519.800415,431.011292 
	C519.623230,431.012695 519.446106,431.014130 519.268982,431.015564 
	C517.744141,443.240875 516.219360,455.466187 514.579468,468.614166 
	C518.273010,468.059143 520.951294,467.656677 523.779419,467.620392 
	C528.375671,473.148407 532.890442,478.253235 537.249695,483.487579 
	C541.898499,489.069763 544.197388,489.006683 547.133789,482.985535 
z"
          />
          <path
            fill="#1B78B1"
            opacity="1.000000"
            stroke="none"
            d="
M584.735901,440.948547 
	C585.207214,442.767517 585.678467,444.586487 586.149719,446.405457 
	C586.369080,446.370880 586.588440,446.336334 586.807800,446.301758 
	C587.128784,444.098694 587.449768,441.895630 588.213623,439.534363 
	C589.658691,438.840607 590.660889,438.305084 590.787598,438.237366 
	C595.242920,438.282440 597.903748,444.037384 602.791992,439.165070 
	C602.791992,442.406036 602.791992,445.164734 602.791992,449.277954 
	C603.891174,447.188843 604.347046,446.322449 604.802917,445.456055 
	C605.224121,445.418640 605.645325,445.381226 606.066528,445.343842 
	C606.995239,448.823730 609.601929,453.108673 608.418579,455.575317 
	C607.009155,458.513306 609.525452,464.631195 603.059631,464.426025 
	C603.059631,466.385345 603.059631,468.209991 603.048706,470.414581 
	C602.723450,472.190918 602.408997,473.587341 602.094604,474.983734 
	C602.094604,466.567505 602.094604,458.151276 602.094604,449.735016 
	C601.729553,449.691101 601.364502,449.647156 600.999512,449.603210 
	C600.366211,453.513336 599.732971,457.423462 598.563599,461.788147 
	C597.048584,467.012543 594.724915,470.433228 589.282776,469.933441 
	C584.408020,469.485840 578.622253,472.162567 575.320435,466.112152 
	C574.949280,466.792450 574.638489,467.362030 574.327698,467.931610 
	C573.168152,461.519714 572.008545,455.107788 570.848999,448.695892 
	C570.552185,448.732117 570.255432,448.768311 569.958618,448.804535 
	C569.958618,454.893860 569.958618,460.983154 569.648193,467.423401 
	C569.125793,467.573334 568.973511,467.334320 568.877686,466.688843 
	C561.209778,457.887207 564.226501,449.720276 569.825989,442.239594 
	C571.349548,441.529022 572.828918,440.694611 574.406982,440.142181 
	C576.104431,439.547943 577.898865,439.234497 579.642212,438.766052 
	C580.446960,438.549805 581.231018,438.256256 582.264893,438.193848 
	C583.248901,439.242767 583.992432,440.095642 584.735901,440.948547 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M584.752197,440.590881 
	C583.992432,440.095642 583.248901,439.242767 582.261597,437.788910 
	C581.642395,435.209961 581.267029,433.231995 580.914307,431.373230 
	C575.772522,432.322632 570.680237,431.952393 567.328125,434.189667 
	C562.328003,437.526886 558.146606,441.938324 554.721619,447.590027 
	C549.062988,456.927765 558.633667,471.337372 567.363159,472.583496 
	C567.867615,470.746796 568.374329,468.902008 568.880981,467.057220 
	C568.973511,467.334320 569.125793,467.573334 569.526001,467.875916 
	C569.768555,472.200867 569.822998,476.424255 569.229980,481.018799 
	C567.875183,482.566986 567.167786,483.743958 566.642883,484.617218 
	C563.559265,482.757233 560.856689,481.377197 558.438232,479.608582 
	C554.985413,477.083435 549.655457,475.912445 550.652466,469.779266 
	C550.681641,469.600067 549.872925,469.284668 549.454834,469.032776 
	C549.372742,459.972107 549.290710,450.911407 549.674072,441.273743 
	C550.625122,432.972351 555.282898,428.252991 562.182251,426.496582 
	C568.091125,424.992371 573.710144,419.514832 580.727783,423.488647 
	C580.932251,423.029327 581.375305,422.034058 581.818359,421.038818 
	C582.546509,426.099152 583.274658,431.159515 583.937988,436.884644 
	C584.022217,437.998749 584.171204,438.448090 584.320129,438.897430 
	C584.469543,439.342682 584.618958,439.787933 584.752197,440.590881 
M577.404785,424.479828 
	C577.404785,424.479828 577.521606,424.407135 577.404785,424.479828 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M602.005493,475.406128 
	C602.408997,473.587341 602.723450,472.190918 603.304321,470.659790 
	C603.827454,472.009888 604.084229,473.494720 604.382080,475.217163 
	C604.992493,474.415741 605.271729,473.660919 605.784851,473.433472 
	C613.775085,469.891052 618.544617,464.432281 617.035583,455.070648 
	C616.879395,454.101746 616.874817,453.063141 617.036072,452.096222 
	C617.750183,447.814178 616.360718,444.536804 612.911987,441.838898 
	C610.208618,439.724060 607.862549,437.156342 605.321777,434.829132 
	C600.743713,430.635773 593.173401,429.812988 588.945068,433.014587 
	C589.122131,430.563965 589.299255,428.113312 590.009399,425.300903 
	C591.038635,424.621368 591.534790,424.303650 592.448975,423.989441 
	C595.614014,424.334106 598.687866,424.004333 601.042236,425.151337 
	C606.519836,427.819916 611.641907,431.215240 616.927185,434.283142 
	C618.566772,435.234863 620.267761,436.080658 621.961914,437.378235 
	C621.990356,438.858429 621.997620,439.935333 622.007568,441.479584 
	C621.679871,450.394318 621.305908,458.840485 621.067444,467.290436 
	C621.032898,468.512634 621.648621,469.753204 621.979980,471.321838 
	C621.998474,472.107635 622.000488,472.556885 621.635986,473.013428 
	C615.211121,476.691650 609.152710,480.362518 603.094360,484.033386 
	C602.701721,481.298431 602.309082,478.563446 602.005493,475.406128 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M547.245239,482.566162 
	C544.197388,489.006683 541.898499,489.069763 537.249695,483.487579 
	C532.890442,478.253235 528.375671,473.148407 523.781189,467.591064 
	C523.632996,467.195587 523.668457,467.147125 523.989380,466.915344 
	C524.466980,466.191284 524.623596,465.699005 524.708252,465.314423 
	C524.470947,465.489624 524.303101,465.496674 524.132812,465.443268 
	C524.092712,458.316010 523.286560,451.079285 524.284119,444.100342 
	C524.940063,439.511139 527.976318,435.167358 530.380615,430.961761 
	C531.635742,428.766266 533.828796,427.106964 536.298523,424.453033 
	C537.140991,427.636749 537.785156,430.071136 538.722168,433.006775 
	C539.372986,434.013000 539.730896,434.517944 540.088867,435.022888 
	C540.424377,436.723938 540.759888,438.424957 540.673584,440.483398 
	C539.861328,440.357056 539.434021,439.898193 539.088196,439.384430 
	C538.366699,438.312500 537.688232,437.211578 536.992126,436.122559 
	C536.328491,437.226959 535.375427,438.252869 535.050964,439.449249 
	C533.595337,444.817474 532.113586,450.195221 531.075500,455.652161 
	C530.646362,457.908203 530.808533,460.552917 531.589905,462.697235 
	C532.712463,465.777985 534.407776,468.730591 536.299683,471.428589 
	C538.337524,474.334717 540.884216,476.884003 543.942932,480.443115 
	C544.872009,476.766632 545.342102,474.906189 545.812256,473.045715 
	C546.327026,476.079407 546.841797,479.113098 547.245239,482.566162 
z"
          />
          <path
            fill="#085CA3"
            opacity="1.000000"
            stroke="none"
            d="
M588.743164,433.345978 
	C593.173401,429.812988 600.743713,430.635773 605.321777,434.829132 
	C607.862549,437.156342 610.208618,439.724060 612.911987,441.838898 
	C616.360718,444.536804 617.750183,447.814178 617.036072,452.096222 
	C616.874817,453.063141 616.879395,454.101746 617.035583,455.070648 
	C618.544617,464.432281 613.775085,469.891052 605.784851,473.433472 
	C605.271729,473.660919 604.992493,474.415741 604.382080,475.217163 
	C604.084229,473.494720 603.827454,472.009888 603.315186,470.279846 
	C603.059631,468.209991 603.059631,466.385345 603.059631,464.426025 
	C609.525452,464.631195 607.009155,458.513306 608.418579,455.575317 
	C609.601929,453.108673 606.995239,448.823730 606.066528,445.343842 
	C605.645325,445.381226 605.224121,445.418640 604.802917,445.456055 
	C604.347046,446.322449 603.891174,447.188843 602.791992,449.277954 
	C602.791992,445.164734 602.791992,442.406036 602.791992,439.165070 
	C597.903748,444.037384 595.242920,438.282440 590.787598,438.237366 
	C590.660889,438.305084 589.658691,438.840607 588.436523,439.231689 
	C588.324829,437.283966 588.433105,435.480682 588.743164,433.345978 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M630.015991,480.957977 
	C629.480652,478.450531 628.945312,475.943115 628.067505,473.229919 
	C627.634460,459.516144 627.543884,446.008087 627.621338,432.107544 
	C628.533691,430.588104 629.383301,429.514771 629.992493,428.319000 
	C630.604980,427.116699 630.978149,425.792542 631.384583,425.062073 
	C631.312988,430.322876 631.312988,435.043365 631.312988,439.763855 
	C631.556885,439.758118 631.800720,439.752411 632.044617,439.746674 
	C632.044617,437.845856 632.044617,435.945038 632.044617,432.613464 
	C634.113708,435.781403 635.991821,437.641632 636.659363,439.865265 
	C638.848694,447.158722 642.176392,454.456299 638.934448,462.297180 
	C637.470154,465.838745 635.842529,469.321075 634.108032,472.738434 
	C633.437012,474.060608 632.042786,475.017822 631.382629,476.342865 
	C630.677979,477.757111 630.451294,479.409515 630.015991,480.957977 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M545.854004,472.626221 
	C545.342102,474.906189 544.872009,476.766632 543.942932,480.443115 
	C540.884216,476.884003 538.337524,474.334717 536.299683,471.428589 
	C534.407776,468.730591 532.712463,465.777985 531.589905,462.697235 
	C530.808533,460.552917 530.646362,457.908203 531.075500,455.652161 
	C532.113586,450.195221 533.595337,444.817474 535.050964,439.449249 
	C535.375427,438.252869 536.328491,437.226959 536.992126,436.122559 
	C537.688232,437.211578 538.366699,438.312500 539.088196,439.384430 
	C539.434021,439.898193 539.861328,440.357056 540.623230,440.918884 
	C542.628357,451.400269 544.262085,461.803528 545.854004,472.626221 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M622.004883,441.012207 
	C621.997620,439.935333 621.990356,438.858429 621.975464,436.975342 
	C621.775330,431.448669 620.216187,426.992126 615.828552,425.144440 
	C608.338562,421.990265 600.482178,419.705841 592.030945,416.798492 
	C592.030945,419.763947 592.030945,421.874939 592.030945,423.985931 
	C591.534790,424.303650 591.038635,424.621368 590.234009,424.963257 
	C590.347168,420.237122 590.768860,415.486816 591.777649,410.370544 
	C595.574341,410.656799 598.873840,411.026703 601.976318,412.015198 
	C610.724182,414.802307 621.659790,414.842194 622.857605,427.724457 
	C622.866150,427.816040 623.131714,427.883698 623.277222,427.962524 
	C623.374146,431.980408 623.471008,435.998352 623.228394,440.459625 
	C622.598572,440.974274 622.303833,441.010681 622.004883,441.012207 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M581.877625,420.629913 
	C581.375305,422.034058 580.932251,423.029327 580.727783,423.488647 
	C573.710144,419.514832 568.091125,424.992371 562.182251,426.496582 
	C555.282898,428.252991 550.625122,432.972351 549.810425,440.820282 
	C549.388855,435.924255 549.296326,430.904755 549.683350,425.416107 
	C551.815735,423.577118 553.266357,421.727051 555.160217,420.929077 
	C560.428284,418.709564 565.890991,416.955261 571.256287,414.962189 
	C574.843018,413.629791 578.168762,413.303833 580.870911,416.963226 
	C581.031860,417.012634 581.368469,417.023651 581.368469,417.023651 
	C581.557922,418.089447 581.747437,419.155243 581.877625,420.629913 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M623.042603,500.572571 
	C616.214111,500.572571 609.385742,500.572571 602.050293,500.572571 
	C602.050293,496.826416 602.050293,493.110474 602.707275,489.201660 
	C603.561829,489.026001 603.759460,489.043213 603.968384,489.467133 
	C604.174988,491.207275 604.370361,492.540680 604.616699,494.222412 
	C608.655518,493.146576 611.975525,492.232849 615.311218,491.380615 
	C620.198486,490.131927 623.173950,487.556305 621.998291,481.753357 
	C622.004822,481.505493 622.021179,481.009918 622.231689,481.012512 
	C622.722900,481.037506 623.003967,481.047485 623.285400,481.045105 
	C623.409058,486.445312 623.532654,491.845551 623.353638,497.899902 
	C623.048218,499.226868 623.045410,499.899719 623.042603,500.572571 
M604.518860,496.404846 
	C604.518860,496.404846 604.589172,496.520630 604.518860,496.404846 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M580.709961,416.913818 
	C578.168762,413.303833 574.843018,413.629791 571.256287,414.962189 
	C565.890991,416.955261 560.428284,418.709564 555.160217,420.929077 
	C553.266357,421.727051 551.815735,423.577118 549.820557,424.967560 
	C549.388123,423.257385 549.298096,421.526672 549.681885,419.266174 
	C551.088989,416.051941 552.022339,413.367462 553.189209,410.011292 
	C556.443726,410.011292 560.204224,410.139465 563.951965,409.976044 
	C567.875549,409.804962 571.795227,409.064728 575.699341,409.176697 
	C577.128906,409.217682 578.508911,410.989227 579.911499,411.971344 
	C580.005432,412.400085 580.099365,412.828827 580.077515,413.894836 
	C580.211060,415.325989 580.460510,416.119904 580.709961,416.913818 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M623.423340,427.519470 
	C623.131714,427.883698 622.866150,427.816040 622.857605,427.724457 
	C621.659790,414.842194 610.724182,414.802307 601.976318,412.015198 
	C598.873840,411.026703 595.574341,410.656799 591.902161,410.004333 
	C592.388550,409.234802 593.331299,407.807129 594.287537,407.797974 
	C603.804321,407.706879 613.323242,407.849945 622.984192,408.598511 
	C623.263550,410.149323 623.400391,411.028534 623.537231,411.907745 
	C623.547913,416.963959 623.558655,422.020203 623.423340,427.519470 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M566.352783,510.854462 
	C570.044983,510.271576 573.616577,510.091431 577.539001,509.893616 
	C578.052551,511.817810 578.589111,513.828125 579.337769,516.632935 
	C580.451843,513.738770 581.279785,511.587891 582.107727,509.437012 
	C582.755798,509.600220 583.403870,509.763397 584.051880,509.926575 
	C583.244202,513.943970 582.519043,517.981934 581.528748,521.953857 
	C581.398560,522.475891 579.868591,523.001648 579.066040,522.900208 
	C578.323120,522.806274 577.314575,521.999084 577.073914,521.286194 
	C576.412048,519.325562 576.091736,517.249695 575.636353,515.219360 
	C575.181213,515.216797 574.726074,515.214172 574.270935,515.211609 
	C574.089783,517.765137 573.908569,520.318604 573.727356,522.872131 
	C573.344727,522.999634 572.962097,523.127075 572.579468,523.254517 
	C571.822754,522.038025 571.066101,520.821533 570.053955,519.194458 
	C569.294250,520.630432 568.641418,521.864380 567.988586,523.098389 
	C567.403137,523.079468 566.817688,523.060547 566.232239,523.041565 
	C566.232239,519.113464 566.232239,515.185303 566.352783,510.854462 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M549.348328,469.483856 
	C549.872925,469.284668 550.681641,469.600067 550.652466,469.779266 
	C549.655457,475.912445 554.985413,477.083435 558.438232,479.608582 
	C560.856689,481.377197 563.559265,482.757233 566.642883,484.617218 
	C567.167786,483.743958 567.875183,482.566986 568.877869,481.224609 
	C569.136047,483.754364 569.098938,486.449524 568.709473,489.800110 
	C566.339661,490.486572 563.921753,491.251373 562.380249,490.410187 
	C557.885010,487.957153 553.739929,484.862335 549.457642,482.019165 
	C549.385681,477.991089 549.313721,473.963013 549.348328,469.483856 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M594.893188,487.043701 
	C594.893188,491.458954 594.893188,495.874237 594.893188,500.649109 
	C588.969727,500.649109 583.947815,500.649109 578.647888,500.649109 
	C578.166016,497.666168 577.695618,494.754852 577.617554,491.442078 
	C586.914795,493.724945 591.334229,492.678711 594.893188,487.043701 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M655.952637,510.461884 
	C660.283752,510.194824 664.613037,509.792267 668.947021,509.730835 
	C670.566650,509.707855 672.197449,510.480347 673.823181,510.890198 
	C672.569519,511.833069 671.315857,512.775940 669.868408,513.864563 
	C671.020447,514.371277 672.376465,514.967773 674.742126,516.008423 
	C672.556030,517.084290 671.161621,517.770508 669.664551,518.507263 
	C671.342529,519.596313 672.847168,520.572876 674.351807,521.549438 
	C674.139465,521.968201 673.927185,522.386902 673.714844,522.805664 
	C671.460266,522.805664 669.205688,522.805664 666.457642,522.805664 
	C666.457642,519.125427 666.457642,515.687500 666.457642,511.842773 
	C659.810547,513.917114 665.468811,520.411133 660.551819,523.861572 
	C660.321289,520.872009 660.498413,518.520325 659.865295,516.411926 
	C659.347412,514.687317 657.863220,513.252991 656.357544,511.605896 
	C655.922668,511.168488 655.937683,510.815186 655.952637,510.461884 
z"
          />
          <path
            fill="#085CA3"
            opacity="1.000000"
            stroke="none"
            d="
M574.268433,468.310425 
	C574.638489,467.362030 574.949280,466.792450 575.320435,466.112152 
	C578.622253,472.162567 584.408020,469.485840 589.282776,469.933441 
	C594.724915,470.433228 597.048584,467.012543 598.369141,462.145813 
	C597.982544,465.830536 597.254272,469.612183 596.016357,473.899963 
	C595.333008,475.272369 595.159180,476.138611 594.529541,477.004272 
	C587.454041,476.922333 580.728882,477.343658 575.002014,473.004517 
	C574.737732,471.566101 574.473450,470.127686 574.268433,468.310425 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M522.998535,521.005615 
	C523.688232,520.445435 524.795837,520.004272 524.995300,519.304382 
	C525.855774,516.285095 526.450134,513.190063 527.277771,509.517242 
	C535.424927,510.394958 530.473145,518.942322 535.554443,522.315674 
	C535.554443,517.709473 535.554443,513.695740 535.554443,509.682007 
	C536.195374,509.675201 536.836304,509.668365 537.477234,509.661530 
	C537.741699,512.729126 538.006104,515.796692 538.247192,518.593933 
	C539.789307,519.603333 541.186462,520.517761 542.583618,521.432251 
	C542.385498,522.013855 542.187378,522.595520 541.989258,523.177124 
	C537.687012,522.763550 533.384827,522.349976 529.082581,521.936401 
	C529.002441,522.004761 528.922241,522.073059 528.842102,522.141418 
	C527.148010,521.828674 525.453979,521.515930 523.338013,521.127441 
	C522.916016,521.051819 522.998535,521.005615 522.998535,521.005615 
z"
          />
          <path
            fill="#0D3D8B"
            opacity="1.000000"
            stroke="none"
            d="
M594.985352,477.004883 
	C595.159180,476.138611 595.333008,475.272369 595.785278,474.220703 
	C595.746887,478.090912 595.430054,482.146545 595.003174,486.622955 
	C591.334229,492.678711 586.914795,493.724945 577.653320,491.036804 
	C576.857117,487.971924 576.417480,484.910858 576.393799,481.503601 
	C579.569031,485.713959 584.063965,483.777863 587.869385,483.922791 
	C594.460632,484.173828 594.521240,483.936066 594.985352,477.004883 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M622.021118,481.009918 
	C622.021179,481.009918 622.004822,481.505493 621.611694,481.754639 
	C619.756409,482.558472 618.279358,483.077087 616.834839,483.674622 
	C612.535400,485.453217 608.248718,487.262878 603.957092,489.060425 
	C603.759460,489.043213 603.561829,489.026001 603.069824,488.963898 
	C602.823120,487.556030 602.870728,486.193085 603.006348,484.431763 
	C609.152710,480.362518 615.211121,476.691650 621.638733,473.457153 
	C622.012329,476.265625 622.016724,478.637787 622.021118,481.009918 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M594.529541,477.004272 
	C594.521240,483.936066 594.460632,484.173828 587.869385,483.922791 
	C584.063965,483.777863 579.569031,485.713959 576.441895,481.079956 
	C576.074097,481.002563 575.767944,480.679535 575.864380,480.279358 
	C576.001648,479.605164 575.932251,479.362640 575.752686,479.151642 
	C575.469299,477.398407 575.185852,475.645142 574.952209,473.448212 
	C580.728882,477.343658 587.454041,476.922333 594.529541,477.004272 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M505.102600,518.638977 
	C505.119263,516.548035 505.061676,514.879395 505.198456,513.226929 
	C505.442474,510.279297 507.300385,509.384430 510.027924,509.511536 
	C513.500977,509.673340 513.974731,512.119995 513.943054,514.718872 
	C513.905151,517.833923 515.154541,521.747437 510.687256,522.955688 
	C507.643005,523.779053 505.516907,522.453430 505.102600,518.638977 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M588.976807,512.174255 
	C591.406189,509.938660 593.911682,508.132477 597.398682,511.275543 
	C597.322754,512.844971 597.044128,513.855103 596.447388,514.717712 
	C595.255249,514.044739 594.381409,513.519409 593.291382,512.864075 
	C593.291382,515.339844 593.291382,517.287170 593.291382,518.951294 
	C595.142883,518.579102 596.555237,518.295166 597.948608,518.376465 
	C597.898010,519.471375 597.866455,520.201111 597.834961,520.930786 
	C594.864746,525.321411 591.812988,522.283325 588.276489,520.727051 
	C588.173767,517.674072 588.575317,514.924133 588.976807,512.174255 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M557.133606,510.058624 
	C559.391357,509.910065 561.649109,509.761505 563.906921,509.612946 
	C564.129395,510.121674 564.351929,510.630432 564.574463,511.139160 
	C563.098145,512.006226 561.621826,512.873291 559.643127,514.035400 
	C561.398254,514.552795 562.762512,514.954956 564.126770,515.357178 
	C564.225464,515.724426 564.324097,516.091736 564.422791,516.458984 
	C562.958862,517.124878 561.494934,517.790710 559.398010,518.744507 
	C560.479248,519.300720 560.964600,519.544922 561.444946,519.798584 
	C562.432068,520.319885 563.416443,520.846191 564.401978,521.370483 
	C563.448486,521.922729 562.553101,522.703796 561.525635,522.964661 
	C560.437744,523.240784 559.224182,523.022217 557.616577,522.499207 
	C557.156311,518.005371 557.144958,514.031982 557.133606,510.058624 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M549.349243,482.421600 
	C553.739929,484.862335 557.885010,487.957153 562.380249,490.410187 
	C563.921753,491.251373 566.339661,490.486572 568.623413,490.243530 
	C568.948181,491.102417 569.006653,492.173309 568.620605,493.800293 
	C565.688477,494.139496 563.059326,494.357849 560.745728,493.605530 
	C557.109863,492.423126 553.686707,490.586609 550.126221,488.718414 
	C549.854492,487.650330 549.629944,486.890961 549.405457,486.131561 
	C549.350647,485.029053 549.295837,483.926544 549.349243,482.421600 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M609.063843,510.027496 
	C610.979797,510.034271 612.912903,509.916840 614.794006,510.176331 
	C615.090149,510.217194 615.554260,512.123047 615.251221,512.896484 
	C613.872192,516.416687 620.908020,524.372986 609.540161,522.583008 
	C608.774536,521.212463 608.535767,520.221558 608.191833,518.794250 
	C610.291443,519.212524 611.777893,519.508667 613.264404,519.804810 
	C609.420532,517.312866 606.489197,514.969788 609.063843,510.027496 
z"
          />
          <path
            fill="#050364"
            opacity="1.000000"
            stroke="none"
            d="
M579.975708,411.523621 
	C578.508911,410.989227 577.128906,409.217682 575.699341,409.176697 
	C571.795227,409.064728 567.875549,409.804962 563.951965,409.976044 
	C560.204224,410.139465 556.443726,410.011292 553.189209,410.011292 
	C552.022339,413.367462 551.088989,416.051941 549.830383,418.856445 
	C549.505127,415.398560 549.505127,411.820709 549.505127,407.709167 
	C559.081055,407.709167 568.458496,407.631256 577.828735,407.854950 
	C578.590942,407.873108 579.303955,409.953674 579.975708,411.523621 
z"
          />
          <path
            fill="#050D6B"
            opacity="1.000000"
            stroke="none"
            d="
M550.173462,489.027100 
	C553.686707,490.586609 557.109863,492.423126 560.745728,493.605530 
	C563.059326,494.357849 565.688477,494.139496 568.532715,494.194427 
	C568.947632,494.783081 569.005981,495.533752 568.975830,496.652924 
	C563.215271,501.394867 558.350342,497.083038 553.244629,495.532806 
	C552.014404,495.159271 550.869934,494.503418 549.470886,493.626831 
	C549.561646,491.859894 549.867554,490.443481 550.173462,489.027100 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M495.964600,516.082947 
	C495.648590,517.660400 495.814178,519.665649 494.891571,520.704224 
	C493.800598,521.932312 491.674377,523.047729 490.177612,522.849243 
	C489.010773,522.694580 487.221985,520.411194 487.284698,519.152283 
	C487.438080,516.072693 488.503754,513.038574 489.202209,509.986176 
	C491.095184,510.441437 492.988129,510.896698 494.965332,511.982971 
	C493.529755,512.822754 492.009918,513.031494 489.847717,513.328491 
	C490.061707,515.446594 490.281433,517.621033 490.501129,519.795532 
	C491.066040,519.730713 491.630951,519.665894 492.195862,519.601074 
	C492.195862,518.387756 492.195862,517.174438 492.195862,515.743652 
	C493.690369,515.878174 494.827454,515.980591 495.964600,516.082947 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M523.176392,520.688843 
	C522.998535,521.005615 522.916016,521.051819 522.923950,521.098389 
	C521.233765,521.757385 519.535583,522.369812 517.361145,522.508545 
	C516.549805,518.586853 516.214722,515.138855 515.879639,511.690887 
	C515.879639,511.690887 515.733337,511.338165 516.052551,511.063904 
	C516.578186,510.520508 516.784607,510.251343 516.991028,509.982208 
	C516.991028,509.982208 516.992615,510.001221 517.366211,510.060638 
	C519.026672,510.133270 520.313538,510.146454 521.600342,510.159637 
	C521.600342,510.159637 521.800659,510.076599 521.867188,510.528564 
	C522.210815,512.298401 522.487915,513.616272 522.765076,514.934082 
	C522.800659,515.455811 522.836243,515.977600 522.900940,517.140259 
	C522.930054,517.781250 523.137695,518.234436 523.137695,518.234436 
	C523.209900,518.946960 523.282104,519.659546 523.176392,520.688843 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M640.831482,509.907898 
	C642.198547,511.284821 643.514526,512.720825 644.978088,513.986206 
	C645.253540,514.224426 646.117310,513.782104 647.277466,513.401489 
	C648.226440,514.431396 648.607666,515.713501 648.988831,516.995605 
	C648.603455,517.791748 647.952576,518.563965 647.878174,519.388123 
	C647.538940,523.147827 645.784912,523.632080 642.473145,521.352173 
	C641.673767,517.258118 641.252625,513.583008 640.831482,509.907898 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M683.945435,512.285950 
	C684.100220,513.636169 684.212463,514.619141 684.237427,515.604431 
	C684.408569,522.354065 683.622192,523.092590 676.217468,522.826050 
	C676.217468,518.667175 676.217468,514.484619 676.217468,508.917908 
	C678.998779,510.010010 681.430664,510.964905 683.945435,512.285950 
M678.232788,517.353271 
	C679.067627,518.113708 679.902466,518.874146 680.737244,519.634644 
	C681.046936,517.773010 681.473877,515.917847 681.577271,514.044800 
	C681.600708,513.619934 680.501892,513.133179 679.919678,512.674866 
	C679.367249,513.973999 678.814758,515.273193 678.232788,517.353271 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M547.583374,515.037476 
	C547.623474,514.572083 547.663574,514.106689 547.769531,512.956665 
	C548.005493,511.557922 548.175781,510.843872 548.346008,510.129822 
	C550.246216,510.106934 552.146484,510.084076 554.424805,510.471588 
	C555.989502,516.068542 550.149963,510.029968 550.459412,514.046692 
	C555.702881,514.905090 556.597046,518.172729 554.519714,522.792969 
	C552.781067,522.817200 551.042480,522.841431 548.844116,522.490662 
	C548.116028,521.758423 547.847595,521.401245 547.579224,521.044006 
	C547.654663,520.561279 547.730164,520.078491 548.263611,519.266052 
	C550.074707,519.210083 551.427795,519.483826 552.780884,519.757568 
	C552.892334,519.195557 553.003723,518.633545 553.115112,518.071533 
	C551.502441,517.621521 549.889832,517.171570 548.277161,516.721558 
	C548.277161,516.721558 548.079285,516.873474 548.152710,516.505066 
	C548.011841,515.770264 547.797607,515.403870 547.583374,515.037476 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M608.745544,510.012573 
	C606.489197,514.969788 609.420532,517.312866 613.264404,519.804810 
	C611.777893,519.508667 610.291443,519.212524 608.191833,518.794250 
	C608.535767,520.221558 608.774536,521.212463 609.118164,522.551392 
	C608.416992,522.950806 607.610840,523.002197 606.604248,522.635742 
	C605.087219,521.062500 603.770691,519.907166 602.129150,518.466614 
	C603.562988,517.759521 605.023010,517.039429 607.349854,515.891968 
	C604.968872,515.142334 603.574707,514.703430 602.212219,514.274475 
	C603.804993,512.674316 605.160828,511.312134 606.516724,509.949951 
	C607.153564,509.965851 607.790405,509.981750 608.745544,510.012573 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M622.007568,441.479584 
	C622.303833,441.010681 622.598572,440.974274 623.112122,440.903625 
	C623.451477,450.657410 623.567688,460.410553 623.288696,470.619965 
	C622.586975,471.009918 622.276978,470.979584 621.963501,470.985260 
	C621.648621,469.753204 621.032898,468.512634 621.067444,467.290436 
	C621.305908,458.840485 621.679871,450.394318 622.007568,441.479584 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M517.403198,332.585205 
	C517.497498,331.533508 517.683777,329.906067 518.397766,329.626617 
	C526.011597,326.646210 533.708984,323.879395 541.771484,321.122986 
	C534.019653,325.006470 525.879883,328.820892 517.403198,332.585205 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M606.125244,509.867401 
	C605.160828,511.312134 603.804993,512.674316 602.212219,514.274475 
	C603.574707,514.703430 604.968872,515.142334 607.349854,515.891968 
	C605.023010,517.039429 603.562988,517.759521 602.129150,518.466614 
	C603.770691,519.907166 605.087219,521.062500 606.201111,522.608826 
	C604.302368,522.993774 602.606262,522.987732 600.383911,522.524780 
	C599.857605,518.149841 599.857605,514.231873 599.857605,510.150024 
	C601.771606,509.936249 603.365906,509.758148 604.960205,509.580078 
	C605.218079,509.648376 605.475891,509.716614 606.125244,509.867401 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M631.087463,519.808105 
	C632.017761,520.039856 632.947998,520.271667 633.902466,521.039185 
	C633.926575,521.574951 634.025024,521.885071 634.025024,521.885010 
	C631.962219,522.404175 629.899475,522.923340 627.784241,523.455750 
	C627.784241,518.316772 627.784241,513.961853 627.784241,509.606873 
	C628.481567,509.603851 629.178894,509.600830 629.876221,509.597778 
	C629.931396,512.491821 629.986633,515.385925 630.146057,518.951172 
	C630.250244,519.622375 630.631409,519.882690 630.631409,519.882690 
	C630.631409,519.882690 631.087463,519.808105 631.087463,519.808105 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M496.809631,511.981140 
	C497.445374,511.252289 498.081116,510.523407 498.716827,509.794556 
	C499.176941,510.505341 499.974518,511.189697 500.032715,511.932007 
	C500.195190,514.004822 500.051544,516.101624 500.013489,518.934082 
	C500.678802,519.740417 501.359375,519.801575 502.039948,519.862732 
	C502.723877,520.354492 503.407806,520.846252 504.091736,521.338013 
	C503.477051,521.878296 502.718414,522.976074 502.272919,522.861084 
	C500.461731,522.393372 498.746613,521.553772 497.176636,520.329895 
	C497.406036,519.705566 497.523590,519.562561 497.491180,519.506958 
	C497.382202,519.319702 497.219727,519.163574 497.077759,518.995544 
	C497.031281,517.617493 496.984833,516.239502 496.938599,514.110718 
	C496.895721,512.900391 496.852661,512.440796 496.809631,511.981140 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M640.371338,509.907043 
	C641.252625,513.583008 641.673767,517.258118 642.087280,521.353394 
	C640.928040,522.204102 639.776489,522.634583 638.353027,522.561890 
	C638.082520,518.030273 638.083923,514.001709 638.085327,509.973175 
	C638.693970,509.950836 639.302612,509.928497 640.371338,509.907043 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M637.780579,509.832275 
	C638.083923,514.001709 638.082520,518.030273 638.001831,522.514709 
	C636.826721,522.727051 635.730896,522.483459 634.330017,522.062500 
	C634.025024,521.885071 633.926575,521.574951 633.980042,521.423157 
	C634.387756,518.445984 634.557068,515.578674 635.184631,512.815369 
	C635.446960,511.660156 636.682739,510.726044 637.780579,509.832275 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M623.389343,590.926636 
	C622.507751,590.857849 621.991089,590.727905 621.337585,590.370728 
	C628.815063,587.368225 636.429382,584.593018 644.415161,581.821045 
	C639.796997,584.163025 634.834656,586.562927 629.803955,588.809509 
	C627.867554,589.674316 625.775452,590.190491 623.389343,590.926636 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M681.720825,556.556091 
	C679.127686,562.820374 673.581787,566.021790 667.388245,568.857788 
	C671.832153,564.749146 676.620728,560.654175 681.720825,556.556091 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M554.973450,522.851013 
	C556.597046,518.172729 555.702881,514.905090 550.459412,514.046692 
	C550.149963,510.029968 555.989502,516.068542 554.752075,510.507324 
	C555.241394,510.102020 555.781555,510.071320 556.727661,510.049622 
	C557.144958,514.031982 557.156311,518.005371 557.211304,522.432129 
	C556.645630,522.893372 556.036438,522.901184 554.973450,522.851013 
z"
          />
          <path
            fill="#FC9E08"
            opacity="1.000000"
            stroke="none"
            d="
M518.528320,577.458496 
	C513.121094,576.816528 507.932922,575.208313 505.011597,569.376831 
	C509.462067,571.718628 513.916809,574.424805 518.528320,577.458496 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M588.664734,511.997009 
	C588.575317,514.924133 588.173767,517.674072 587.925842,520.777832 
	C587.250427,521.261353 586.421448,521.390930 585.592407,521.520508 
	C585.585571,518.107239 585.516663,514.691711 585.642090,511.283264 
	C585.659912,510.799530 586.622864,510.350647 587.148376,509.885681 
	C587.549805,510.530365 587.951233,511.175049 588.664734,511.997009 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M505.968262,338.027954 
	C506.793884,337.706635 507.619476,337.385315 508.778503,337.102722 
	C505.038239,340.400970 500.964539,343.660461 496.575745,346.992798 
	C497.723389,345.231140 499.186096,343.396606 500.830139,341.281311 
	C502.131073,340.352936 503.250641,339.705353 504.906311,338.909851 
	C505.715302,338.587280 505.890564,338.342590 505.968262,338.027954 
z"
          />
          <path
            fill="#FC9E08"
            opacity="1.000000"
            stroke="none"
            d="
M533.032349,584.582520 
	C527.561707,584.669495 523.110901,582.015869 519.034424,578.081421 
	C523.644836,579.883606 528.295715,582.064636 533.032349,584.582520 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M648.950317,517.430237 
	C648.607666,515.713501 648.226440,514.431396 647.496643,513.079712 
	C647.999512,511.941864 648.850952,510.873566 649.702393,509.805267 
	C650.251160,510.799011 651.210876,511.767792 651.273071,512.791077 
	C651.475159,516.116333 651.348633,519.461548 651.348633,522.799133 
	C650.750244,522.850647 650.151855,522.902161 649.553406,522.953674 
	C649.339539,521.257385 649.125671,519.561157 648.950317,517.430237 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M496.539124,511.988800 
	C496.852661,512.440796 496.895721,512.900391 496.872986,513.701782 
	C496.551910,514.532410 496.296631,515.021423 496.002960,515.796631 
	C494.827454,515.980591 493.690369,515.878174 492.195862,515.743652 
	C492.195862,517.174438 492.195862,518.387756 492.195862,519.601074 
	C491.630951,519.665894 491.066040,519.730713 490.501129,519.795532 
	C490.281433,517.621033 490.061707,515.446594 489.847717,513.328491 
	C492.009918,513.031494 493.529755,512.822754 495.118073,512.294678 
	C495.547241,511.982330 495.907928,511.989410 496.539124,511.988800 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M604.655396,509.372925 
	C603.365906,509.758148 601.771606,509.936249 599.857605,510.150024 
	C599.857605,514.231873 599.857605,518.149841 599.960083,522.523682 
	C599.496399,522.405396 598.930298,521.831299 598.099548,521.093994 
	C597.866455,520.201111 597.898010,519.471375 598.203369,518.161621 
	C597.906555,516.676208 597.336060,515.770752 596.765564,514.865295 
	C597.044128,513.855103 597.322754,512.844971 597.636230,511.496765 
	C599.897644,510.494415 602.124146,509.830109 604.655396,509.372925 
z"
          />
          <path
            fill="#161657"
            opacity="1.000000"
            stroke="none"
            d="
M655.574097,510.186310 
	C655.937683,510.815186 655.922668,511.168488 655.974976,511.779144 
	C656.042175,515.652161 656.042175,519.267761 656.042175,524.154297 
	C653.016174,519.774597 653.045593,512.756775 655.574097,510.186310 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M488.271301,354.419312 
	C490.414337,351.988983 492.867645,349.546692 495.679901,347.182129 
	C493.553101,349.642395 491.067322,352.024872 488.271301,354.419312 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M621.979980,471.321838 
	C622.276978,470.979584 622.586975,471.009918 623.126831,471.065308 
	C623.432251,474.094147 623.504272,477.133881 623.430786,480.609375 
	C623.003967,481.047485 622.722900,481.037506 622.231689,481.012512 
	C622.016724,478.637787 622.012329,476.265625 622.005249,473.449799 
	C622.000488,472.556885 621.998474,472.107635 621.979980,471.321838 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M689.504272,548.898499 
	C687.762573,551.178467 685.700195,553.543152 683.292786,555.886963 
	C685.026428,553.571838 687.105042,551.277527 689.504272,548.898499 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M550.126221,488.718414 
	C549.867554,490.443481 549.561646,491.859894 549.086792,493.599243 
	C548.238159,491.514130 547.558472,489.106079 546.960999,486.299713 
	C547.560974,486.058197 548.078674,486.214996 549.000977,486.251678 
	C549.629944,486.890961 549.854492,487.650330 550.126221,488.718414 
z"
          />
          <path
            fill="#FC9E08"
            opacity="1.000000"
            stroke="none"
            d="
M504.891907,568.669678 
	C503.031525,567.829407 501.076813,566.666016 499.150452,565.124878 
	C501.051727,565.946960 502.924652,567.146729 504.891907,568.669678 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M515.791382,512.098450 
	C516.214722,515.138855 516.549805,518.586853 516.953369,522.430298 
	C516.582336,519.385864 516.142761,515.945984 515.791382,512.098450 
z"
          />
          <path
            fill="#FDA806"
            opacity="1.000000"
            stroke="none"
            d="
M479.152832,363.867371 
	C477.761169,363.886688 476.641602,363.777832 475.522003,363.669006 
	C475.443085,363.152863 475.364166,362.636688 475.285217,362.120544 
	C476.612732,361.975647 477.940247,361.830750 479.643799,362.047913 
	C480.019836,362.409912 480.188660,362.820770 480.188660,362.820801 
	C479.934082,363.126923 479.679504,363.433044 479.152832,363.867371 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M548.172974,517.072388 
	C549.889832,517.171570 551.502441,517.621521 553.115112,518.071533 
	C553.003723,518.633545 552.892334,519.195557 552.780884,519.757568 
	C551.427795,519.483826 550.074707,519.210083 548.338623,518.907349 
	C547.993408,518.393372 548.031128,517.908264 548.172974,517.072388 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M614.498413,591.491455 
	C613.141296,592.062500 611.461609,592.522522 609.400696,592.941345 
	C610.738220,592.393555 612.457031,591.887024 614.498413,591.491455 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M697.003723,543.996521 
	C696.074768,544.232910 695.140991,544.474060 693.840942,544.694458 
	C694.312317,543.416016 695.149841,542.158386 696.408569,540.879089 
	C696.829834,540.857422 697.042603,541.042725 697.020752,541.413269 
	C696.996399,542.526489 696.993835,543.269104 696.991272,544.011780 
	C696.991272,544.011780 696.998840,544.001343 697.003723,543.996521 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M480.476501,362.776520 
	C480.188660,362.820770 480.019836,362.409912 479.956726,362.196594 
	C480.887451,361.037628 481.881287,360.091949 483.147827,359.130920 
	C482.535156,360.321136 481.649719,361.526703 480.476501,362.776520 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M522.994141,514.717041 
	C522.487915,513.616272 522.210815,512.298401 521.912048,510.627258 
	C522.334656,511.682648 522.778870,513.091309 522.994141,514.717041 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M664.740356,570.384399 
	C664.105408,571.195007 663.159851,571.985962 661.878784,572.655945 
	C662.505310,571.811584 663.467468,571.088196 664.740356,570.384399 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M512.656006,335.264008 
	C513.297668,334.563141 514.256165,333.801605 515.556213,333.086670 
	C514.922791,333.823303 513.947876,334.513336 512.656006,335.264008 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M698.415405,537.871216 
	C698.773071,538.547729 698.858032,539.366455 698.965576,540.598999 
	C698.988098,541.012878 699.000732,540.999268 698.678101,540.992126 
	C697.917847,541.004211 697.480225,541.023499 697.042603,541.042725 
	C697.042603,541.042725 696.829834,540.857422 696.719604,540.768372 
	C697.120544,539.790710 697.631653,538.902100 698.415405,537.871216 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M580.870911,416.963226 
	C580.460510,416.119904 580.211060,415.325989 580.030029,414.223816 
	C580.573608,414.747467 581.048828,415.579346 581.446289,416.717407 
	C581.368469,417.023651 581.031860,417.012634 580.870911,416.963226 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M521.364624,509.988464 
	C520.313538,510.146454 519.026672,510.133270 517.358765,510.051514 
	C518.361389,509.927734 519.745117,509.872528 521.364624,509.988464 
z"
          />
          <path
            fill="#FC9E08"
            opacity="1.000000"
            stroke="none"
            d="
M499.191956,564.502197 
	C498.531799,564.426514 497.817444,564.035461 497.058533,563.303711 
	C497.721924,563.370972 498.429810,563.778931 499.191956,564.502197 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M540.208496,434.774170 
	C539.730896,434.517944 539.372986,434.013000 538.995789,433.259094 
	C538.976562,433.010132 539.419556,432.986969 539.640869,433.000366 
	C540.017578,433.517639 540.172913,434.021545 540.208496,434.774170 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M476.411804,367.757690 
	C476.388580,367.234406 476.618103,366.704834 477.070129,366.128693 
	C477.083252,366.638550 476.873932,367.194946 476.411804,367.757690 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M501.793213,519.739624 
	C501.359375,519.801575 500.678802,519.740417 499.997009,519.380371 
	C500.512695,519.259827 501.029572,519.438232 501.793213,519.739624 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M623.308228,500.472534 
	C623.045410,499.899719 623.048218,499.226868 623.208679,498.357178 
	C623.435547,498.897705 623.504761,499.635101 623.308228,500.472534 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M623.664551,411.590424 
	C623.400391,411.028534 623.263550,410.149323 623.272827,408.953827 
	C623.543213,409.516052 623.667542,410.394562 623.664551,411.590424 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M548.045166,510.196899 
	C548.175781,510.843872 548.005493,511.557922 547.696411,512.612732 
	C547.619751,512.056946 547.682007,511.160461 548.045166,510.196899 
z"
          />
          <path
            fill="#1B78B1"
            opacity="1.000000"
            stroke="none"
            d="
M584.433533,438.667786 
	C584.171204,438.448090 584.022217,437.998749 583.912476,437.307800 
	C584.150085,437.523499 584.348511,437.980804 584.433533,438.667786 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M649.626099,579.143738 
	C649.536804,579.472412 649.211609,579.742920 648.666565,580.020752 
	C648.761230,579.713928 649.075745,579.399719 649.626099,579.143738 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M656.508667,575.696655 
	C656.369629,576.091064 655.921448,576.507141 655.133911,576.866821 
	C655.262817,576.446350 655.731201,576.082336 656.508667,575.696655 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M547.410645,515.240234 
	C547.797607,515.403870 548.011841,515.770264 548.265930,516.452759 
	C547.949829,516.326965 547.593872,515.885010 547.410645,515.240234 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M547.411621,521.272766 
	C547.847595,521.401245 548.116028,521.758423 548.473267,522.442749 
	C548.122681,522.347107 547.683350,521.924316 547.411621,521.272766 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M523.233398,518.111023 
	C523.137695,518.234436 522.930054,517.781250 522.917480,517.532471 
	C523.225220,517.384094 523.366638,517.618713 523.233398,518.111023 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M707.835327,385.338562 
	C707.897217,385.376038 707.893066,385.368530 707.901611,385.344940 
	C707.910095,385.321350 707.773376,385.301117 707.835327,385.338562 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M509.390198,337.108948 
	C509.371674,336.847595 509.634583,336.570923 510.199585,336.333008 
	C510.224945,336.612396 509.948273,336.852966 509.390198,337.108948 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M511.478943,336.041809 
	C511.230347,335.670868 511.400574,335.421143 512.010193,335.329498 
	C512.198181,335.684143 512.014282,335.909271 511.478943,336.041809 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M539.845581,431.739380 
	C539.645264,431.730347 539.520508,431.474213 539.537476,431.126831 
	C539.759827,431.187836 539.840515,431.340057 539.845581,431.739380 
z"
          />
          <path
            fill="#1B78B1"
            opacity="1.000000"
            stroke="none"
            d="
M575.616577,479.319672 
	C575.932251,479.362640 576.001648,479.605164 575.884338,480.057739 
	C575.524658,480.062805 575.415527,479.813293 575.616577,479.319672 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M516.669250,509.944153 
	C516.784607,510.251343 516.578186,510.520508 516.134277,510.891357 
	C516.047058,510.630768 516.197266,510.268433 516.669250,509.944153 
z"
          />
          <path
            fill="#2E2E69"
            opacity="1.000000"
            stroke="none"
            d="
M496.999878,519.246521 
	C497.219727,519.163574 497.382202,519.319702 497.491180,519.506958 
	C497.523590,519.562561 497.406036,519.705566 497.214294,519.904968 
	C497.021606,519.833435 496.971802,519.665466 496.999878,519.246521 
z"
          />
          <path
            fill="#5E5C68"
            opacity="1.000000"
            stroke="none"
            d="
M630.954956,519.664917 
	C631.087463,519.808105 630.631409,519.882690 630.631409,519.882690 
	C630.631409,519.882690 630.250244,519.622375 630.185303,519.405029 
	C630.348694,519.311035 630.582642,519.422424 630.954956,519.664917 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M699.756714,536.154480 
	C699.947144,536.346252 699.872498,536.541382 699.552124,536.754272 
	C699.330017,536.563965 699.391785,536.359192 699.756714,536.154480 
z"
          />
          <path
            fill="#C07D3F"
            opacity="1.000000"
            stroke="none"
            d="
M653.638367,577.140015 
	C653.791748,577.519226 653.575134,577.728821 652.986023,577.763672 
	C652.834778,577.382141 653.053101,577.176025 653.638367,577.140015 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M665.504761,328.167389 
	C666.454346,328.982208 667.109253,329.721252 667.849365,330.745148 
	C666.637634,330.248993 665.340759,329.467957 664.024536,328.328888 
	C664.406860,328.011108 664.808472,328.051361 665.504761,328.167389 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M660.066956,325.300293 
	C661.083801,325.651855 662.110901,326.366119 663.253296,327.384583 
	C662.271484,327.013519 661.174316,326.338257 660.066956,325.300293 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M657.116821,323.206970 
	C657.514221,322.911133 657.906738,322.978180 658.594116,323.091736 
	C659.214600,323.569672 659.540222,324.001068 659.959229,324.686279 
	C659.072327,324.483337 658.092102,324.026581 657.116821,323.206970 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M680.016235,342.316986 
	C680.208496,342.260406 680.486328,342.422485 680.803528,342.835175 
	C680.528503,342.993073 680.281494,342.809692 680.016235,342.316986 
z"
          />
          <path
            fill="#D57939"
            opacity="1.000000"
            stroke="none"
            d="
M656.577393,321.207581 
	C656.789368,321.216797 656.849548,321.637360 656.802002,321.844788 
	C656.503296,321.986481 656.297424,321.851654 656.047729,321.483826 
	C655.958618,321.319916 656.365356,321.198395 656.577393,321.207581 
z"
          />
          <path
            fill="#FD8B03"
            opacity="1.000000"
            stroke="none"
            d="
M697.020752,541.413330 
	C697.480225,541.023499 697.917847,541.004211 698.684448,540.985962 
	C698.994019,541.421753 698.974670,541.856506 698.939453,542.616028 
	C698.457764,543.244385 697.992004,543.547974 697.258728,543.931702 
	C696.993835,543.269104 696.996399,542.526489 697.020752,541.413330 
z"
          />
          <path
            fill="#FDA205"
            opacity="1.000000"
            stroke="none"
            d="
M505.762390,338.118652 
	C505.890564,338.342590 505.715302,338.587280 505.224030,338.833527 
	C505.117432,338.616241 505.301056,338.384338 505.762390,338.118652 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M630.010620,481.363647 
	C630.451294,479.409515 630.677979,477.757111 631.382629,476.342865 
	C632.042786,475.017822 633.437012,474.060608 634.108032,472.738434 
	C635.842529,469.321075 637.470154,465.838745 638.934448,462.297180 
	C642.176392,454.456299 638.848694,447.158722 636.659363,439.865265 
	C635.991821,437.641632 634.113708,435.781403 632.044617,432.613464 
	C632.044617,435.945038 632.044617,437.845856 632.044617,439.746674 
	C631.800720,439.752411 631.556885,439.758118 631.312988,439.763855 
	C631.312988,435.043365 631.312988,430.322876 631.593933,425.301422 
	C632.203308,425.043732 632.690063,424.962067 632.837097,425.148438 
	C636.933655,430.341339 641.620605,435.204559 644.867126,440.889221 
	C647.494568,445.489868 649.659485,450.434357 649.223999,456.516693 
	C648.494324,466.709198 643.583130,474.183655 636.751099,480.959045 
	C636.204041,481.501526 635.588074,481.984589 635.100769,482.574982 
	C634.212402,483.651215 633.396484,484.787231 632.550415,485.898315 
	C631.702026,484.521973 630.853638,483.145630 630.010620,481.363647 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M523.731323,465.218323 
	C524.303101,465.496674 524.470947,465.489624 524.295654,465.458282 
	C523.859558,466.045349 523.764038,466.596222 523.668457,467.147125 
	C523.668457,467.147125 523.632996,467.195587 523.631226,467.224915 
	C520.951294,467.656677 518.273010,468.059143 514.579468,468.614166 
	C516.219360,455.466187 517.744141,443.240875 519.268982,431.015564 
	C519.446106,431.014130 519.623230,431.012695 519.800415,431.011292 
	C520.976868,442.338623 522.153320,453.665985 523.731323,465.218323 
z"
          />
          <path
            fill="#085CA3"
            opacity="1.000000"
            stroke="none"
            d="
M568.877686,466.688843 
	C568.374329,468.902008 567.867615,470.746796 567.363159,472.583496 
	C558.633667,471.337372 549.062988,456.927765 554.721619,447.590027 
	C558.146606,441.938324 562.328003,437.526886 567.328125,434.189667 
	C570.680237,431.952393 575.772522,432.322632 580.914307,431.373230 
	C581.267029,433.231995 581.642395,435.209961 582.021118,437.592834 
	C581.231018,438.256256 580.446960,438.549805 579.642212,438.766052 
	C577.898865,439.234497 576.104431,439.547943 574.406982,440.142181 
	C572.828918,440.694611 571.349548,441.529022 569.825989,442.239594 
	C564.226501,449.720276 561.209778,457.887207 568.877686,466.688843 
z"
          />
          <path
            fill="#0B2A7D"
            opacity="1.000000"
            stroke="none"
            d="
M577.463196,424.443481 
	C577.521606,424.407135 577.404785,424.479828 577.463196,424.443481 
z"
          />
          <path
            fill="#124A94"
            opacity="1.000000"
            stroke="none"
            d="
M592.448975,423.989441 
	C592.030945,421.874939 592.030945,419.763947 592.030945,416.798492 
	C600.482178,419.705841 608.338562,421.990265 615.828552,425.144440 
	C620.216187,426.992126 621.775330,431.448669 621.954224,436.572021 
	C620.267761,436.080658 618.566772,435.234863 616.927185,434.283142 
	C611.641907,431.215240 606.519836,427.819916 601.042236,425.151337 
	C598.687866,424.004333 595.614014,424.334106 592.448975,423.989441 
z"
          />
          <path
            fill="#070666"
            opacity="1.000000"
            stroke="none"
            d="
M523.989380,466.915344 
	C523.764038,466.596222 523.859558,466.045349 524.367676,465.350586 
	C524.623596,465.699005 524.466980,466.191284 523.989380,466.915344 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M603.968384,489.467133 
	C608.248718,487.262878 612.535400,485.453217 616.834839,483.674622 
	C618.279358,483.077087 619.756409,482.558472 621.605164,482.002502 
	C623.173950,487.556305 620.198486,490.131927 615.311218,491.380615 
	C611.975525,492.232849 608.655518,493.146576 604.616699,494.222412 
	C604.370361,492.540680 604.174988,491.207275 603.968384,489.467133 
z"
          />
          <path
            fill="#051B76"
            opacity="1.000000"
            stroke="none"
            d="
M604.554016,496.462738 
	C604.589172,496.520630 604.518860,496.404846 604.554016,496.462738 
z"
          />
          <path
            fill="#FCFCFB"
            opacity="1.000000"
            stroke="none"
            d="
M596.447388,514.717712 
	C597.336060,515.770752 597.906555,516.676208 598.222351,517.796448 
	C596.555237,518.295166 595.142883,518.579102 593.291382,518.951294 
	C593.291382,517.287170 593.291382,515.339844 593.291382,512.864075 
	C594.381409,513.519409 595.255249,514.044739 596.447388,514.717712 
z"
          />
          <path
            fill="#E5E6F2"
            opacity="1.000000"
            stroke="none"
            d="
M678.247559,516.962769 
	C678.814758,515.273193 679.367249,513.973999 679.919678,512.674866 
	C680.501892,513.133179 681.600708,513.619934 681.577271,514.044800 
	C681.473877,515.917847 681.046936,517.773010 680.737244,519.634644 
	C679.902466,518.874146 679.067627,518.113708 678.247559,516.962769 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
